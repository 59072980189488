import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getCarProducts, getCarProductsBrands, getCarProductsCompanies, getCarProductsModels, getCarProductsVersions } from "../../../../redux/actions/productos/carProductsAction"

import { Accordion, NativeSelect, Switch, NumberInput, MultiSelect } from '@mantine/core';
import { YearPickerInput } from '@mantine/dates';

import "./carFiltrosItems.css";
import { hasViewPermission } from "../../../configuration/permissions/utilities/hasPermissions";

export default function Filters({ productIndex, setSelectedPricelist}) {
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const currency = useSelector((state) => state.currency.data);
	const labelingReducer = useSelector((state) => state.labeling);
	const priceLists = useSelector((state) => state.priceList.priceList);
	const productsReducer = useSelector((state) => state.carProducts);
	const user = useSelector((state) => state.auth.user);

	const [minPrice, setMinPrice] = useState(searchParams.get('minPrice') !== null ? parseFloat(searchParams.get('minPrice')) : null);
	const [maxPrice, setMaxPrice] = useState(searchParams.get('maxPrice') !== null ? parseFloat(searchParams.get('maxPrice')) : null);

	const [minKms, setMinKms] = useState(searchParams.get('minKms') !== null ? parseFloat(searchParams.get('minKms')) : null);
	const [maxKms, setMaxKms] = useState(searchParams.get('maxKms') !== null ? parseFloat(searchParams.get('maxKms')) : null);

	const [brand, setBrand] = useState(searchParams.get('brand') !== null ? searchParams.get('brand') : null);
	const [company, setCompany] = useState(searchParams.get('company') !== null ? searchParams.get('company') : null);
	const [labeling, setLabeling] = useState(searchParams.get('labeling') !== null ? searchParams.get('labeling') : null);
	const [model, setModel] = useState(searchParams.get('model') !== null ? searchParams.get('model') : null);
	const [version, setVersion] = useState(searchParams.get('version') !== null ? searchParams.get('version') : null);

	const [orderByValue, setOrderByValue] = useState(searchParams.get('orderByValue') !== null ? parseInt(searchParams.get('orderByValue')) : null);
	const [orderByField, setOrderByField] = useState(searchParams.get('orderByField') !== null ? searchParams.get('orderByField') : null);

	const [startDate, setStartDate] = useState(searchParams.get('minYear') !== null ? new Date(parseInt(searchParams.get('minYear')), 0, 1) : null);
	const [finishDate, setFinishDate] = useState(searchParams.get('maxYear') !== null ? new Date(parseInt(searchParams.get('maxYear')), 0, 1) : null);

	const toggleIsUpdated = () => {
		dispatch(getCarProducts(productIndex, { ...productsReducer, isUpdated: !productsReducer.isUpdated, page: 1 }));
		searchParams.set("isUpdated", !productsReducer.isUpdated);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const toggleHasPrice = () => {
		dispatch(getCarProducts(productIndex, { ...productsReducer, hasPrice: !productsReducer.hasPrice, page: 1 }));
		searchParams.set("hasPrice", !productsReducer.hasPrice);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handlBrandChange = (value) => {
		if (value !== "[]") {
			dispatch(getCarProducts(productIndex, { ...productsReducer, brand: value, model: null, version: null, page: 1 }));
			dispatch(getCarProductsCompanies(productIndex, value, undefined))
			dispatch(getCarProductsModels(productIndex, value, searchParams.get('company')))
			dispatch(getCarProductsVersions(productIndex, value, searchParams.get('company'), null))
			setBrand(value)
			setModel(null)
			setVersion(null)
			searchParams.set("brand", value);
			searchParams.delete("version");
			searchParams.delete("model");
		}
		else {
			dispatch(getCarProducts(productIndex, { ...productsReducer, brand: null, model: null, version: null, page: 1 }))
			dispatch(getCarProductsCompanies(productIndex, undefined, undefined))
			setBrand(null)
			searchParams.delete("brand");
		};
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handleCompanyChange = (value) => {
		if (value !== "Todas las empresas") {
			dispatch(getCarProducts(productIndex, { ...productsReducer, company: value, model: null, page: 1 }));
			searchParams.set("company", value);
			dispatch(getCarProductsBrands(productIndex, value))
			dispatch(getCarProductsModels(productIndex, searchParams.get('brand'), value))
			setCompany(value)
		}
		else {
			dispatch(getCarProducts(productIndex, { ...productsReducer, company: null, model: null, page: 1 }));
			searchParams.delete("company");
			dispatch(getCarProductsBrands(productIndex, undefined))
			setCompany(null)
		}
		setModel(null)
		searchParams.delete("model");
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handleCurrencyChange = (value) => {
		dispatch(getCarProducts(productIndex, { ...productsReducer, currency: value }));
	}

	const handleMinYearChange = (value) => {
		if (value) {
			const variable = value.getFullYear()
			setStartDate(value)
			dispatch(getCarProducts(productIndex, { ...productsReducer, minYear: variable, page: 1 }))
			searchParams.set("minYear", variable);
			searchParams.set("page", 1);
		}
		else {
			setStartDate(value)
			dispatch(getCarProducts(productIndex, { ...productsReducer, minYear: 0, page: 1 }));
			searchParams.delete("minYear");
			searchParams.set("page", 1);
		}
		setSearchParams(searchParams);
	}

	const handleMaxYearChange = (value) => {
		if (value) {
			let maxVariable = value.getFullYear()
			setFinishDate(value)
			dispatch(getCarProducts(productIndex, { ...productsReducer, maxYear: maxVariable, page: 1 }))
			searchParams.set("maxYear", maxVariable);
			searchParams.set("page", 1);
		}
		else {
			setFinishDate(value)
			dispatch(getCarProducts(productIndex, { ...productsReducer, maxYear: 3000, page: 1 }));
			searchParams.delete("maxYear");
			searchParams.set("page", 1);
		}
		setSearchParams(searchParams);
	}

	const handleChangeMinPrice = (value) => {
		setMinPrice(value)
		dispatch(getCarProducts(productIndex, { ...productsReducer, minPrice: value, page: 1 }));
		searchParams.set("minPrice", value);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handleChangeMaxPrice = (value) => {
		setMaxPrice(value)
		dispatch(getCarProducts(productIndex, { ...productsReducer, maxPrice: value, page: 1 }));
		searchParams.set("maxPrice", value);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handleChangeMinKms = (value) => {
		setMinKms(value)
		dispatch(getCarProducts(productIndex, { ...productsReducer, minKms: value, page: 1 }));
		searchParams.set("minKms", value);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handleChangeMaxKms = (value) => {
		setMaxKms(value)
		dispatch(getCarProducts(productIndex, { ...productsReducer, maxKms: value, page: 1 }));
		searchParams.set("maxKms", value);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handlLabelingChange = (value) => {
		if (value !== "Sin seleccionar") {
			dispatch(getCarProducts(productIndex, { ...productsReducer, labeling: value, page: 1 }));
			searchParams.set("labeling", value);
			//dispatch(getCarProductsBrands(productIndex, value))
			//dispatch(getCarProductsModels(productIndex, searchParams.get('brand'), value))
			setLabeling(value)
		}
		else {
			dispatch(getCarProducts(productIndex, { ...productsReducer, labeling: null, page: 1 }));
			searchParams.delete("labeling");
			setLabeling(null)
		}
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handlModelChange = (value) => {
		if (value !== "[]") {
			dispatch(getCarProductsVersions(productIndex, searchParams.get('brand'), searchParams.get('company'), value))
			dispatch(getCarProducts(productIndex, { ...productsReducer, model: value, version: null, page: 1 }));
			setModel(value)
			searchParams.set("model", value);
		}
		else {
			dispatch(getCarProducts(productIndex, { ...productsReducer, model: null, version: null, page: 1 }))
			setModel(null)
			searchParams.delete("model");
		};
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handlVersionChange = (value) => {
		if (value !== "[]") {
			dispatch(getCarProductsCompanies(productIndex, brand, value))
			dispatch(getCarProducts(productIndex, { ...productsReducer, version: value, page: 1 }));
			setVersion(value)
			searchParams.set("version", value);
		}
		else {
			dispatch(getCarProductsCompanies(productIndex, brand, undefined))
			dispatch(getCarProducts(productIndex, { ...productsReducer, version: null, page: 1 }))
			setVersion(null)
			searchParams.delete("version");
		};
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handleOrderChange = (value) => {
		setOrderByField(value.field)
		setOrderByValue(value.value)
		dispatch(getCarProducts(productIndex, { ...productsReducer, orderByField: value.field, orderByValue: value.value }));
		searchParams.set("orderByField", value.field);
		searchParams.set("orderByValue", value.value);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handleResetFilters = () => {
		setBrand(null)
		setModel(null)
		setVersion(null)

		setStartDate(null)
		setFinishDate(null)

		setOrderByField("normalizedPrice")
		setOrderByValue(1)

		setMinPrice("")
		setMaxPrice("")

		setMinKms("")
		setMaxKms("")

		setCompany(null)
		setLabeling(null)

		searchParams.delete("brand");
		searchParams.delete("company");
		searchParams.delete("labeling");
		searchParams.delete("maxKms");
		searchParams.delete("maxPrice");
		searchParams.delete("maxYear");
		searchParams.delete("minKms");
		searchParams.delete("minPrice");
		searchParams.delete("minYear");
		searchParams.delete("model");
		searchParams.delete("version");
		searchParams.delete("orderByField");
		searchParams.delete("orderByValue");
		searchParams.set("page", 1);
		setSearchParams(searchParams);

		dispatch(getCarProducts(productIndex,
			{
				...productsReducer,
				brand: null,
				company: null,
				labeling: null,
				maxKms: null,
				maxPrice: null,
				maxYear: null,
				minKms: null,
				minPrice: null,
				minYear: null,
				model: null,
				version: null,
				models: [],
				versions: [],
				orderByField: "normalizedPrice",
				orderByValue: 1,
			}));

		dispatch(getCarProductsBrands(productIndex, null))
		dispatch(getCarProductsCompanies(productIndex, null, null))
	}

	return (
		<>
			 {hasViewPermission('priceLists', user) &&  <Accordion variant="contained" className="car-filters-container" defaultValue="filters">
				<Accordion.Item key={1} value="filters">
					<Accordion.Control>COMERCIALIZACION</Accordion.Control>
					<Accordion.Panel>
						<NativeSelect
							label="Lista de precio seleccionada"
							onChange={(e) => {
								setSelectedPricelist(priceLists.find((priceList) => priceList._id === e.target.value));
							}}
							size="xs"
						>
							{priceLists.map((priceList) => {
								return (
									<option value={priceList._id} key={priceList._id}>
										{priceList.name}
									</option>
								);
							})}
						</NativeSelect>
					</Accordion.Panel>
				</Accordion.Item>
			</Accordion>}
			<Accordion variant="contained" className="car-filters-container" defaultValue="filters">
				<Accordion.Item key={1} value="filters">
					<Accordion.Control>FILTROS</Accordion.Control>
					<Accordion.Panel>

						<NativeSelect
							label="Ordenar por"
							size="xs"
							onChange={(e) => { handleOrderChange(JSON.parse(e.target.value)) }}
							>
							<option selected={orderByValue === 1 && orderByField === "normalizedPrice"} value={JSON.stringify({ field: "normalizedPrice", value: 1 })} key={"priceUpward"}>Precio ascendente</option>
							<option selected={orderByValue === -1 && orderByField === "normalizedPrice"} value={JSON.stringify({ field: "normalizedPrice", value: -1 })} key={"priceDownward"}>Precio descendente</option>
							<option selected={orderByValue === 1 && orderByField === "info.year"} value={JSON.stringify({ field: "info.year", value: 1 })} key={"yearUpward"}>Antiguedad ascendente</option>
							<option selected={orderByValue === -1 && orderByField === "info.year"} value={JSON.stringify({ field: "info.year", value: -1 })} key={"yearDownward"}>Antiguedad descendente</option>
							<option selected={orderByValue === 1 && orderByField === "info.kms"} value={JSON.stringify({ field: "info.kms", value: 1 })} key={"kmsUpward"}>Kilometraje ascendente</option>
							<option selected={orderByValue === -1 && orderByField === "info.kms"} value={JSON.stringify({ field: "info.kms", value: -1 })} key={"kmsDownward"}>Kilometraje descendente</option>
						</NativeSelect>

						<NativeSelect
							label="Empresa"
							size="xs"
							onChange={(e) => { handleCompanyChange(e.target.value) }}
							value={company}
							>
							<option selected={!company} value={null} key={"allCompanies"}>Todas las empresas</option>
							{productsReducer.companies.map((item) => {
								return (<option value={item._id} key={item._id} style={{ textTransform: "capitalize" }}>{item._id} ({item.count})</option>);
							})}
						</NativeSelect>

						<NativeSelect
							label="Etiquetas"
							size="xs"
							onChange={(e) => { handlLabelingChange(e.target.value) }}
							value={labeling}
							>
							<option selected={!labeling} value={null} key={"allCompanies"}>Sin seleccionar</option>
							{labelingReducer.labelingList.map((item) => {
								return (<option value={item._id} key={item._id} style={{ textTransform: "capitalize" }}>{item.name}</option>);
							})}
						</NativeSelect>

						<MultiSelect
							label="Marca"
							size="xs"
							onChange={(e) => { handlBrandChange(e) }}
							data={productsReducer.brands.map((brand) => `${brand._id} (${brand.count})`)}
							searchable
							clearable
							hidePickedOptions
							/>

						{brand && brand.length > 0 && <MultiSelect
							label="Modelo"
							size="xs"
							onChange={(e) => { handlModelChange(e) }}
							data={productsReducer.models.map((model) => `${model._id} (${model.count})`)}
							searchable
							clearable
							hidePickedOptions
							/>}

						{model && model.length > 0 && <MultiSelect
							label="Versión"
							size="xs"
							onChange={(e) => { handlVersionChange(e) }}
							data={productsReducer.versions.map((version) => `${version._id} (${version.count})`)}
							searchable
							clearable
							hidePickedOptions
							/>}

						<div className="filters-dates">
							<h4>Año</h4>
							<div>
								<YearPickerInput
									clearable
									value={startDate}
									hideOutsideDates
									onChange={(e) => handleMinYearChange(e)}
									placeholder="Año minimo"
									maxDate={finishDate ? finishDate : new Date()}
									size="xs"
									/>
								<YearPickerInput
									clearable
									value={finishDate}
									hideOutsideDates
									onChange={(e) => { handleMaxYearChange(e) }}
									placeholder="Año maximo"
									minDate={startDate}
									maxDate={new Date()}
									size="xs"
									/>
							</div>
						</div>

						<div className="filters-numbers">
							<h4>Precio</h4>
							<div>
								<NumberInput
									min={0}
									max={maxPrice || undefined}
									placeholder="Minimo"
									prefix="$"
									size="xs"
									step={productsReducer.currency === "ARS" ? 1000000 : 1000}
									thousandSeparator=","
									value={minPrice}
									onChange={handleChangeMinPrice}
									/>
								<NumberInput
									min={minPrice || 0}
									placeholder="Maximo"
									prefix="$"
									size="xs"
									step={productsReducer.currency === "ARS" ? 1000000 : 1000}
									thousandSeparator=","
									value={maxPrice}
									onChange={handleChangeMaxPrice}
									/>
							</div>
						</div>

						<div className="filters-numbers">
							<h4>Kilometraje</h4>
							<div>
								<NumberInput
									min={0}
									max={maxKms || undefined}
									placeholder="Minimo"
									suffix="kms"
									size="xs"
									step={1000}
									thousandSeparator=","
									value={minKms}
									allowDecimal={false}
									onChange={handleChangeMinKms}
									/>
								<NumberInput
									min={minKms || 0}
									placeholder="Maximo"
									suffix="kms"
									size="xs"
									step={1000}
									thousandSeparator=","
									value={maxKms}
									allowDecimal={false}
									onChange={handleChangeMaxKms}
									/>
							</div>
						</div>

						<section>
							<h4>Tipo de moneda</h4>
							{currency && <NativeSelect
								size="xs"
								onChange={(e) => { handleCurrencyChange(e.target.value) }}
								value={productsReducer.currency}
								>
								<option value={currency.name} key={currency.name}>{currency.name}</option>
								{Object.keys(currency.convertTo).map((item) => {
									return (<option value={item} key={item}>{item}</option>);
								})}
							</NativeSelect>}
						</section>

						<Switch
							checked={productsReducer.isUpdated}
							className="mantine-Switch-track-aa"
							color="var(--color-gray-blue)"
							labelPosition="left"
							label="Solo productos actualizados"
							size="md"
							onClick={toggleIsUpdated}
							/>

						<Switch
							checked={productsReducer.hasPrice}
							className="mantine-Switch-track-aa"
							color="var(--color-gray-blue)"
							labelPosition="left"
							label="Solo productos con precio"
							size="md"
							onClick={toggleHasPrice}
							/>

						<h6 onClick={handleResetFilters}>Resetear filtros</h6>
					</Accordion.Panel>
				</Accordion.Item>
			</Accordion>
		</>
	);
}
