import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getProductIndices } from '../../../../redux/actions/entities/marketPlayerActions';

import circle_check from '../../../../assets/svg/information-icons/circle_check.svg'
import circle_cross from '../../../../assets/svg/information-icons/circle_cross.svg'

import { TextSearcher } from '../../../utilities/components/TextSearcher';
import SectionAccordion from '../../../utilities/components/SectionAccordion';

export const IndexSearchIndices = ({ handleSelectedIndex }) => {
    const dispatch = useDispatch();
    const productsIndices = useSelector((state) => state.marketPlayer.indices);
    const navbarCategories = useSelector((state) => state.navbar.categories);

    const [params] = useSearchParams();
    const [marketPlayerID] = useState(params.get("id") || "");

    const [searchFilter, setSearchFilter] = useState("")
    const [actualProductsIndices, setActualProductsIndices] = useState(productsIndices || [])

    const errorQuantity = productsIndices.filter((productIndex) => {
        if (productIndex?.status?.code > 300) return productIndex
        return null
    }).length

    const warningQuantity = productsIndices.filter((productIndex) => {
        if (productIndex?.status?.code === 204) return productIndex
        return null
    }).length

    useEffect(() => {
        dispatch(getProductIndices(marketPlayerID));
    }, [dispatch, marketPlayerID]);

    useEffect(() => {
        if (searchFilter === "") setActualProductsIndices(productsIndices)
        else {
            const newActualProductsIndices = productsIndices.filter((productIndex) => {
                if (productIndex.indexPath.includes(searchFilter)) return productIndex
                return null
            })
            setActualProductsIndices(newActualProductsIndices)
        }
    }, [searchFilter, productsIndices])

    return (
        <>
            <SectionAccordion title="Indices" openClass="indexSearchIndices-container">
                <div>
                    <TextSearcher placeholder="Categoría, subcategoría o indice..." setSearchFilter={setSearchFilter} />
                    {errorQuantity ?
                        <h2 style={{ color: 'var(--color-red)' }}><img alt="no-error-icon" src={circle_cross} /> Se {errorQuantity > 1 ? "han" : "ha"} encontrado {errorQuantity} {errorQuantity > 1 ? "errores" : "error"} </h2> :
                        <h2 ><img src={circle_check} alt="error-icon" /> No se ha encontrado ningun error </h2>
                    }
                    {warningQuantity > 0 &&
                        <h2 style={{ color: 'var(--color-gray-blue)' }}> (Se {warningQuantity > 1 ? "han" : "ha"} encontrado {warningQuantity} {warningQuantity > 1 ? "extracciones" : "extracción"} sin productos)</h2>}
                </div>
                {navbarCategories.map((category) => {
                    return <div key={category._id}>
                        <h3>{category.name}</h3>
                        {category.subcategories.map((subcategory, index) => {
                            return <div key={index}>
                                <h4>{subcategory.name}</h4>
                                <div className="indexSearchIndices-category">
                                    {actualProductsIndices.length > 0 && actualProductsIndices.map((productIndex) => {
                                        const path = productIndex.indexPath ? productIndex.indexPath.split('-') : ""
                                        if (category.name.toLowerCase().replace(/ /g, "_") === path[0] && subcategory.name.toLowerCase().replace(/ /g, "_") === path[1]) {
                                            return <section key={productIndex._id}
                                                onClick={() => handleSelectedIndex(productIndex)}
                                                className={productIndex?.status?.code > 300 ? "indexSearchIndices-category-error" :
                                                    productIndex?.status?.code === 204 ? "indexSearchIndices-category-warning" : ""} >
                                                {path[2].replace(/_/g, " ")}
                                            </section>
                                        }
                                        return null
                                    })}
                                </div>
                            </div>
                        })}
                    </div>
                })}
            </SectionAccordion>
        </>
    )
}