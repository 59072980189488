import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";

import { deletePriceList, getPriceLists, postPriceList, updatePriceList } from '../../../redux/actions/utilities/priceListAction';
import { deleteCommercialCondition, getCommercialConditions, postCommercialCondition, updateCommercialCondition } from '../../../redux/actions/utilities/commecialConditionAction';

import ConfirmationModal from '../../utilities/Modal/ConfirmationModal';
import StatusModal from '../../utilities/Modal/StatusModal';
import CommercializationCreationModal from './modals/CommercializationCreationModal';

import { hasViewPermission } from '../permissions/utilities/hasPermissions';
import LabelingManager from '../../utilities/components/labelingManager/LabelingManager';

export const Commercialization = () => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user);
    const priceList = useSelector((state) => state.priceList);
    const commercialCondition = useSelector((state) => state.commercialCondition);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [showCreationModal, setShowCreationModal] = useState(false)
    const [showStatusModal, setShowStatusModal] = useState(false)

    document.title = "Scope | Comercialización"

    useEffect(() => {
        if (!showStatusModal) {
            dispatch(getPriceLists());
            dispatch(getCommercialConditions())
        }
    }, [showStatusModal, dispatch])

    const handlerShowConfirmationModal = () => {
        if (showConfirmationModal.type === 'Lista de precio') {
            dispatch(deletePriceList(showConfirmationModal.id))
        } else {
            dispatch(deleteCommercialCondition(showConfirmationModal.id))
        }
        setShowConfirmationModal(false);
        setShowStatusModal(true)
    }

    const handlerCreationModalAction = (name, percentage) => {
        if (showCreationModal.action === 'Crear') {
            if (showCreationModal.type === 'Lista de precio') dispatch(postPriceList(name, percentage))
            else dispatch(postCommercialCondition(name, percentage))
        } else {
            if (showCreationModal.type === 'Lista de precio') dispatch(updatePriceList(name, percentage, showCreationModal.values._id));
            else dispatch(updateCommercialCondition(name, percentage, showCreationModal.values._id))
        }
        setShowCreationModal(false)
        setShowStatusModal(true)
    }

    return (
        <div className="cuentas-contenedor">
            {hasViewPermission('commercialConditions', user) && ( <LabelingManager 
                collection={commercialCondition.commercialConditions}
                information="La condicion comercial aplica directamente al precio de venta. Solo se puede aplicar una condicion comercial a la vez. El porcentaje aplicado no se muestra a quien no tenga disponible su vista."
                permissions="commercialConditions"
                setShowConfirmationModal={setShowConfirmationModal} 
                setShowCreationModal={setShowCreationModal}
                title="Condiciones comerciales"
                type="condición comercial"
                user={user}
            />)}

            {hasViewPermission('priceLists', user) && (<LabelingManager 
                collection={priceList.priceList}
                information="Los porcentajes se aplican a los costos. Solo se puede aplicar una lista de precios a la vez. El porcentaje aplicado no se muestra a quien no tenga disponible su vista."
                permissions="priceLists"
                setShowConfirmationModal={setShowConfirmationModal} 
                setShowCreationModal={setShowCreationModal}
                title="Listas de precios"
                type="Lista de precio"
                user={user}
            />)}

            {showConfirmationModal &&
                <ConfirmationModal
                    descriptionText={`¿Está seguro que quiere eliminar la ${showConfirmationModal.type} "${showConfirmationModal.name}"?`}
                    handlerShowConfirmationModal={handlerShowConfirmationModal}
                    setShowConfirmationModal={setShowConfirmationModal}
                    titleText={`Eliminar ${showConfirmationModal.type}`}
                />}
            {showCreationModal &&
                <CommercializationCreationModal
                    handlerCreationModalAction={handlerCreationModalAction}
                    showCreationModal={showCreationModal}
                    setShowCreationModal={setShowCreationModal}
                />}
            {showStatusModal && (
                <StatusModal loading={priceList.loading || commercialCondition.loading} error={priceList.error || commercialCondition.error} setModalStatusCloseAction={setShowStatusModal}>
                    {priceList.message || commercialCondition.message}
                </StatusModal>
            )}
        </div>
    )
}
