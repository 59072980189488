import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Accordion } from '@mantine/core';

import "./currencyCard.css";
import "../recommendations.css"

export const CurrencyCard = () => {

	const currency = useSelector((state) => state.currency.data);

	const [currencyPrice, setCurrencyPrice] = useState(currency);

	useEffect(() => {
		setCurrencyPrice(currency);
	}, [currency])

	const getPrettyDate = (input) => {
		let date = input.split('T')[0].split('-')
		let time = input.split('T')[1].split(':')
		return date[2] + "/" + date[1] + " " + time[0] + ":" + time[1]
	}

	const formatearNumero = (valor) => {
		return new Intl.NumberFormat("de-DE", {
			minimumFractionDigits: 2,  // Mínimo de 2 decimales
			maximumFractionDigits: 2   // Máximo de 2 decimales
		}).format((valor * 1));
	};

	//TODO: Mejorar el label de la moneda en lugar de hacer caso por caso

	return (
		currencyPrice && (
			<Accordion variant="contained" className="recommendation-container" defaultValue="currencyCard-container">
				<Accordion.Item key={1} value="currencyCard-container">
					<Accordion.Control>
						<div className="currencyCard-circle"></div>
						<span>Cotización divisas</span>
					</Accordion.Control>
					<Accordion.Panel>
						{Object.entries(currency.convertTo).map((item) => {
							return <div className="currencyCard" key={item[0]}>
								<section>
									<h4>{item[0] === "USD" ? "Dólar oficial" : 
										item[0] === "USDMEP" ? "Dólar MEP" :
										item[0] === "BRL" ? "Real brasileño" : item[0]}</h4>
									<span>${formatearNumero(item[1].value)}</span>
								</section>
								<div>
									<h5>{getPrettyDate(item[1].updatedTimestamp)} | </h5>
									<a href={item[1].source} target="blank">{item[1].source.split('//')[1]}</a>
								</div>
							</div>
						})}
					</Accordion.Panel>
				</Accordion.Item>
			</Accordion>
		)
	);
};
