import {
    ACTION_CLIENT_ERROR,
    ACTION_CLIENT_START,
    ACTION_CLIENT_SUCCESS,
    ADD_CLIENTS_SUCCESS,
    GET_CLIENT_SUCCESS,
    GET_CLIENTS_SUCCESS,
} from "../../types/index";

const initialState = {
    error: false,
    clients: {},
    message: "",
    loading: false,
    selectedClient: {},
    count: 1,
    page: 1
};

export default function clientReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_CLIENT_START:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case ACTION_CLIENT_ERROR:
            return {
                ...state,
                error: true,
                message: action.payload,
                loading: false,
            };
        case ACTION_CLIENT_SUCCESS:
            return {
                ...state,
                error: false,
                message: action.payload,
                loading: false,
            };
        case ADD_CLIENTS_SUCCESS:
            return {
                ...state,
                error: false,
                clients: action.payload.clients,
                message: action.payload.message,
                loading: false
            }
        case GET_CLIENT_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                selectedClient: action.payload,
            }
        case GET_CLIENTS_SUCCESS:
            return {
                ...state,
                error: false,
                clients: action.payload.clients,
                message: "",
                loading: false,
                count: action.payload.count,
                page: action.payload.page
            }
        default:
            return state;
    }
}
