import React, { useState, useEffect } from "react";
import { Routes, Route, HashRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { v4 as uuidv4 } from "uuid";

import { authUser, resetearEstadoErrorUser } from "../redux/actions/utilities/authAction";
import Home from "../components/home/Home.js";
import Navbar from "../components/navbar/Navbar";
import Login from "../components/login/Login";
import NotFound from "../components/notFound/notFound.js";
import SelectedProduct from "../components/products/generic/selectedProduct/SelectedProduct.js";
import CarSelectedProduct from "../components/products/cars/carSelectedProduct/CarSelectedProduct";
import MarketPlayers from "../components/entities/marketPlayers/MarketPlayers.js";
import CargaPlanillas from "../components/cargaPlanillas/CargaPlanillas.js";
import Quotation from "../components/quotation/Quotation";
import Products from "../components/products/generic/Products";
import CarProducts from "../components/products/cars/CarProducts";
import Categorization from "../components/configuration/categorization/Categorization.js";
import { IndexSearch } from "../components/entities/marketPlayers/indexSearch/IndexSearch.js";
import { Commercialization } from "../components/configuration/commercialization/Commercialization.js";
import Clients from "../components/entities/clients/Clients.js";
import { Client } from "../components/entities/clients/Client.js";
import Collaborators from "../components/entities/collaborators/Collaborators.js";
import { Collaborator } from "../components/entities/collaborators/Collaborator.js";
import ActivityLogs from "../components/activityLogs/activityLogs";

import '@mantine/dates/styles.css';
import Permissions from "../components/configuration/permissions/Permissions";
import ProtectedRoutes from "../components/utilities/privateRoutes/ProtectedRoutes";
import CarsMarketValue from "../components/charts/carsMarketValue/CarsMarketValue.js";

export default function App() {
	const dispatch = useDispatch();

	const navbar = useSelector((state) => state.navbar);
	const auth = useSelector((state) => state.auth);
	const navbarCategories = useSelector((state) => state.navbar.categories);

	const [logInStatus, setLogInStatus] = useState(localStorage.getItem("LogInStatus") === "true");

	useEffect(() => {
		dispatch(authUser())
	}, [dispatch])

	useEffect(() => {
		if (auth.error) {
			setLogInStatus(false);
			localStorage.removeItem("user");
			localStorage.setItem("LogInStatus", false);
			dispatch(resetearEstadoErrorUser());
		}
	}, [auth.error, dispatch]);

	useEffect(() => {
		if (auth.user) {
			setLogInStatus(true)
		}
	}, [auth.user])

	return (
		<HashRouter>
			<div>
				{logInStatus && navbar.show && <Navbar />}
				<Routes>
					<Route path="/" element={<Login />} />
					<Route path="/Dashboard" element={<Home />} />
					<Route element={<ProtectedRoutes resources={["products"]} />}>
						{navbarCategories?.map((category) => {
						return category.subcategories.map((subcategory) => {
							return subcategory.indices.map((index) => {
								const completeIndex = `${category.name.replace(/ /g, "_").toLowerCase()}-${subcategory.name.replace(/ /g, "_").toLowerCase()}-${index.name.replace(/ /g, "_").toLowerCase()}`;
								return (subcategory.productType?.name === 'cars' ?
									(<Route key={uuidv4()} path={`/${completeIndex}`} element={<CarProducts key={uuidv4()} indexID={[index._id]} indexPath={completeIndex} />} />) :
									(<Route key={uuidv4()} path={`/${completeIndex}`} element={<Products key={uuidv4()} indexID={[index._id]} indexPath={completeIndex} />} />));
								});
							});
						})}

						{navbarCategories?.map((category) => {
							return category.subcategories.map((subcategory) => {
								const subcategoryIndicesIds = subcategory.indices.map(index => index._id);
								const completeIndex = `${category.name.replace(/ /g, "_").toLowerCase()}-${subcategory.name.replace(/ /g, "_").toLowerCase()}`;
								return (subcategory.productType.name === 'cars' ?
									(<Route key={uuidv4()} path={`/${completeIndex}`} element={<CarProducts key={uuidv4()} indexID={subcategoryIndicesIds} indexPath={completeIndex} />} />) :
									(<Route key={uuidv4()} path={`/${completeIndex}`} element={<Products key={uuidv4()} indexID={subcategoryIndicesIds} indexPath={completeIndex} />} />)
								);
							});
						})}
						{auth.defaultProductType?.name === 'cars' ? 
							<Route path="/allProducts" element={<CarProducts key={uuidv4()} indexID={["allProducts"]} indexPath="allProducts" />} />
							: 
							<Route path="/allProducts" element={<Products key={uuidv4()} indexID={["allProducts"]} indexPath="allProducts" />} />
						}
					</Route>
					<Route path="/Producto" element={<SelectedProduct />} />
					<Route path="/CarProducto" element={<CarSelectedProduct />} />
					<Route element={<ProtectedRoutes resources={["clients"]} />}>
						<Route path="/Clientes" element={<Clients />} />
						<Route path="/Cliente" element={<Client />} />
					</Route>
					<Route element={<ProtectedRoutes resources={["collaborators"]} />}>
						<Route path="/Colaboradores" element={<Collaborators />} />
						<Route path="/Colaborador" element={<Collaborator />} />
					</Route>
					<Route element={<ProtectedRoutes resources={["quotations"]} />}>
						<Route path="/Cotizaciones" element={<Quotation />} />
					</Route>
					<Route element={<ProtectedRoutes resources={["spreadsheets"]} />}>
						<Route path="/CargaPlanillas" element={<CargaPlanillas />} />
					</Route>
					<Route element={<ProtectedRoutes resources={["priceLists", "commercialConditions"]} />}>
						<Route path="/Comercializacion" element={<Commercialization />} />
					</Route>
					<Route element={<ProtectedRoutes resources={["marketPlayers"]} />}>
						<Route path="/ActoresDeMercado" element={<MarketPlayers />} />
					</Route>
					<Route element={<ProtectedRoutes resources={["permissions"]} />}>
						<Route path="/Permisos" element={<Permissions />} />
					</Route>
					<Route element={<ProtectedRoutes resources={["categorization"]} />}>
						<Route path="/Categorizacion" element={<Categorization />} />
					</Route>
					<Route element={<ProtectedRoutes resources={["activityLogs"]} />}>
						<Route path="/Actividad" element={<ActivityLogs />} />
					</Route>
					<Route element={<ProtectedRoutes resources={["carsMarketValues"]} />}>
						<Route path="/ValoresDeMercado" element={<CarsMarketValue />} />
					</Route>
					<Route path="/BuscadorIndex" element={<IndexSearch />} />
					<Route path="/*" element={<NotFound />} />
				</Routes>
			</div>
		</HashRouter>
	);
}
