import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { deleteMarketPlayer, enableMarketPlayer } from "../../../redux/actions/entities/marketPlayerActions";

import ModalModificarCuenta from "./modales/ModalEditMarketPlayer";
import ConfirmationModal from "../../utilities/Modal/ConfirmationModal";

import iconEdit from "../../../assets/svg/functionalities-icons/lapiz-editar.svg";
import iconDelete from "../../../assets/svg/functionalities-icons/trash-can.svg";

import "./marketPlayers.css";

const CardMarketPlayer = ({ permisoAdministrador, index, marketPlayer }) => {
	const dispatch = useDispatch();
	const navegation = useNavigate();

	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showConfirmEnableModal, setShowConfirmEnableModal] = useState(false);

	const handleDeleteMarketPlayer = () => {
		dispatch(deleteMarketPlayer(marketPlayer));
		setShowDeleteModal(false);
	}

	const handleEnableMarketPlayer = () => {
		setShowConfirmEnableModal(true);
		setShowEditModal(false);
	};

	const handleCloseEnableMarketPlayer = (response) => {
		if (!response) {
			setShowConfirmEnableModal(false);
			setShowEditModal(true);
		} else {
			setShowConfirmEnableModal(false);
			dispatch(enableMarketPlayer(marketPlayer._id));
		}
	};

	const handleContainerClick = (event) => {
		if (event.target.localName !== "img" && permisoAdministrador)
			navegation(`/BuscadorIndex?id=${marketPlayer._id}`);
	}

	return (
		<>
			<div
				className={`cardMarketPlayer-container ${!marketPlayer.isEnabled ? "cardMarketPlayer-container-disabled" : ""}`}
				key={index} onClick={(event) => handleContainerClick(event)}
				style={!marketPlayer.isEnabled ? { background: "var(--color-gray-ligth)" } : marketPlayer.status.hasError ? { background: "linear-gradient(to bottom, white 95%, var(--color-red) 95%)" } :
					marketPlayer.status.hasNoProducts ? { background: "linear-gradient(to bottom, white 95%, var(--color-gray-blue) 95%)" } :
						{ background: "linear-gradient(to bottom, white 95%, var(--color-green) 95%)" }}
			>
				{permisoAdministrador && (
					<section>
						<img onClick={() => setShowEditModal(true)} src={iconEdit} type="button" alt="modify" />
						<img
							onClick={() => {
								setShowDeleteModal(true);
							}}
							src={iconDelete}
							type="button"
							alt="delete"
						/>
					</section>
				)}
				<div>
					<h3>{marketPlayer && marketPlayer.company.name}</h3>
					{!marketPlayer.isEnabled && <h6>Deshabilitado</h6>}
					{marketPlayer && marketPlayer.credentials.username && <h4>Usuario: {marketPlayer.credentials.username}</h4>}
				</div>
			</div>
			{showEditModal && (
				<ModalModificarCuenta
					setShowEditModal={setShowEditModal}
					marketPlayer={marketPlayer}
					handleEnableMarketPlayer={handleEnableMarketPlayer}
				/>
			)}
			{showDeleteModal &&
				<ConfirmationModal
					descriptionText={`¿Estas seguro que quieres eliminar al actor de mercado "${marketPlayer.company.name.toUpperCase()}"?`}
					handlerShowConfirmationModal={handleDeleteMarketPlayer}
					setShowConfirmationModal={setShowDeleteModal}
					titleText={`Eliminar ${marketPlayer.company.isSupplier ? "proveedor" : "competencia"}`}
				/>}
			{showConfirmEnableModal && (
				<ConfirmationModal
					descriptionText={`¿Estas seguro que quieres ${marketPlayer.isEnabled ? "deshabilitar" : "habilitar"} esta cuenta?`}
					handlerShowConfirmationModal={handleCloseEnableMarketPlayer}
					setShowConfirmationModal={setShowConfirmEnableModal}
					titleText="Habilitacion"
				/>
			)}
		</>
	);

};


export default CardMarketPlayer;
