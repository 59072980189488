import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { NativeSelect, Select } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';

import { changeQuotation, getquotationRecord, getQuotationStates } from "../../../redux/actions/utilities/quotationAction";
import { getClients } from '../../../redux/actions/entities/clientAction';
import { getCollaborators } from '../../../redux/actions/entities/collaboratorAction';

import downloadIcon from "../../../assets/svg/functionalities-icons/download.svg";
import quotation_icon from "../../../assets/svg/menu-icons/quotation-icon.svg";
import expandArrow from "../../../assets/svg/menu-icons/flecha-triangular.svg";

import './quotationRecord.css'
import { PDFDownloadLink } from "@react-pdf/renderer";
import QuotationDownload from "../QuotationDownload";
import { StatsSegments } from "../../statsGraphs/statsSegments/StatsSegments";
import SectionAccordion from "../../utilities/components/SectionAccordion";

export const QuotationRecord = ({ clientID, collaboratorID, isHome }) => {
    const dispatch = useDispatch();

    const quotationRecord = useSelector((state) => state.quotation.record || [])
    const quotationStates = useSelector((state) => state.quotation.states || [])
    const user = useSelector((state) => state.auth.user)

    const collaborators = useSelector((state) => state.collaborators.collaborators)
    const clients = useSelector((state) => state.clients.clients)
    const userCompany = useSelector((state) => state.auth.company);
    const currency = useSelector((state) => state.products.currency)

    const [startDate, setStartDate] = useState(null);
    const [finishDate, setFinishDate] = useState(null);

    const quotationsPerPage = 12
    const [quotationQuantity, setQuotationQuantity] = useState(quotationsPerPage)

    const [selectedClient, setSelectedClient] = useState(clientID || undefined)
    const [selectedCollaborator, setSelectedCollaborator] = useState(collaboratorID || undefined)
    const [selectedState, setSelectedState] = useState(undefined)

    useEffect(() => {
        dispatch(getClients())
        dispatch(getCollaborators())
        dispatch(getQuotationStates())
        dispatch(getquotationRecord(selectedClient, selectedCollaborator, undefined, quotationQuantity, startDate, finishDate, isHome))
    }, [dispatch])

    useEffect(() => {
        dispatch(getquotationRecord(selectedClient, selectedCollaborator, selectedState, quotationQuantity, startDate, finishDate, isHome))
    }, [selectedClient, selectedCollaborator, selectedState, quotationQuantity, startDate, finishDate, dispatch])

    const handleOpenQuotationClick = (clientID, quotationID) => {
        dispatch(changeQuotation(clientID, quotationID, currency))
    }

    const handleSelectClient = (selectedID) => {
        if (selectedClient !== selectedID) setSelectedClient(selectedID)
        else setSelectedClient("")
    }

    const handleSelectCollaborator = (selectedID) => {
        if (selectedCollaborator !== selectedID) setSelectedCollaborator(selectedID)
        else setSelectedCollaborator("")
    }

    let clientsName = []
    if (clients.length) {
        clientsName = clients.map((client) => {
            return {
                ...client,
                value: client._id,
                label: client.info.name
            }
        })
    }

    let collaboratorsName = []
    if (collaborators.length) {
        collaboratorsName = collaborators.map((collaborator) => {
            return {
                ...collaborator,
                value: collaborator._id,
                label: collaborator.info.lastName + " " + collaborator.info.name
            }
        })
    }

    const clientsOptionsFilter = ({ options, search }) => {
        if (clientsName.length) {
            const filtered = clientsName.filter((client) =>
                client.info.name.toLowerCase().trim().includes(search.toLowerCase().trim())
                || client.info.fantasyName.toLowerCase().trim().includes(search.toLowerCase().trim())
                || client.info.CUIT.toLowerCase().trim().includes(search.toLowerCase().trim())
            );

            return filtered;
        }
    };

    const collaboratorsOptionsFilter = ({ options, search }) => {
        try {
            if (collaboratorsName.length) {
                const filtered = collaboratorsName.filter((collaborator) =>
                    collaborator.info.name.toLowerCase().trim().includes(search.toLowerCase().trim())
                    || collaborator.info.lastName.toLowerCase().trim().includes(search.toLowerCase().trim())
                    || collaborator.info.CUIL.toLowerCase().trim().includes(search.toLowerCase().trim())
                );

                return filtered;
            }
        } catch {
            return collaboratorsName
        }
    };

    return (
        <div style={{ marginTop: "50px" }}>
            <SectionAccordion title="Historial de cotizaciones">
                <section className="quotationRecord-filters-container">
                    <div className="quotationRecord-filters-dates">
                        <DatePickerInput
                            clearable
                            value={startDate}
                            hideOutsideDates
                            locale="es"
                            onChange={setStartDate}
                            placeholder="Fecha inicial"
                            maxDate={new Date()}
                            size="xs"
                        />
                        <DatePickerInput
                            clearable
                            value={finishDate}
                            hideOutsideDates
                            locale="es"
                            onChange={setFinishDate}
                            placeholder="Fecha final"
                            maxDate={new Date()}
                            size="xs"
                        />
                    </div>
                    <div>
                        {(collaboratorID || (!clientID && !collaboratorID)) && clientsName.length > 0 &&
                            <Select
                                placeholder="Filtre por cliente"
                                data={clientsName}
                                filter={clientsOptionsFilter}
                                nothingFoundMessage="Sin resultados..."
                                searchable
                                size="xs"
                                onOptionSubmit={(event) => handleSelectClient(event)}
                                value={selectedClient}
                            />
                        }

                        {collaboratorsName.length > 0 && clientID && user.role?.access !== "user" &&
                            <Select
                                placeholder="Filtre por colaborador"
                                data={collaboratorsName}
                                filter={collaboratorsOptionsFilter}
                                nothingFoundMessage="Sin resultados..."
                                searchable
                                size="xs"
                                onOptionSubmit={(event) => handleSelectCollaborator(event)}
                                value={selectedCollaborator}
                            />
                        }

                        {quotationStates.length > 0 && <NativeSelect size="xs" onChange={(event) => setSelectedState(event.target.value)}>
                            <option value={""}>Todos los estados</option>
                            {quotationStates.length > 0 && quotationStates.map((state) => {
                                return <option value={state._id} key={state._id}>{state.name}s</option>
                            })}
                        </NativeSelect>}
                    </div>

                    <section>
                        <div>
                            <div>
                                <span style={{ backgroundColor: "var(--color-gray-blue-ligther)" }}> </span>
                                <h6>Activa</h6>
                            </div>
                            <div>
                                <span style={{ backgroundColor: "var(--color-gray-blue)" }}> </span>
                                <h6>Enviada</h6>
                            </div>
                            <div>
                                <span style={{ backgroundColor: "var(--color-green)" }}> </span>
                                <h6>Aceptada</h6>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span style={{ backgroundColor: "var(--color-red)" }}> </span>
                                <h6>Rechazada</h6>
                            </div>
                            <div>
                                <span style={{ backgroundColor: "var(--color-gray-dark)" }}> </span>
                                <h6>Cancelada</h6>
                            </div>
                        </div>
                    </section>
                </section>
                <section className="quotationRecord-cards-container">
                    {quotationRecord.data?.length > 0 && quotationRecord.data.map((record) => {
                        return <div className="quotationRecord-card" key={record._id}>
                            <div style={{ padding: "7.5px 10px" }}>
                                <h5>{record.creationDate && record.creationDate.split('T')[0].split('-').reverse().join('-')}</h5>
                                <h4>{record.code}</h4>
                            </div>
                            <div className="quotationRecord-card-rigth">
                                {(user._id === record.collaboratorID && (record.state.name === "Activa" || record.state.name === "Enviada")) ? <Link
                                    to={"/Cotizaciones"}
                                    key={record._id} onClick={() => handleOpenQuotationClick(record.clientID, record._id)}
                                >
                                    <img src={quotation_icon} alt="quotation-icon" className="global-small-img-button"/>
                                </Link> :
                                    <PDFDownloadLink
                                        document={
                                            <QuotationDownload
                                                company={userCompany}
                                                data={record}
                                                currencyData={record.conversion}
                                            />
                                        }
                                        fileName={`SCOPE-${record.code}.pdf`}
                                    >
                                        <img src={downloadIcon} className="global-small-img-button" alt="quotation-icon" />
                                    </PDFDownloadLink>
                                }
                                <span style={record.state.name === "Activa" ? { backgroundColor: "var(--color-gray-blue-ligther)" } :
                                    record.state.name === "Enviada" ? { backgroundColor: "var(--color-gray-blue)" } :
                                        record.state.name === "Aceptada" ? { backgroundColor: "var(--color-green)" } :
                                            record.state.name === "Rechazada" ? { backgroundColor: "var(--color-red)" } :
                                                { backgroundColor: "var(--color-gray-dark)" }}> </span>
                            </div>
                        </div>
                    })}
                </section>
                <section className="quotationRecord-cards-pagination">
                    {quotationRecord.data?.length > 0 && (quotationRecord.data.length % quotationsPerPage === 0 && quotationRecord.data.length + quotationsPerPage + 1 > quotationQuantity) && <div onClick={() => setQuotationQuantity(quotationQuantity + quotationsPerPage)}>
                        Ver más <img src={expandArrow} alt="expand-arrow" style={{ rotate: '90deg' }} />
                    </div>}
                </section>
                {quotationRecord.quantity &&
                    <section className="quotationRecord-graphs">
                        <StatsSegments data={quotationRecord.quantity} length={quotationRecord.quantity?.reduce((sum, item) => sum + item.quantity, 0)} />
                    </section>
                }
            </SectionAccordion>

        </div>
    )
}