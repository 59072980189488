import {
	CLEAR_ENTITY_LABELS_SUCCESS,
	GET_ENTITY_LABELS_SUCCESS,
	GET_LABELING_ERROR,
	GET_LABELING_SUCCESS,
	SET_LABELING_MESSAGE,
	START_GET_LABELING,
} from "../../types/index";

import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";

const cookie = new universlaCookie();

export function clearEntityLabelsList() {
	return async (dispatch) => {
		dispatch(downloadLabelings());
		try {
			dispatch(clearEntityLabels());
		} catch (e) {
			dispatch(getLabelingsError(e.response.data.message));
		}
	};
}

export function deleteEntityLabel(entityID, labelID, type) {
	return async (dispatch) => {
		try {
			const response = await clienteAxios({
				method: "DELETE",
				url: `entityLabeling/?entityID=${entityID}&labelID=${labelID}&type=${type}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(setLabelingsMessage(response.data.message))
		} catch (e) {
			dispatch(getLabelingsError(e.response.data.message));
		}
	};
}

export function deleteLabeling(id) {
	return async (dispatch) => {
		try {
			const response = await clienteAxios({
				method: "DELETE",
				url: `labeling/?id=${id}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(setLabelingsMessage(response.data.message))
		} catch (e) {
			dispatch(getLabelingsError(e.response.data.message));
		}
	};
}

export function getEntityLabels(entityID, type) {
	return async (dispatch) => {
		dispatch(downloadLabelings());
		try {
			const response = await clienteAxios({
				method: "GET",
				url: `entityLabeling/?entityID=${entityID}&type=${type}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(getEntityLabelsSuccess(response.data.response));
		} catch (e) {
			dispatch(getLabelingsError(e.response.data.message));
		}
	};
}

export function getLabelings() {
	return async (dispatch) => {
		dispatch(downloadLabelings());
		try {
			const response = await clienteAxios({
				method: "GET",
				url: `labeling`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(getLabelingsSuccess(response.data.response));
		} catch (e) {
			dispatch(getLabelingsError(e.response.data.message));
		}
	};
}

export function updateLabeling(name, id) {
	return async (dispatch) => {
		try {
			const response = await clienteAxios({
				method: "PUT",
				url: `labeling/?id=${id}&name=${name}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(setLabelingsMessage(response.data.message))
		} catch (e) {
			dispatch(getLabelingsError(e.response.data.message));
		}
	};
}

export function postEntityLabel(entityID, labelID, type) {
	return async (dispatch) => {
		try {
			const response = await clienteAxios({
				method: "POST",
				url: `entityLabeling/?entityID=${entityID}&labelID=${labelID}&type=${type}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(setLabelingsMessage(response.data.message));
		} catch (e) {
			dispatch(getLabelingsError(e.response.data.message));
		}
	};
}

export function postInsertEntityLabel(name, entityID, type) {
	return async (dispatch) => {
		try {
			const response = await clienteAxios({
				method: "POST",
				url: `labeling/?name=${name}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});

			const response2 = await clienteAxios({
				method: "POST",
				url: `entityLabeling/?entityID=${entityID}&labelID=${response.data.data}&type=${type}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});

			dispatch(setLabelingsMessage(response2.data.message));
		} catch (e) {
			dispatch(getLabelingsError(e.response.data.message));
		}
	};
}

export function postLabeling(name) {
	return async (dispatch) => {
		try {
			const response = await clienteAxios({
				method: "POST",
				url: `labeling/?name=${name}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(setLabelingsMessage(response.data.message));
		} catch (e) {
			dispatch(getLabelingsError(e.response.data.message));
		}
	};
}

const setLabelingsMessage = (message) => ({
	type: SET_LABELING_MESSAGE,
	payload: message
})

const clearEntityLabels = () => ({
	type: CLEAR_ENTITY_LABELS_SUCCESS,
});

const downloadLabelings = () => ({
	type: START_GET_LABELING,
});

const getLabelingsSuccess = (labelingList) => ({
	type: GET_LABELING_SUCCESS,
	payload: labelingList,
});

const getEntityLabelsSuccess = (entityLabels) => ({
	type: GET_ENTITY_LABELS_SUCCESS,
	payload: entityLabels,
});

const getLabelingsError = (message) => ({
	type: GET_LABELING_ERROR,
	payload: message
});
