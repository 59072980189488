import { EXCEL_LOAD_START, EXCEL_LOAD_ERROR, GET_EXCELHISTORY_SUCCESS } from "../../types/index";

import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";

const cookie = new universlaCookie();

export function excelClientsLoad(items, parser) {
	return async (dispatch) => {
		dispatch(startExcelLoading());
		try {
			const promises = [];
			for (let i = 0; i < Math.ceil(items.length / 50); i++) {
				let data = {
					items: items.slice(i * 50, (i + 1) * 50),
					parser
				};
				const promise = await clienteAxios({
					method: "POST",
					url: `excelClientsImport`,
					headers: {
						"x-acces-token": cookie.get("token"),
					},
					data: data
				});
				promises.push(promise);
			}
			await Promise.all(promises);

			dispatch(getExcelHistorySuccess([]));
		} catch (e) {
			dispatch(excelError());
		}
	};
}

export function excelLoad(indexID, productType, items, parser, expirable, fileName) {
	return async (dispatch) => {
		dispatch(startExcelLoading());
		try {
			const promises = [];
			for (let i = 0; i < Math.ceil(items.length / 1000); i++) {
				let data = {
					indexID,
					productType,
					items: items.slice(i * 1000, (i + 1) * 1000),
					parser,
					expirable,
					fileName,
				};
				const promise = await clienteAxios({
					method: "POST",
					url: `excelLoad`,
					headers: {
						"x-acces-token": cookie.get("token"),
					},
					data: data
				});
				promises.push(promise);
			}
			await Promise.all(promises);

			let data = {
				indexID,
				productType,
				parser,
				expirable,
				fileName,
			};

			await clienteAxios({
				method: "POST",
				url: `excelHistory`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: data
			});

			dispatch(getExcelHistory());
			return true
		} catch (e) {
			dispatch(excelError());
			return true
		}
	};
}

export function getExcelHistory() {
	return async (dispatch) => {
		try {
			const response = await clienteAxios({
				method: "GET",
				url: `excelHistory`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(getExcelHistorySuccess(response.data.response));
		} catch (e) {
			dispatch(excelError());
		}
	};
}

export function deleteExcelHistory(excel) {
	return async (dispatch) => {
		dispatch(startExcelLoading());
		try {
			await clienteAxios({
				method: "DELETE",
				url: `excelHistory/?indexID=${excel.product.index}&empresa=${excel.company}&id=${excel._id}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(getExcelHistory())
		} catch (e) {
			dispatch(excelError());
		}
	};
}

const getExcelHistorySuccess = (excelHistory) => ({
	type: GET_EXCELHISTORY_SUCCESS,
	payload: excelHistory,
});

const startExcelLoading = () => ({
	type: EXCEL_LOAD_START,
});

const excelError = () => ({
	type: EXCEL_LOAD_ERROR,
});
