import {
    GET_PRODUCT_TYPES_SUCCESS,
    ACTION_PRODUCT_TYPES_START,
    ACTION_PRODUCT_TYPES_ERROR,
} from "../../types/index";

import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";

const cookie = new universlaCookie();

export function getProductTypes() {
    return async (dispatch) => {
        dispatch(downloadProductTypes());
        try {
			const response = await clienteAxios({
				method: "GET",
				url: `productTypes`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
            dispatch(getProductTypesSuccess(response.data.response));
        } catch (e) {
            dispatch(getProductTypesError());
        }
    };
}

const downloadProductTypes = () => ({
    type: ACTION_PRODUCT_TYPES_START,
});

const getProductTypesSuccess = (productTypes) => ({
    type: GET_PRODUCT_TYPES_SUCCESS,
    payload: productTypes,
});

const getProductTypesError = () => ({
    type: ACTION_PRODUCT_TYPES_ERROR,
});