import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Input, NativeSelect } from '@mantine/core';
import { v4 as uuidv4 } from "uuid";

import { addCollaboratorDirection, deleteCollaborator, deleteCollaboratorDirection, getCollaboratorByID, updateCollaborator, updateCollaboratorDirection } from '../../../redux/actions/entities/collaboratorAction'

import ConfirmationModal from '../../utilities/Modal/ConfirmationModal';
import StatusModal from '../../utilities/Modal/StatusModal';
import ModalDirection from '../modals/ModalDirection';

import iconAddNew from "../../../assets/svg/functionalities-icons/plus_blue.svg";
import iconTrashCan from "../../../assets/svg/functionalities-icons/trash-can.svg";
import iconEdit from "../../../assets/svg/functionalities-icons/lapiz-editar.svg";
import { QuotationRecord } from '../../quotation/quotationRecord/QuotationRecord';
import reverseIcon from "../../../assets/svg/functionalities-icons/arrow-open_white.svg"
import { getRoles } from '../../../redux/actions/utilities/roleAction';
import { hasDeletePermission, hasEditPermission, hasPermissionByPermissionName } from '../../configuration/permissions/utilities/hasPermissions';
import SectionAccordion from '../../utilities/components/SectionAccordion';
import EntityLabels from '../entityLabels/EntityLabels';
import { clearEntityLabelsList } from '../../../redux/actions/utilities/labelingAction';

export const Collaborator = () => {
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const navegation = useNavigate();

    const collaborator = useSelector((state) => state.collaborators.selectedCollaborator)
    const collaboratorStatus = useSelector((state) => state.collaborators)
    const roles = useSelector((state) => state.role.roles)
    const user = useSelector((state) => state.auth.user)

    const [collaboratorID] = useState(params.get("id") || "");

    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [showStatusModal, setShowStatusModal] = useState(false)
    const [showDirectionModal, setShowDirectionModal] = useState(false)

    const [editMode, setEditMode] = useState(false)
    const [collaboratorInfoCopy, setClientInfoCopy] = useState(collaborator.info ? { info: collaborator.info, contact: collaborator.contact, roleID: collaborator.role._id } : {})

    useEffect(() => {
        dispatch(getCollaboratorByID(collaboratorID));
        dispatch(getRoles());
        dispatch(clearEntityLabelsList())
    }, [])

    useEffect(() => {
        if (!showStatusModal) {
            if (showConfirmationModal && showConfirmationModal.title === "Eliminar colaborador" && !editMode) {
                navegation(`/Colaboradores`);
                setShowConfirmationModal(false)
            } else {
                dispatch(getCollaboratorByID(collaboratorID));
                setShowConfirmationModal(false)
            }
        }
    }, [showStatusModal])

    const handleDirectionAction = (country, province, locality, neighborhood, street, number, floor, postalCode) => {
        if (showDirectionModal.data) dispatch(updateCollaboratorDirection(country, province, locality, neighborhood, street, number, floor, postalCode, showDirectionModal.data.index, collaboratorID))
        else dispatch(addCollaboratorDirection(country, province, locality, neighborhood, street, number, floor, postalCode, collaboratorID))

        setShowDirectionModal(false)
        setShowStatusModal(true)
        setEditMode(false)
    }

    const handlerShowConfirmationModal = () => {
        if (showConfirmationModal.title === "Eliminar colaborador") {
            dispatch(deleteCollaborator(collaboratorID))
        } else {
            dispatch(deleteCollaboratorDirection(collaboratorID))
        }
        setShowStatusModal(true)
    }

    const handleUpdateClient = () => {
        dispatch(updateCollaborator(collaboratorInfoCopy, collaboratorID))
        setEditMode(false)
        setShowStatusModal(true)
    }

    return (
        <div className="cuentas-contenedor">
            {collaborator.info &&
                <section className='entity-backToSection-container'>
                    <Link to={"/Colaboradores"} key={uuidv4()}>
                        <img alt="reverse" src={reverseIcon} />
                    </Link>
                    <h3>| Colaborador:</h3>
                    <h4>{collaborator.info.lastName} {collaborator.info.name}</h4>
                </section>
            }
            {collaborator && collaborator.info && 
                <SectionAccordion title="Datos del colaborador" openClass="entity-info-container">
                    <div className='entity-entire-container'>
                        <div className='entity-contact-container'>
                            <div>
                                <h3>Nombre</h3>
                                {!editMode ? <h4>{collaborator.info.name}</h4> : <Input size="xxs" id="nameInputForm" value={collaboratorInfoCopy.info.name} onChange={(e) => setClientInfoCopy({
                                    ...collaboratorInfoCopy,
                                    info: {
                                        ...collaboratorInfoCopy.info,
                                        name: e.target.value
                                    },
                                })} />}
                            </div>
                            <div>
                                <h3>Apellido</h3>
                                {!editMode ? <h4>{collaborator.info.lastName}</h4> : <Input size="xxs" id="lastNameInputForm" value={collaboratorInfoCopy.info.lastName} onChange={(e) => setClientInfoCopy({
                                    ...collaboratorInfoCopy,
                                    info: {
                                        ...collaboratorInfoCopy.info,
                                        lastName: e.target.value
                                    },
                                })} />}
                            </div>
                            <div>
                                <h3>CUIL</h3>
                                {!editMode ? <h4>{collaborator.info.CUIL}</h4> : <Input size="xxs" id="CUILInputForm" value={collaboratorInfoCopy.info.CUIL} onChange={(e) => setClientInfoCopy({
                                    ...collaboratorInfoCopy,
                                    info: {
                                        ...collaboratorInfoCopy.info,
                                        CUIL: e.target.value
                                    },
                                })} />}
                            </div>
                            <div>
                                <h3>Tipo documento</h3>
                                {!editMode ? <h4>{collaborator.info.docType}</h4> :
                                    <NativeSelect size="xxs" id="docTypeInputForm" value={collaboratorInfoCopy.info.docType} onChange={(e) => setClientInfoCopy({
                                        ...collaboratorInfoCopy,
                                        info: {
                                            ...collaboratorInfoCopy.info,
                                            docType: e.target.value
                                        },
                                    })}>
                                        <option hidden>Tipo documento</option>
                                        <option value="DNI">DNI</option>
                                    </NativeSelect>
                                }
                            </div>
                            <div>
                                <h3>Número documento</h3>
                                {!editMode ? <h4>{collaborator.info.docNum}</h4> : <Input size="xxs" id="docNumInputForm" value={collaboratorInfoCopy.info.docNum} onChange={(e) => setClientInfoCopy({
                                    ...collaboratorInfoCopy,
                                    info: {
                                        ...collaboratorInfoCopy.info,
                                        docNum: e.target.value
                                    },
                                })} />}
                            </div>
                            <h3>Domicilio</h3>
                            {collaborator.directions.country || editMode ? <section>
                                {collaborator.directions.country ?
                                    <div key={collaborator.directions.street + collaborator.directions.number}>
                                        <section >
                                            <h5>{collaborator.directions.street} {collaborator.directions.number} {collaborator.directions.floor} - {collaborator.directions.neighborhood} ({collaborator.directions.postalCode})</h5>
                                            <h6>{collaborator.directions.locality} - {collaborator.directions.province} - {collaborator.directions.country}</h6>
                                        </section>
                                        {editMode &&
                                            <section>
                                                <img
                                                    alt="edit-button"
                                                    src={iconEdit}
                                                    className="cuentas-comercial-button_undo"
                                                    onClick={() => setShowDirectionModal({ action: "Añadir", data: collaborator.directions, type: "direction" })}
                                                />
                                                <img
                                                    alt="confirm-button"
                                                    src={iconTrashCan}
                                                    className="cuentas-comercial-button_undo"
                                                    onClick={() => setShowConfirmationModal({
                                                        description: `¿Está seguro que desea eliminar la dirección "${collaborator.directions.street + " " + collaborator.directions.number + " - " + collaborator.directions.neighborhood}"?`,
                                                        title: "Eliminar dirección"
                                                    })}
                                                />
                                            </section>}
                                    </div>
                                    :
                                    <div onClick={() => setShowDirectionModal({ action: "Añadir", type: "direction" })}>
                                        <div>
                                            <h6>Añadir</h6>
                                            <img alt='add' src={iconAddNew} />
                                        </div>
                                    </div>}
                            </section> :
                                <h5 style={{ margin: "15px 0 20px 20px" }}>Sin registro</h5>
                            }

                            <div>
                                <h3>Teléfono</h3>
                                {!editMode ? <h4>{collaborator.contact.phone}</h4> : <Input size="xxs" id="phoneInputForm" value={collaboratorInfoCopy.contact.phone} onChange={(e) => setClientInfoCopy({
                                    ...collaboratorInfoCopy,
                                    contact: {
                                        ...collaboratorInfoCopy.contact,
                                        phone: e.target.value
                                    },
                                })} />}
                            </div>
                            <div>
                                <h3>Rol</h3>
                                {!editMode ? <h4>{collaborator.role.name}</h4> :
                                    <NativeSelect size="xxs" id="roleInputForm" value={collaboratorInfoCopy.roleID}
                                        onChange={(e) => setClientInfoCopy({
                                            ...collaboratorInfoCopy,
                                            roleID: e.target.value
                                        })}
                                    >
                                        <option hidden>Seleccionar rol</option>
                                        {
                                            roles.map(role => {
                                                return <option value={role._id}>{role.name}</option>
                                            })
                                        }
                                    </NativeSelect>
                                }
                            </div>
                            <div style={{ marginTop: "10px !important" }}>
                                <h3>Email</h3>
                                <h4>{collaborator.contact.email}</h4>
                            </div>

                            {!editMode ?
                                <div>
                                    {hasEditPermission("collaborators", user) &&
                                        <button className="entity-button-blue" onClick={() => { setEditMode(true); setClientInfoCopy({ info: collaborator.info, contact: collaborator.contact, roleID: collaborator.roleID }) }}>Editar</button>
                                    }
                                    {hasDeletePermission("collaborators", user) &&
                                        <button className="entity-button-red" onClick={() => setShowConfirmationModal({
                                            title: "Eliminar colaborador",
                                            description: `¿Está seguro que desea eliminar el colaborador "${collaborator.info.lastName + " " + collaborator.info.name}"?`
                                        })}>Eliminar</button>
                                    }
                                </div> :
                                <div>
                                    <button className="entity-button-blueWhite" onClick={() => setEditMode(false)}>Cancelar</button>
                                    <button className="entity-button-blue"
                                        disabled={!collaboratorInfoCopy.info.name || !collaboratorInfoCopy.info.lastName || !collaboratorInfoCopy.info.CUIL || !collaboratorInfoCopy.info.docType || !collaboratorInfoCopy.roleID}
                                        onClick={handleUpdateClient}>
                                        Confirmar</button>
                                </div>
                                }
                            </div>
                            <EntityLabels entityID={collaboratorID} type="collaborator"/>
                        </div>
                </SectionAccordion>
            }
            {showConfirmationModal &&
                <ConfirmationModal
                    descriptionText={showConfirmationModal.description}
                    handlerShowConfirmationModal={handlerShowConfirmationModal}
                    setShowConfirmationModal={setShowConfirmationModal}
                    titleText={showConfirmationModal.title}
                />}
            {showStatusModal &&
                <StatusModal error={collaboratorStatus.error} loading={collaboratorStatus.loading} setModalStatusCloseAction={setShowStatusModal}>
                    {collaboratorStatus.message}
                </StatusModal>}
            {showDirectionModal && <ModalDirection handleDirectionAction={handleDirectionAction} showDirectionModal={showDirectionModal} setShowDirectionModal={setShowDirectionModal} />}
            {hasPermissionByPermissionName('quotations', 'viewByCollab', user) &&
                <QuotationRecord collaboratorID={collaboratorID} isHome={false} />
            }
        </div>
    )
}
