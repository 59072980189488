import {
	GET_ROLES_ERROR,
	GET_ROLES_SUCCESS,
	SET_ROLES_MESSAGE,
	START_GET_ROLES,
} from "../../types/index";

import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";

const cookie = new universlaCookie();

export function deleteRole(id) {
	return async (dispatch) => {
		try {
			const response = await clienteAxios({
				method: "DELETE",
				url: `role/?id=${id}`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(setRolesMessage({ error: false, message: response.data.message }))
		} catch (e) {
			dispatch(setRolesMessage({ error: true, message: e.response.data.message }))
		}
	};
}

export function getRoles() {
	return async (dispatch) => {
		dispatch(downloadRoles());
		try {
			const response = await clienteAxios({
				method: "GET",
				url: `role`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(getRolesSuccess(response.data.response));
		} catch (e) {
			dispatch(getRolesError());
		}
	};
}

export function updateRole(name, permissions, id) {
	return async (dispatch) => {
		try {
			const response = await clienteAxios({
				method: "PUT",
				url: `role`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: {
					id: id,
					name: name,
					permissions: permissions
				}
			});
			dispatch(setRolesMessage({ error: false, message: response.data.message }))
		} catch (e) {
			dispatch(getRolesError());
		}
	};
}

export function postRole(name, permissions) {
	return async (dispatch) => {
		try {
			const response = await clienteAxios({
				method: "POST",
				url: `role`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
				data: {
					name: name,
					permissions: permissions
				}
			});
			dispatch(setRolesMessage({ error: false, message: response.data.message }));
		} catch (e) {
			dispatch(getRolesError());
		}
	};
}

const setRolesMessage = (message) => ({
	type: SET_ROLES_MESSAGE,
	payload: message
})

const downloadRoles = () => ({
	type: START_GET_ROLES,
});

const getRolesSuccess = (roles) => ({
	type: GET_ROLES_SUCCESS,
	payload: roles,
});

const getRolesError = () => ({
	type: GET_ROLES_ERROR,
});
