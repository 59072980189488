import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";

import ModalAddClient from './ModalAddClient';
import EntityCard from '../EntityCard';

import { getClientsPaginated } from '../../../redux/actions/entities/clientAction';

import "../entities.css"
import { TextSearcher } from '../../utilities/components/TextSearcher';
import { useSearchParams } from 'react-router-dom';
import { Pagination } from '@mantine/core';
import { hasCreatePermission } from '../../configuration/permissions/utilities/hasPermissions';
import SectionAccordion from '../../utilities/components/SectionAccordion';
import ModalImportClients from './ModalImportClients';
import StatusModal from '../../utilities/Modal/StatusModal';

const Clients = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    const clientsReducer = useSelector((state) => state.clients);
    const user = useSelector((state) => state.auth.user);

    const [activePage, setPage] = useState(searchParams.get('page') !== null ? parseInt(searchParams.get('page')) : 1);
    const [searcherInput, setSearcherInput] = useState("");

    const [showAddClientModal, setShowAddClientModal] = useState(false)
    const [showImportClientsModal, setShowImportClientsModal] = useState(false)
    const [showStatusModal, setShowStatusModal] = useState(false);

    document.title = "Scope | Clientes"

    useEffect(() => {
        if (!showAddClientModal) {
            dispatch(getClientsPaginated(activePage, searcherInput));
        }
    }, [showAddClientModal, dispatch])

    useEffect(() => {
        if (searcherInput) {
            dispatch(getClientsPaginated(1, searcherInput));
        }
    }, [searcherInput])

    const handlePathPage = (event) => {
        setPage(event)

        dispatch(getClientsPaginated(event, searcherInput))
        searchParams.set("page", event);
        setSearchParams(searchParams);
    }

    useEffect(() => {
        setPage(!searchParams.get('page') ? 1 : parseInt(searchParams.get('page')))
    }, [searchParams]);

    return (
        <div className="cuentas-contenedor">
            <SectionAccordion title="Clientes">
                <div className="entity-filters-container">
                    <TextSearcher placeholder="Nombre, razón social o CUIT" setSearchFilter={setSearcherInput} />
                    <section style={{ width: "100%" }}>
                        {hasCreatePermission("clients", user) &&
                            <button className="entity-addNew-botton" onClick={() => setShowAddClientModal(!showAddClientModal)}>
                                Nuevo cliente
                            </button>
                        }

                        {hasCreatePermission("clients", user) && 
                            <button className="entity-addNew-botton-white" onClick={() => setShowImportClientsModal(!showImportClientsModal)}>
                                Importar clientes
                            </button>
                        }
                    </section>
                </div>
                {clientsReducer.count > 0 && !clientsReducer.loading &&
                    <span className="products-totalQuantity">Total de clientes: <b>{clientsReducer.count}</b></span>
                }
                <section className="entity-cards-container">
                    {clientsReducer.clients &&
                        clientsReducer.clients.length > 0 &&
                        clientsReducer.clients.map((client) => {
                            return (
                                <EntityCard
                                    key={client._id}
                                    mainText={client.info.name}
                                    secondaryText={client.info.fantasyName}
                                    entityID={client._id}
                                    type="client" />
                            );
                        })}
                </section>
                <div className="pagination-container">
                    <Pagination
                        color="var(--color-gray-blue-dark)"
                        onChange={(event) => handlePathPage(event)}
                        total={Math.ceil(clientsReducer.count / 20)}
                        value={parseInt(clientsReducer.page)}
                    />
                </div>
            </SectionAccordion>
            {showAddClientModal && <ModalAddClient setShowAddClientModal={setShowAddClientModal} />}

            {showImportClientsModal && <ModalImportClients setShowImportClientsModal={setShowImportClientsModal} setShowStatusModal={setShowStatusModal}/>}

            {showStatusModal && (
                <StatusModal loading={clientsReducer.loading} error={clientsReducer.error} setModalStatusCloseAction={setShowStatusModal}>
                    {clientsReducer.message}
                </StatusModal>
            )}
        </div >
    )
}

export default Clients