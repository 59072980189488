import {
	GET_ACTIVITY_LOGS_ERROR,
	GET_ACTIVITY_LOGS_SUCCESS,
	START_GET_ACTIVITY_LOGS,
} from "../../types/index";

import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";

const cookie = new universlaCookie();

export function getActivityLogs() {
	return async (dispatch) => {
		dispatch(downloadActivityLogs());
		try {
			const response = await clienteAxios({
				method: "GET",
				url: `activityLogs`,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(getActivityLogsSuccess(response.data.response[0]));
		} catch (e) {
			dispatch(getActivityLogsError());
		}
	};
}

export function getFilteredActivityLogs(roleSelected, sectionSelected, collaboratorSelected, initialDate, finalDate, page) {
	return async (dispatch) => {
		const filters = `role=${roleSelected}&section=${sectionSelected}&collaborator=${collaboratorSelected}&initialDate=${initialDate?.toISOString()}&finalDate=${finalDate?.toISOString()}&page=${page}`
		dispatch(downloadActivityLogs());
		try {
			const response = await clienteAxios({
				method: "GET",
				url: `activityLogs?${filters}` ,
				headers: {
					"x-acces-token": cookie.get("token"),
				},
			});
			dispatch(getActivityLogsSuccess(response.data.response[0]));
		} catch (e) {
			dispatch(getActivityLogsError());
		}
	};
}

const downloadActivityLogs = () => ({
	type: START_GET_ACTIVITY_LOGS,
});

const getActivityLogsSuccess = (payload) => ({
	type: GET_ACTIVITY_LOGS_SUCCESS,
	payload: payload,
});

const getActivityLogsError = () => ({
	type: GET_ACTIVITY_LOGS_ERROR,
});
