import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import noImage from "../../../../assets/svg/system-images/producto-sin-imagen.svg";
import labelTagIcon from "../../../../assets/svg/information-icons/labelTag02.svg";

import "./carProductCard.css";
import { hasViewPermission } from "../../../configuration/permissions/utilities/hasPermissions";

import { Tooltip } from '@mantine/core';


export default function CarProductCard({ item, selectedPricelist }) {

	const currency = useSelector((state) => state.carProducts.currency);
	const user = useSelector((state) => state.auth.user);

	const [producto, setProducto] = useState(item);

	useEffect(() => {
		let precioProducto = parseFloat(producto.normalizedPrice);

		const productoDesactualizado = Date.parse(new Date()) - Date.parse(producto.timestamp) - 24 * 60 * 60 * 1000 > 0;

		setProducto({ ...producto, productoDesactualizado, precioProducto });
	}, [item]);

	const formatearNumero = (valor) => {
		return new Intl.NumberFormat("de-DE", {
			minimumFractionDigits: 2,  // Mínimo de 2 decimales
			maximumFractionDigits: 2   // Máximo de 2 decimales
		}).format((valor * 1));
	};

	const formatNumber = (valor) => {
		return new Intl.NumberFormat("de-DE").format((valor * 1));
	};

	return (
		<div>
			<section className="carProductCard-timestamp" style={producto.productoDesactualizado > 0 ? { border: "2px solid var(--color-red)", borderBottom: "0px" } : {}}>
				{producto.productoDesactualizado > 0 ?
					<span style={{ color: "var(--color-red)" }}>Producto desactualizado. Ultima actualización {producto.timestamp.split("T")[0]}</span>
					: <span>Extracción de articulo: {producto.timestamp.split("T")[1].split(':').slice(0, 2).join(':')}hs</span>
				}
			</section>
			<Link
				to={`/CarProducto?id=${item._id}`}
				className="carProductCard-container"
				key={item._id}
				style={producto.productoDesactualizado > 0 ? { border: "2px solid var(--color-red)" } : {}}
			>

				<div>
					<div>
						<img
							alt="img. prod."
							src={
								Array.isArray(producto.info.images) && producto.info.images[0]
									? producto.info.images[0]
									: noImage
							}
						/>
						<img
							alt="img. prod."
							src={
								Array.isArray(producto.info.images) && producto.info.images[0]
									? producto.info.images[0]
									: noImage
							}
						/>
					</div>
					<section>
						<div>
							<h3>{producto.info.brand} - {producto.info.model}</h3>
							<h4>{producto.info.version}</h4>
							<h5>{producto.company.name}</h5>
						</div>
					</section>
					<div>
						{producto.normalizedPrice * 1 === 0 ?
							<span>
								Consultar precio
							</span>
							:
							<span>
								{currency}{" "}
								{formatearNumero(producto.normalizedPrice * 1)}
							</span>
						}
					</div>
					<section>
						<h5>{producto.info.year}</h5>
						<h5 className={item.marketPlayerData.labels && item.marketPlayerData.labels.length > 0 ? "hasLabel" : ""}>{formatNumber(producto.info.kms)} kms</h5>
						{/* <h5>--- %</h5>	ToChange */}
						{item.marketPlayerData.labels && item.marketPlayerData.labels.length > 0 && 
							<Tooltip label={
								<ul style={{ margin: 0, padding: 0, listStyle: "none", textTransform: "capitalize" }}>
									{item.marketPlayerData.labels.map(label => (
										<li key={label.id}>• {label.name}</li>
									))}
								</ul>}>
							<img src={labelTagIcon} alt="labelIcon" />
						</Tooltip>
						}
					</section>
				</div>
				{hasViewPermission('priceLists', user) && (producto.normalizedPrice * 1) !== 0 && selectedPricelist.percentage !== 0 && (
					<section className="carProductCard-price-suggested">
						<h6>Precio de compra sugerido: </h6>
						<span>
							{currency}{" "}
							{formatearNumero(producto.normalizedPrice * 1 *  ( 100 + selectedPricelist.percentage) / 100)}
						</span>
					</section>
				)}
			</Link>
		</div>
	);
}
