export function hasViewPermission(resource, user) {
    const permissions = user.role?.permissions;
    
    return permissions && permissions[resource] ?
        (permissions[resource].actions.view.value) :
        false;
}

export function hasCreatePermission(resource, user) {
    const permissions = user.role?.permissions;
    
    return permissions && permissions[resource] ?
        (permissions[resource].actions.create.value) :
        false;
}

export function hasEditPermission(resource, user) {
    const permissions = user.role?.permissions;
    
    return permissions && permissions[resource] ?
        (permissions[resource].actions.update.value) :
        false;
}

export function hasDeletePermission(resource, user) {
    const permissions = user.role?.permissions;
    
    return permissions && permissions[resource] ?
        (permissions[resource].actions.delete.value) :
        false;
}

export function hasAdministrationPermission(resource, user) {
    const permissions = user.role?.permissions;
    
    return permissions && permissions[resource] ?
        (permissions[resource].actions.administrate.value) :
        false;
}

export function hasPermissionByPermissionName(resource, permissionName, user) {
    const permissions = user.role?.permissions;
    
    return permissions && permissions[resource] ?
        (permissions[resource].actions[permissionName].value) :
        false;
}