import { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import universlaCookie from "universal-cookie";

import { Modal, Box } from "@mui/material";

import { logOutUser } from "../../../redux/actions/utilities/authAction";

import accountsIcon from '../../../assets/svg/menu-icons/cuenta.svg'
import homeIcon from '../../../assets/svg/menu-icons/inicio-icono.svg'
import excelLoadIcon from '../../../assets/svg/menu-icons/excel-load.svg'
import quotationIcon from '../../../assets/svg/menu-icons/quotation-icon.svg'
import closeSessionIcon from "../../../assets/svg/menu-icons/cerrar-sesion.svg";
import closeMenuIcon from "../../../assets/svg/menu-icons/flecha-triangular-white.svg";
import configurationIcon from "../../../assets/svg/menu-icons/configuracion.svg";

import carsMarketValuesIcon from "../../../assets/svg/menu-icons/carsMarketValues.svg";

import expandArrow from "../../../assets/svg/menu-icons/flecha-triangular-white.svg";

import './navbarMenu.css';
import { hasViewPermission } from '../../configuration/permissions/utilities/hasPermissions';

const cookie = new universlaCookie();

export function NavbarMenu({ handleCloseNavbarMenu }) {
    const navegation = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user);

    const [active, setActive] = useState(location.pathname);

    const [openOption, setOpenOption] = useState(null);

    const options = [
        { link: '/Dashboard', label: 'Inicio', icon: homeIcon, canView: true },
        { link: '/CargaPlanillas', label: 'Carga de planillas', icon: excelLoadIcon, canView: hasViewPermission("spreadsheets", user) },
        { link: '/ValoresDeMercado', label: 'Valores de mercado', icon: carsMarketValuesIcon, canView: hasViewPermission("carsMarketValues", user) },
        { link: '/Clientes', label: 'Clientes', icon: accountsIcon, canView: hasViewPermission("clients", user) },
        { link: '/Colaboradores', label: 'Colaboradores', icon: accountsIcon, canView: hasViewPermission("collaborators", user) },
        { link: '/Cotizaciones', label: 'Lista de cotización', icon: quotationIcon, canView: hasViewPermission("quotations", user) },
        {
            links: [
                { link: '/ActoresDeMercado', label: 'Actores de mercado', canView: hasViewPermission("marketPlayers", user) },
                { link: '/Categorizacion', label: 'Categorización', canView: hasViewPermission("categorization", user) },
                { link: '/Comercializacion', label: 'Comercialización', canView: hasViewPermission("commercialConditions", user) || 
                    hasViewPermission("priceLists", user) },
                { link: '/Permisos', label: 'Roles y permisos', canView: hasViewPermission("permissions", user) },
                { link: '/Actividad', label: 'Registro de actividad', canView: hasViewPermission("activityLogs", user) }
            ], label: 'Configuración', icon: configurationIcon
        }
    ]

    const handleLogOut = (event) => {
        event.preventDefault();
        cookie.remove("token");
        localStorage.removeItem("user");
        localStorage.setItem("LogInStatus", false);
        dispatch(logOutUser());
        navegation("/");
    }

    const handleClickAwayClose = (event, reason) => {
        if (event.clientX < 300) {
            return;
        }
        handleCloseNavbarMenu()
    };

    const handleClickWithOption = (value) => {
        if (!openOption || openOption !== value) setOpenOption(value)
        else setOpenOption(null)
    }

    return (
        <Box >
            <Modal open={true}
                style={{ backdropFilter: "blur(4px)" }}
                onClick={handleClickAwayClose}
            >
                <nav className="navbarMenu-container">
                    <div className="navbarMenu-navbarMain">

                        <div className="navbarMenu-header" >
                            <img alt='close-menu-icon' src={closeMenuIcon} onClick={handleCloseNavbarMenu} />
                            <h6 onClick={handleCloseNavbarMenu}>Cerrar menú</h6>
                        </div>

                        {options && options.map((item) => {
                            if (item.link && item.canView) {
                                return <a
                                    href='/'
                                    className="navbarMenu-link"
                                    data-active={item.link === active || undefined}
                                    to={item.link}
                                    key={item.label}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setActive(item.label);
                                        navegation(item.link)
                                        handleCloseNavbarMenu()
                                    }}
                                >
                                    <img alt='item-icon' src={item.icon} />
                                    <span>{item.label}</span>
                                </a>
                            } else if (item.links && item.links.some(subItem => subItem.canView)) {
                                return (
                                    <div className={openOption ? "navbarMenu-link-with-options-container" : ""}>
                                        <div className="navbarMenu-link navbarMenu-link-with-options" onClick={() => handleClickWithOption(item.label)}>
                                            <div>
                                                <img alt='item-icon' src={item.icon} />
                                                <span>{item.label}</span>
                                            </div>
                                            <img alt='item-icon' src={expandArrow} style={openOption ? { rotate: "270deg", width: "12px" } : { rotate: "90deg", width: "12px" }} />
                                        </div>
                                        <div style={openOption !== item.label ? { display: "none" } : { display: "flex" }} className="navbarMenu-link-options">
                                            {item.links.filter(subItem => subItem.canView).map((link) =>
                                                <a
                                                    href='/'
                                                    data-active={link.link === active || undefined}
                                                    to={link.link}
                                                    key={link.label}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        setActive(link.label);
                                                        navegation(link.link)
                                                        handleCloseNavbarMenu()
                                                    }}
                                                >
                                                    <span>{link.label}</span>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                        }
                        )}
                    </div>

                    <section>
                        <span>{user.info?.lastName[0]}{user.info?.name[0]}</span>
                        <h4>{user.contact?.email}</h4>
                    </section>

                    <div className="navbarMenu-footer" >
                        <a href="/" className="navbarMenu-link" onClick={(event) => handleLogOut(event)}>
                            <img alt='close-session-icon' src={closeSessionIcon} />
                            <span>Cerrar sesión</span>
                        </a>
                    </div>
                </nav>
            </Modal>
        </Box >
    );
}