import {
    ACTION_CLIENT_ERROR,
    ACTION_CLIENT_START,
    ACTION_CLIENT_SUCCESS,
    ADD_CLIENTS_SUCCESS,
    GET_CLIENT_SUCCESS,
    GET_CLIENTS_SUCCESS,
    RESET_QUOTATION_RECORD
} from "../../types/index";

import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";
const cookie = new universlaCookie();

export function addClient(name, fantasyName, code, CUIT, industry, taxCategory) {
    return async (dispatch) => {
        dispatch(actionClientStart());
        try {
            const response = await clienteAxios({
                method: "POST",
                url: "client",
                headers: {
                    "x-acces-token": cookie.get("token"),
                },
                data: {
                    name, fantasyName, code, CUIT, industry, taxCategory
                },
            });
            dispatch(addClientSuccess({ clients: response.data.clients, message: response.data.message }))
        } catch (error) {
            dispatch(actionClientError(error.response.data.message));
        }
    };
}

export function addClientContact(name, lastName, email, phone, clientID) {
    return async (dispatch) => {
        dispatch(actionClientStart());
        try {
            const response = await clienteAxios({
                method: "POST",
                url: "clientContact",
                headers: { "x-acces-token": cookie.get("token") },
                data: { name, lastName, email, phone, clientID },
            });
            dispatch(actionClientSuccessMessage(response.data.message));
        } catch (error) {
            dispatch(actionClientError(error.response.data.message));
        }
    };
}

export function addClientDirection(country, province, locality, neighborhood, street, number, floor, postalCode, clientID) {
    return async (dispatch) => {
        dispatch(actionClientStart());
        try {
            const response = await clienteAxios({
                method: "POST",
                url: "clientDirection",
                headers: { "x-acces-token": cookie.get("token") },
                data: { country, province, locality, neighborhood, street, number, floor, postalCode, clientID },
            });
            dispatch(actionClientSuccessMessage(response.data.message));
        } catch (error) {
            dispatch(actionClientError(error.response.data.message));
        }
    };
}

export function deleteClient(clientID) {
    return async (dispatch) => {
        dispatch(actionClientStart());
        try {
            const response = await clienteAxios({
                method: "DELETE",
                url: `client/?clientID=${clientID}`,
                headers: {
                    "x-acces-token": cookie.get("token"),
                }
            });
            dispatch(actionClientSuccessMessage(response.data.message));
        } catch (error) {
            dispatch(actionClientError(error.response.data.message));
        }
    };
}

export function deleteClientContact(index, clientID) {
    return async (dispatch) => {
        dispatch(actionClientStart());
        try {
            const response = await clienteAxios({
                method: "DELETE",
                url: `clientContact/?clientID=${clientID}&index=${index}`,
                headers: {
                    "x-acces-token": cookie.get("token"),
                }
            });
            dispatch(actionClientSuccessMessage(response.data.message));
        } catch (error) {
            dispatch(actionClientError(error.response.data.message));
        }
    };
}

export function deleteClientDirection(index, clientID) {
    return async (dispatch) => {
        dispatch(actionClientStart());
        try {
            const response = await clienteAxios({
                method: "DELETE",
                url: `clientDirection/?clientID=${clientID}&index=${index}`,
                headers: {
                    "x-acces-token": cookie.get("token"),
                }
            });
            dispatch(actionClientSuccessMessage(response.data.message));
        } catch (error) {
            dispatch(actionClientError(error.response.data.message));
        }
    };
}

export function getClientByID(clientID) {
    return async (dispatch) => {
        dispatch(actionClientStart());
        try {
            const response = await clienteAxios({
                method: "GET",
                url: `client/?clientID=${clientID}`,
                headers: {
                    "x-acces-token": cookie.get("token"),
                }
            });
            dispatch(getClientSuccess(response.data.response));
        } catch (error) {
            dispatch(actionClientError(error.response.data.message));
        }
    };
}

export function getClients() {
    return async (dispatch) => {
        dispatch(actionClientStart());
        try {
            const response = await clienteAxios({
                method: "GET",
                url: `clients`,
                headers: {
                    "x-acces-token": cookie.get("token"),
                }
            });
            dispatch(getClientsSuccess(response.data.response));
            dispatch(resetQuotationRecord())
        } catch (error) {
            dispatch(actionClientError(error.response.data.message));
        }
    };
}

export function getClientsPaginated(page, searcher) {
    return async (dispatch) => {
        dispatch(actionClientStart());
        try {
            const response = await clienteAxios({
                method: "GET",
                url: `paginatedClients?page=${page}&searcher=${searcher}`,
                headers: {
                    "x-acces-token": cookie.get("token"),
                }
            });
            dispatch(getClientsSuccess(response.data.response));
            dispatch(resetQuotationRecord())
        } catch (error) {
            dispatch(actionClientError(error.response.data.message));
        }
    };
}

export function updateClient(clientInfo, clientID) {
    return async (dispatch) => {
        dispatch(actionClientStart());
        try {
            const response = await clienteAxios({
                method: "PUT",
                url: "client",
                headers: { "x-acces-token": cookie.get("token") },
                data: { clientInfo, clientID },
            });
            dispatch(actionClientSuccessMessage(response.data.message));
        } catch (error) {
            dispatch(actionClientError(error.response.data.message));
        }
    };
}

export function updateClientContact(name, lastName, email, phone, index, clientID) {
    return async (dispatch) => {
        dispatch(actionClientStart());
        try {
            const response = await clienteAxios({
                method: "PUT",
                url: "clientContact",
                headers: { "x-acces-token": cookie.get("token") },
                data: { name, lastName, email, phone, index, clientID },
            });
            dispatch(actionClientSuccessMessage(response.data.message));
        } catch (error) {
            dispatch(actionClientError(error.response.data.message));
        }
    };
}

export function updateClientDirection(country, province, locality, neighborhood, street, number, floor, postalCode, index, clientID) {
    return async (dispatch) => {
        dispatch(actionClientStart());
        try {
            const response = await clienteAxios({
                method: "PUT",
                url: "clientDirection",
                headers: { "x-acces-token": cookie.get("token") },
                data: { country, province, locality, neighborhood, street, number, floor, postalCode, index, clientID },
            });
            dispatch(actionClientSuccessMessage(response.data.message));
        } catch (error) {
            dispatch(actionClientError(error.response.data.message));
        }
    };
}

const actionClientError = (message) => ({
    type: ACTION_CLIENT_ERROR,
    payload: message,
});

const actionClientStart = () => ({
    type: ACTION_CLIENT_START,
});

const actionClientSuccessMessage = (response) => ({
    type: ACTION_CLIENT_SUCCESS,
    payload: response,
})

const addClientSuccess = (response) => ({
    type: ADD_CLIENTS_SUCCESS,
    payload: response,
});

const getClientSuccess = (response) => ({
    type: GET_CLIENT_SUCCESS,
    payload: response,
});

const getClientsSuccess = (response) => ({
    type: GET_CLIENTS_SUCCESS,
    payload: response,
});

const resetQuotationRecord = () => ({
    type: RESET_QUOTATION_RECORD
})