import { EXCEL_LOAD_START, EXCEL_LOAD_ERROR, GET_EXCELHISTORY_SUCCESS } from "../../types/index";

const initialState = {
	loading: false,
	error: false,
	message: "",
	excelHistory: [],
};

export default function excelLoadReducer (state = initialState, action) {
	switch (action.type) {
		case EXCEL_LOAD_START:
			return {
				...state,
				loading: true,
				message: "Procesando...",
			};
		case EXCEL_LOAD_ERROR:
			return {
				...state,
				loading: false,
				error: true,
				message: "Error"
			};
		case GET_EXCELHISTORY_SUCCESS:
			return {
				...state,
				excelHistory: action.payload,
				loading: false,
				error: false,
				message: "Exito"
			};
		default:
			return state;
	}
}
