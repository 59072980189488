import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasCreatePermission, hasViewPermission } from "../permissions/utilities/hasPermissions";

import Categories from "./categories/Categories";
import LabelingManager from "../../utilities/components/labelingManager/LabelingManager";
import ConfirmationModal from "../../utilities/Modal/ConfirmationModal";
import CommercializationCreationModal from "../commercialization/modals/CommercializationCreationModal";
import { deleteLabeling, getLabelings, postLabeling, updateLabeling } from "../../../redux/actions/utilities/labelingAction";
import StatusModal from "../../utilities/Modal/StatusModal";

const Categorization = () => {
    const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user)
	const labeling = useSelector((state) => state.labeling)

    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [showCreationModal, setShowCreationModal] = useState(false)
    const [showStatusModal, setShowStatusModal] = useState(false)

	document.title = "Scope | Categorización"

    useEffect(() => {
        if (!showStatusModal) {
            dispatch(getLabelings());
        }
    }, [showStatusModal, dispatch])

    const handlerShowConfirmationModal = () => {
        dispatch(deleteLabeling(showConfirmationModal.id))
        
        setShowConfirmationModal(false);
        setShowStatusModal(true)
    }

    const handlerCreationModalAction = (name) => {
        if (showCreationModal.action === 'Crear') {
            dispatch(postLabeling(name))
        } else {
            dispatch(updateLabeling(name, showCreationModal.values._id))
        }
        setShowCreationModal(false)
        setShowStatusModal(true)
    }

	return (
		<div className="cuentas-contenedor">
			{hasViewPermission('labeling', user) &&  <LabelingManager
                collection={labeling.labelingList}
                information="Clientes, colaboradores o actores de mercado, cada uno de ellos puede ser categorizado para poder filtrar busquedas para hacerlas mas específicas."
                permissions="labeling"
                setShowConfirmationModal={setShowConfirmationModal} 
                setShowCreationModal={setShowCreationModal}
                title="Etiquetas"
                type="etiqueta"
                user={user}
			/>}

			{hasViewPermission('categorization', user) && <Categories /> }

            {showConfirmationModal &&
                <ConfirmationModal
                    descriptionText={`¿Está seguro que quiere eliminar la ${showConfirmationModal.type} "${showConfirmationModal.name}"?`}
                    handlerShowConfirmationModal={handlerShowConfirmationModal}
                    setShowConfirmationModal={setShowConfirmationModal}
                    titleText={`Eliminar ${showConfirmationModal.type}`}
                />}
            {showCreationModal &&
                <CommercializationCreationModal
                    handlerCreationModalAction={handlerCreationModalAction}
                    showCreationModal={showCreationModal}
                    setShowCreationModal={setShowCreationModal}
                />}
			{showStatusModal && (
				<StatusModal loading={labeling.loading} error={labeling.error} setModalStatusCloseAction={setShowStatusModal}>
					{labeling.message}
				</StatusModal>
			)}
		</div>
	);
};

export default Categorization;
