import {
	ACTION_CAR_PRODUCTS_START,
	ACTION_CAR_PRODUCTS_ERROR,
	GET_CAR_BRANDS_SUCCESS,
	GET_CAR_COMPANIES_SUCCESS,
	GET_CAR_MODELS_SUCCESS,
	GET_CAR_PRODUCTS_SUCCESS,
	GET_CAR_FILTERS_SUCCESS,
	GET_CAR_VERSIONS_SUCCESS,
	CURRENCY_CHANGE,
} from "../../types/index";

const initialState = {
	brand: null,
	brands: [],
	company: null,
	companies: [],
	count: 1,
	currency: "ARS",
	error: false,
	filters: [],
	filterSearch: [],
	hasPrice: false,
	isUpdated: false,
	labeling: null,
	loading: false,
	maxKms: null,
	maxPrice: null,
	maxTotalPrice: null,
	maxYear: 3000,
	message: "",
	minKms: null,
	minPrice: null,
	minTotalPrice: null,
	minYear: 0,
	model: null,
	models: [],
	orderByField: "normalizedPrice",
	orderByValue: 1,
	page: 1,
	products: [],
	suggested: null,
	textSearch: "",
	version: null,
	versions: [],
};

export default function carProductsReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_CAR_PRODUCTS_START:
			return {
				...state,
				error: false,
				loading: true,
			};
		case ACTION_CAR_PRODUCTS_ERROR:
			return {
				...state,
				error: true,
				loading: false,
			};
		case GET_CAR_BRANDS_SUCCESS:
			return {
				...state,
				brands: action.payload,
				error: true,
				loading: false,
				model: null,
				models: [],
			};
		case GET_CAR_COMPANIES_SUCCESS:
			return {
				...state,
				companies: action.payload,
				error: true,
				loading: false,
			};
		case GET_CAR_MODELS_SUCCESS:
			return {
				...state,
				models: action.payload,
				error: true,
				loading: false,
			};
		case GET_CAR_VERSIONS_SUCCESS:
			return {
				...state,
				versions: action.payload,
				error: true,
				loading: false,
			};
		case GET_CAR_PRODUCTS_SUCCESS:
			return {
				...state,
				brand: action.payload.brand,
				error: false,
				loading: false,
				products: action.payload.resp.data,
				company: action.payload.company,
				count: action.payload.resp.metadata.totalCount,
				page: action.payload.resp.metadata.page,
				labeling: action.payload.labeling,
				minYear: action.payload.minYear,
				maxYear: action.payload.maxYear,
				minKms: action.payload.minKms,
				maxKms: action.payload.maxKms,
				minPrice: action.payload.minPrice,
				maxPrice: action.payload.maxPrice,
				minTotalPrice: action.payload.resp.metadata.minTotalPrice,
				maxTotalPrice: action.payload.resp.metadata.maxTotalPrice,
				model: action.payload.model,
				suggested: action.payload.resp.suggested,
				textSearch: action.payload.textSearch,
				filterSearch: action.payload.filterSearch,
				isUpdated: action.payload.isUpdated,
				hasPrice: action.payload.hasPrice,
				currency: action.payload.currency,
				orderByField: action.payload.orderByField,
				orderByValue: action.payload.orderByValue,
				version: action.payload.version,
			};
		case GET_CAR_FILTERS_SUCCESS:
			return {
				...state,
				error: false,
				loading: false,
				filters: action.payload,
			};
		case CURRENCY_CHANGE:
			return {
				...state,
				currency: action.payload
			}
		default:
			return state;
	}
}
