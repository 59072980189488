import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as XLSX from "xlsx";

import { Modal } from '@mantine/core';

import SelectPlantilla from "../../cargaPlanillas/SelectPlanilla";
import { excelClientsLoad } from "../../../redux/actions/utilities/excelLoadAction";

export default function ModalImportClients({ setShowImportClientsModal, setShowStatusModal }) {
    const dispatch = useDispatch();

    const [dataParsing, setDataParsing] = useState();
    const [items, setItems] = useState(null);
    const [headers, setHeaders] = useState(null);
    const [fileName, setFileName] = useState("Ningun archivo seleccionado");

    const [clientNameFlag, setClientNameFlag] = useState(null);

    async function handleImportClients() {
        const cleanData = dataParsing.filter((data) => {
            if (data.etiqueta !== 'Dejar campo vacio') return data
            return null
        })
        setDataParsing(cleanData)
        setShowImportClientsModal(false)
        setShowStatusModal(true);
        const response = await dispatch(excelClientsLoad(items, cleanData));
        if (response) {
            setItems(null);
            setFileName("Ningun archivo seleccionado");
        }
    };

    const readExcel = (file) => {
        const fileReader = new FileReader();
        if (file) {
            const promise = new Promise((resolve, reject) => {
                fileReader.readAsArrayBuffer(file);

                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLSX.read(bufferArray, { type: "buffer" });
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    const data = XLSX.utils.sheet_to_json(ws);
                    const columns = XLSX.utils.sheet_to_json(ws, {
                        header: 1,
                    });

                    setHeaders(columns[0]);
                    resolve(data);
                };

                fileReader.onerror = (error) => {
                    reject(error);
                };
            });

            promise.then((data) => {
                setItems(data);
                setFileName(file.name);
            });
        }
    };

    const handleSelectInput = (evento, etiqueta) => {
        let flag = true;

        if(etiqueta === 'name' && evento.target.value !== 'Dejar campo vacio') setClientNameFlag(true)
        else if (etiqueta === 'name' && evento.target.value === 'Dejar campo vacio') setClientNameFlag(false)

        dataParsing &&
            dataParsing.map((llave, index) => {
                if (llave.tag === etiqueta) {
                    dataParsing[index].etiqueta = evento.target.value;
                    flag = false;
                }
                return null
            });

        if (flag && evento.target.value !== 'Dejar campo vacio')
            setDataParsing(
                dataParsing ? [...dataParsing, { tag: etiqueta, etiqueta: evento.target.value }] : [{ tag: etiqueta, etiqueta: evento.target.value }]
            );

        var select = document.getElementById(evento.nativeEvent.srcElement.id);
        var selectedOption = []

        if (evento.target.value === 'Dejar campo vacio') {
            selectedOption = select.options[select.selectedIndex];
            selectedOption.text = "Seleccionar";
            selectedOption.className = "d-none"
        } else {
            selectedOption = select.options[select.options.length - 1];
            selectedOption.text = "Dejar campo vacio";
            selectedOption.className = ""
        }
    };

    return (
        <Modal
            centered
            title="Importar clientes"
            opened={true}
            onClose={() => { setShowImportClientsModal(false) }}
            overlayProps={{
                backgroundOpacity: 0.5,
                blur: 3,
                zIndex: 1100
            }}
        >
            <div className="entitiy-addModal-container">

                <div className="cargar-planillas-fileSelect">
                    <input
                        id="fileSelect"
                        type="file"
                        accept=".xlsx"
                        onChange={(e) => {
                            readExcel(e.target.files[0]);
                        }}
                        onClick={(e) => {
                            readExcel(e.target.files[0]);
                        }}
                    />
                    <label htmlFor="fileSelect">
                        <span>Cargar</span>
                        <span>{fileName}</span>
                    </label>
                </div>

                {items && (
                    <>
                        <div className="entity-importClients-container">
                            <section>
                                <h4>Entidad</h4>
                                <SelectPlantilla etiqueta="name" label="Nombre" headers={headers} handleSelectInput={handleSelectInput} />
                                <SelectPlantilla etiqueta="fantasyName" label="Nombre fantasía" headers={headers} handleSelectInput={handleSelectInput} />
                                <SelectPlantilla etiqueta="CUIT" label="Número de documento / CUIT" headers={headers} handleSelectInput={handleSelectInput} />
                                <SelectPlantilla etiqueta="industry" label="Industría" headers={headers} handleSelectInput={handleSelectInput} />
                                <SelectPlantilla etiqueta="taxCategory" label="Clave fiscal" headers={headers} handleSelectInput={handleSelectInput} />
                            </section>
                            <section>
                                <h4>Dirección</h4>
                                <SelectPlantilla etiqueta="country" label="Pais" headers={headers} handleSelectInput={handleSelectInput} />
                                <SelectPlantilla etiqueta="province" label="Provincia" headers={headers} handleSelectInput={handleSelectInput} />
                                <SelectPlantilla etiqueta="locality" label="Ciudad" headers={headers} handleSelectInput={handleSelectInput} />
                                <SelectPlantilla etiqueta="neighborhood" label="Barrio" headers={headers} handleSelectInput={handleSelectInput} />
                                <SelectPlantilla etiqueta="street" label="Calle" headers={headers} handleSelectInput={handleSelectInput} />
                                <SelectPlantilla etiqueta="streetNumber" label="Número" headers={headers} handleSelectInput={handleSelectInput} />
                                <SelectPlantilla etiqueta="floor" label="Piso" headers={headers} handleSelectInput={handleSelectInput} />
                                <SelectPlantilla etiqueta="postalCode" label="Código postal" headers={headers} handleSelectInput={handleSelectInput} />
                            </section>
                            <section>
                                <h4>Contacto</h4>
                                <SelectPlantilla etiqueta="contactName" label="Nombre" headers={headers} handleSelectInput={handleSelectInput} />
                                <SelectPlantilla etiqueta="contactLastName" label="Apellido" headers={headers} handleSelectInput={handleSelectInput} />
                                <SelectPlantilla etiqueta="contactEmail" label="Email" headers={headers} handleSelectInput={handleSelectInput} />
                                <SelectPlantilla etiqueta="contactPhone" label="Telefono" headers={headers} handleSelectInput={handleSelectInput} />
                            </section>
                            <section>
                                <h4>Redes</h4>
                                <SelectPlantilla etiqueta="instagram" label="Instagram" headers={headers} handleSelectInput={handleSelectInput} />
                                <SelectPlantilla etiqueta="linkedin" label="Linkedin" headers={headers} handleSelectInput={handleSelectInput} />
                                <SelectPlantilla etiqueta="web" label="Web" headers={headers} handleSelectInput={handleSelectInput} />
                            </section>
                        </div>
                        <button onClick={handleImportClients} disabled={!clientNameFlag}>
                            Crear
                        </button>
                    </>
                )}
            </div>
        </Modal>
    );
}
