import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
import { Menu, MenuItem } from "@szhsin/react-menu";
import ListItemButton from "@mui/material/ListItemButton";
import { makeStyles } from "mui-styles";
import { notifications } from '@mantine/notifications';

import { addProductToQuotation } from "../../../../redux/actions/utilities/quotationAction";

import symbolArrowUp from "../../../../assets/svg/information-icons/symbol_arrow_up.svg"
import symbolArrowDown from "../../../../assets/svg/information-icons/symbol_arrow_down.svg"
import noImage from "../../../../assets/svg/system-images/producto-sin-imagen.svg";
import addQuotationItem from "../../../../assets/svg/functionalities-icons/add-quotation-item.svg";
import copyItem from "../../../../assets/svg/functionalities-icons/copy-item.svg";

import "./productCard.css";
import { hasEditPermission, hasPermissionByPermissionName } from "../../../configuration/permissions/utilities/hasPermissions";

const useStyles = makeStyles({
	blockcontainer: {
		display: "flex",
		justifyContent: "flex-end",
		margin: "0 30px 0 0",
		padding: 0,
	},
});

export default function ProductCard({ item, selectedPricelist }) {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user);
	const currency = useSelector((state) => state.products.currency);

	const quotationID = useSelector((state) => state.quotation.quotation);
	const quotations = useSelector((state) => state.quotation.list)

	const quotationData = useSelector((state) => state.quotation.data?.products)

	const [product, setProduct] = useState(item);

	const classes = useStyles();

	useEffect(() => {
		let precioProducto = parseFloat(product.normalizedCountedPrice);

		let productDesactualizado;

		if (product.expiration) {
			if (!product.expiration.isExpirable && product.expiration.expirationDelta === 0) productDesactualizado = -1
			else productDesactualizado = !(product.isExpirable && Date.parse(new Date()) - Date.parse(product.timestamp) - product.expirationDelta * 24 * 60 * 60 * 1000 > 0)
		}
		else {
			productDesactualizado = Date.parse(new Date()) - Date.parse(product.timestamp) - 24 * 60 * 60 * 1000 > 0;
		}
		const priceWithTaxes = item.normalizedCountedPrice * 1 +
			item.normalizedCountedPrice * Object.values(item.price.taxes)
				.map(tax => Object.values(tax)[0] * 0.01)
				.reduce((acc, val) => acc + val, 0)

		setProduct({ ...product, normalizedCountedPrice: item.normalizedCountedPrice, productDesactualizado, precioProducto, priceWithTaxes });
	}, [item]);

	useEffect(() => {
		productInQuotation = quotationData && quotationData.find(objeto => objeto._id === product._id)
	}, [quotationData, quotationID])

	const formatearNumero = (valor) => {
		return new Intl.NumberFormat("de-DE", {
			minimumFractionDigits: 2,  // Mínimo de 2 decimales
			maximumFractionDigits: 2   // Máximo de 2 decimales
		}).format((valor * 1));
	};

	const handlePostQuotation = () => {
		dispatch(addProductToQuotation(product, selectedPricelist._id, quotationID, currency));
		notifications.show({
			title: `Item añadido (${productInQuotation ? productInQuotation.quantity + 1 : 1})`,
			message: product.info.title.toUpperCase(),
			autoClose: 1500,
		})
	};

	const handleCopyInfo = () => {
		const textarea = document.createElement("textarea");
		textarea.value =
			product.info.partNumber?.toUpperCase() + " - " + product.info.title.toString().toUpperCase();
		document.body.appendChild(textarea);
		textarea.select();
		document.execCommand("copy");
		document.body.removeChild(textarea);
		notifications.show({
			title: 'Copiado',
			message: 'El número de parte y titulo han sido copiados al portapapeles',
			autoClose: 1500,
		})
	};

	let canAddItem = hasEditPermission('quotations', user) && quotations && quotations.length > 0 && quotations.find(quotation => quotation._id === quotationID);

	let productInQuotation = quotationData && quotationData.find(objeto => objeto._id === product._id)

	return (
		<div className="productCard-container" >
			{product.stock.hasStock ? (
				<div className="productos-tarjeta_producto-stock-solapa-proveedor" >
					<div className="productos-tarjeta_producto-stock-solapa" >
						<div
							className="productos-tarjeta_producto-stock-solapa-titulo"
							style={product.productDesactualizado > 0 ? { border: "2px solid var(--color-red)", background: "var(--color-red)" } : {}}
						>
							<span>En stock</span>
						</div>
						<div style={product.productDesactualizado > 0 ? { border: "2px solid var(--color-red)" } : {}}>
							<span style={{ fontSize: "11px" }}>{product.stock.value}</span>
						</div>
					</div>
					{hasPermissionByPermissionName('products', 'viewSupplier', user) &&
						<>
							<div type="button" className="productos-tarjeta_producto-stock-solapa-ubicaciones-btn">
								{product.stock.stockDetail.length > 0 && (
									<Menu
										menuButton={
											<ListItemButton className={classes.blockcontainer}>
												<span className="productos-tarjeta_producto-stock-solapa-ubicaciones-btn-p">Detalle stock</span>
											</ListItemButton>
										}
									>
										{product.stock.stockDetail.map((detail, i) => {
											return (
												<MenuItem key={i}>
													<span className="m-0">
														{detail.location}: {detail.quantity}
													</span>
												</MenuItem>
											);
										})}
									</Menu>
								)}
							</div>
							<div className="productos-tarjeta_producto-stock-solapa-ubicaciones">
								{product.stock.stockDetail?.map((detail, i) => {
									return (
										<Tooltip title={detail.location || "Title"} key={i} followCursor>
											<div className="d-flex" type="button">
												<span className="productos-tarjeta_producto-stock-solapa-ubicaciones-btn-p me-1">
													{detail.location}: {detail.quantity} |
												</span>
											</div>
										</Tooltip>
									);
								})}
							</div>
						</>
					}
				</div>
			) : (
				<div className="productos-tarjeta_producto-sin_stock-solapa">
					<span>Sin stock</span>
				</div>
			)}
			<div className="productCard-options">
				<img alt="copy-icon" src={copyItem} className="global-small-img-button" onClick={handleCopyInfo} title="Copiar informacion de producto" />
				{canAddItem && <img alt="add-icon" className="global-small-img-button" src={addQuotationItem} onClick={handlePostQuotation} title="Agregar item a lista de cotizacion" />}
				{productInQuotation && <span>{productInQuotation.quantity ? productInQuotation.quantity : "1"}</span>}
			</div>
			<Link
				to={`/Producto?id=${item._id}`}
				className="productCard-info"
				key={item._id}
				style={product.productDesactualizado > 0 ? { border: "2px solid var(--color-red)" } : {}}
			>
				<div>
					<div style={{ marginTop: "30px" }}>
						<h6>Categoría {" "} <span style={{ textTransform: "uppercase" }}>{product.info.productIndex?.name.replace(/_/g, " ")}</span></h6>
						<h6>NP <span>{product.info.partNumber?.toUpperCase()}</span></h6>
					</div>
					<img
						src={
							Array.isArray(product.info.images) && product.info.images[0]
								? product.info.images[0]
								: noImage
						}
						style={{ maxWidth: "100px", maxHeight: "100px" }}
						alt="img. prod."
					/>
				</div>
				<div>
					<h3>
						Marca: <span>{product.info.brand || 'No disponible'}</span>
					</h3>
					{hasPermissionByPermissionName('products', 'viewSupplier', user) &&
						<h3>
							{product.company.isSupplier ? "Proveedor" : "Competencia"} <span>{product.company.name}</span>
						</h3>
					}
				</div>
				<div style={{ alignContent: "space-around" }}>

					{product.expiration ?
						(
							product.productDesactualizado > 0 ?
								<div>
									<span>Producto desactualizado. Cargado el {product.timestamp.split("T")[0]}</span>
								</div>
								: <div>
									<span style={{ color: "var(--color-grey)" }}>Carga del articulo: {product.timestamp.split("T")[0]}</span>
								</div>) :
						product.productDesactualizado > 0 ?
							<div>
								<span>Producto desactualizado. Ultima actualización {product.timestamp.split("T")[0]}</span>
							</div>
							: <div>
								<span style={{ color: "var(--color-grey)" }}>Extracción de articulo: {product.timestamp.split("T")[1].split(':').slice(0, 2).join(':')}hs</span>
							</div>
					}

					<h2>{product.info.title.toString().toUpperCase()}</h2>
					{hasPermissionByPermissionName('products', 'viewCostDetail', user) && (
						product.normalizedCountedPrice === 0 ?
							<div className="productCard-noPrice">
								<h4>Sin precio</h4>
							</div>
							: <div className="productCard-price-container">
								<div style={{ marginBottom: "-10px" }}>
									<div>
										<h4>{product.company.isSupplier ? "Precio sin impuestos" : "Precio de contado"}</h4>
										<span>
											{currency}{" "}
											{formatearNumero(product.normalizedCountedPrice * 1)}
										</span>
									</div>

									{product.price.taxes.map((tax) => {
										if (Object.values(tax)[0] > 0)
											return (
												<div key={Object.keys(tax)[0]}>
													<h4>{`${Object.keys(tax)[0]} (${Object.values(tax)[0].toFixed(2)}%)`}</h4>
													<span>{currency} {formatearNumero(product.normalizedCountedPrice * (Object.values(tax)[0] * 0.01))}</span>
												</div>
											)
										else return undefined
									})}
									{product.price.taxes.length > 0 ? <div>
										<h4>{"Precio final"}</h4>
										<span>
											{currency}{" "}
											{formatearNumero(product.priceWithTaxes * 1)}
										</span>
									</div> :
										<div>
											<h6>No indíca impuestos</h6>
										</div>}
								</div>
								<div className="productCard-same-product">
									{/* Diferencia en porcentaje */}
									{false && product.percentageDifference !== null &&
										<div className="productCard-price-diference">
											<span>{parseFloat(product.percentageDifference).toFixed(2) + " %"}</span>
											<img src={parseFloat(product.percentageDifference) > 0 ? symbolArrowUp : symbolArrowDown} />
										</div>
									}
									{product.options && product.options.length > 0 &&
										(product.options.filter(option => option.normalizedCountedPrice < product.normalizedCountedPrice).length === 0 ?
											<span>
												Mejor opción
											</span>
											:
											<span>
												Hay mejores opciones
											</span>
										)
									}
								</div>
							</div>
					)}
				</div>
				{hasPermissionByPermissionName('products', 'viewPriceDetail', user) && product.normalizedCountedPrice !== 0 && (
					<section style={product.productDesactualizado > 0 ? { width: "330px" } : { width: "330px" }} className={currency === "USD" ? "" : "product-ARS-currency-theme"}>
						<div>
							<h6>Precio de venta: </h6>
							<span>
								{currency}{" "}
								{formatearNumero(product.normalizedCountedPrice * 1 + product.normalizedCountedPrice * 0.01 * selectedPricelist.percentage)}
							</span>
						</div>
						<div>
							<h6>Precio de venta final: </h6>
							<span>
								{currency}{" "}
								{formatearNumero(product.priceWithTaxes * 1 + product.priceWithTaxes * 0.01 * selectedPricelist.percentage)}
							</span>
						</div>
					</section>
				)}
			</Link>
		</div>
	);
}
