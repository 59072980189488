import {
	ACTION_MARKET_PLAYER_ERROR,
	ACTION_MARKET_PLAYER_RESET,
	ACTION_MARKET_PLAYER_START,
	ACTION_MARKET_PLAYER_SUCCESS,
	GET_MARKET_PLAYER_SUCCESS,
	GET_MARKET_PLAYERS_SUCCESS,
	GET_PRODUCT_INDICES_SUCCESS,
} from "../../types/index";

const initialState = {
	error: false,
	marketPlayers: [],
	selectedMarketPlayer: null,
	message: "",
	indices: [],
	loading: false,
};

export default function marketPlayersReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_MARKET_PLAYER_START:
			return {
				...state,
				error: false,
				loading: true,
			};
		case ACTION_MARKET_PLAYER_ERROR:
			return {
				...state,
				error: true,
				message: action.payload,
				loading: false,
			};
		case GET_MARKET_PLAYERS_SUCCESS:
			return {
				...state,
				error: false,
				indices: [],
				marketPlayers: action.payload,
				loading: false,
			};
		case GET_MARKET_PLAYER_SUCCESS:
			return {
				...state,
				error: false,
				indices: [],
				selectedMarketPlayer: action.payload,
				loading: false,
			};
		case GET_PRODUCT_INDICES_SUCCESS:
			return {
				...state,
				error: false,
				indices: action.payload,
				loading: false
			}
		case ACTION_MARKET_PLAYER_SUCCESS:
			return {
				...state,
				error: false,
				message: action.payload,
				loading: false
			}
		case ACTION_MARKET_PLAYER_RESET:
			return {
				...state,
				error: false,
				message: "",
				loading: false,
			}
		default:
			return state;
	}
}
