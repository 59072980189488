import {
	ACTION_RESOURCE_ERROR,
	ACTION_RESOURCE_START,
	ACTION_RESOURCE_SUCCESS,
} from "../../types/index";

import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";

const cookie = new universlaCookie();

export function getResources() {
	return async (dispatch) => {
		dispatch(actionStart());
		try {
			const response = await clienteAxios.get('resources', { headers: { "x-acces-token": cookie.get("token") } });
			dispatch(actionSuccess(response.data.response));
		} catch (e) {
			dispatch(actionError());
		}
	};
}

const actionStart = () => ({
	type: ACTION_RESOURCE_START,
});

const actionSuccess = (response) => ({
	type: ACTION_RESOURCE_SUCCESS,
	payload: response,
});

const actionError = () => ({
	type: ACTION_RESOURCE_ERROR,
});
