import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";

import EntityCard from '../EntityCard';
import ModalAddCollaborator from './ModalAddCollaborator';

import { getCollaborators } from '../../../redux/actions/entities/collaboratorAction';

import iconAddNew from "../../../assets/svg/functionalities-icons/plus_white.svg";

import "../entities.css"
import { TextSearcher } from '../../utilities/components/TextSearcher';
import { useSearchParams } from 'react-router-dom';
import { Pagination } from '@mantine/core';
import { getRoles } from '../../../redux/actions/utilities/roleAction';
import { hasCreatePermission } from '../../configuration/permissions/utilities/hasPermissions';
import SectionAccordion from '../../utilities/components/SectionAccordion';

const Collaborators = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    const collaboratorsReducer = useSelector((state) => state.collaborators);
    const user = useSelector((state) => state.auth.user);

    const [activePage, setPage] = useState(searchParams.get('page') !== null ? parseInt(searchParams.get('page')) : 1);
    const [searcherInput, setSearcherInput] = useState("");

    const [showAddCollaboratorModal, setShowAddCollaboratorModal] = useState(false)

    document.title = "Scope | Colaboradores"

    useEffect(() => {
        if (!showAddCollaboratorModal) {
            dispatch(getCollaborators(activePage, searcherInput));
        }
        dispatch(getRoles());
    }, [showAddCollaboratorModal, dispatch])

    useEffect(() => {
        dispatch(getCollaborators(1, searcherInput));
    }, [searcherInput])

    const handlePathPage = (event) => {
        setPage(event)

        dispatch(getCollaborators(event, searcherInput))
        searchParams.set("page", event);
        setSearchParams(searchParams);
    }

    useEffect(() => {
        setPage(!searchParams.get('page') ? 1 : parseInt(searchParams.get('page')))
    }, [searchParams]);

    return (
        <div className="cuentas-contenedor">
            <SectionAccordion title="Colaboradores">
                <div className="entity-filters-container">
                    <TextSearcher placeholder="Nombre, apellido o CUIL" setSearchFilter={setSearcherInput} />
                    <section style={{ width: "100%" }}>
                        {hasCreatePermission("collaborators", user) &&
                            <img alt="add" src={iconAddNew} className="entity-addNew-botton" onClick={() => setShowAddCollaboratorModal(!showAddCollaboratorModal)} />
                        }
                        {collaboratorsReducer.count > 0 && !collaboratorsReducer.loading &&
                            <span className="products-totalQuantity">Total de clientes: <b>{collaboratorsReducer.count}</b></span>
                        }
                    </section>
                </div>
                <section className="entity-cards-container">
                    {collaboratorsReducer.collaborators &&
                        collaboratorsReducer.collaborators.length > 0 &&
                        collaboratorsReducer.collaborators.map((collaborator) => {
                            return (
                                <EntityCard
                                    key={collaborator._id}
                                    mainText={collaborator.info.lastName + " " + collaborator.info.name}
                                    secondaryText={collaborator.role.name}
                                    entityID={collaborator._id}
                                    type="collaborator" />
                            );
                        })}
                </section>
                <div className="pagination-container">
                    <Pagination
                        color="var(--color-gray-blue-dark)"
                        onChange={(event) => handlePathPage(event)}
                        total={Math.ceil(collaboratorsReducer.count / 20)}
                        value={parseInt(collaboratorsReducer.page)}
                    />
                </div>
            </SectionAccordion>
            {showAddCollaboratorModal && <ModalAddCollaborator setShowAddCollaboratorModal={setShowAddCollaboratorModal} />}
        </div>
    )
}

export default Collaborators