import React, { useEffect, useState, } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import { Pagination, TagsInput } from '@mantine/core';
import { Modal, Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import CarFilters from "./carFilters/CarFilters";
import MenuItems from "../../navbar/MenuItems";
import CarProductCard from "./carProductCard/CarProductCard";

import { getCurrency } from "../../../redux/actions/utilities/currencyAction";
import { getCarProducts, getCarProductsBrands, getCarProductsCompanies } from "../../../redux/actions/productos/carProductsAction";

import iconWhiteCross from "../../../assets/svg/functionalities-icons/cross_white.svg";
import iconoItemNoEncontrado from "../../../assets/svg/system-images/no-encontrado-41.svg";

import "./carProductos.css";
import { CurrencyCard } from "../../recommendations/currencyCard/CurrencyCard";
import { LoadingProductCard } from "../generic/loadingProductCard/LoadingProductCard";
import { getLabelings } from "../../../redux/actions/utilities/labelingAction";
import { CarsMarketValuesRecommendation } from "../../recommendations/carsMarketValuesRecommendation/CarsMarketValuesRecommendation";
import { getPriceLists } from "../../../redux/actions/utilities/priceListAction";

export default function CarProducts({ indexID, indexPath }) {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    const currency = useSelector((state) => state.currency.data);
    const productsReducer = useSelector((state) => state.carProducts);
    const priceLists = useSelector((state) => state.priceList.priceList);

    const [selectedPricelist, setSelectedPricelist] = useState({ name: "", percentage: 0 });

    const [activePage, setPage] = useState(searchParams.get('page') !== null ? parseInt(searchParams.get('page')) : 1);
    const [tags, setTags] = useState(productsReducer.filterSearch);
    const [search, setSearch] = useState(searchParams.get('textSearch') !== null ? searchParams.get('textSearch') : "");

    const [openModal, setOpenModal] = useState(false)

    const [actualNotFound, setActualNotFound] = useState(false)

    useEffect(() => {
        dispatch(getCarProductsBrands(indexID, searchParams.get('company') !== null ? searchParams.get('company') : undefined))
        dispatch(getCarProductsCompanies(indexID, searchParams.get('brand') !== null ? searchParams.get('brand') : undefined))
        dispatch(getLabelings())
        dispatch(getCurrency());
        dispatch(getPriceLists());
        dispatch(getCarProducts(
            indexID,
            {
                brand: searchParams.get('brand') !== null ? searchParams.get('brand') : null,
                company: searchParams.get('company') !== null ? searchParams.get('company') : null,
                hasPrice: searchParams.get('hasPrice') !== null ? searchParams.get('hasPrice') === "true" : true,
                isUpdated: searchParams.get('isUpdated') !== null ? searchParams.get('isUpdated') === "true" : true,
                page: searchParams.get('page') !== null ? parseInt(searchParams.get('page')) : 1,
                currency: productsReducer.currency,
                labeling: searchParams.get('labeling') !== null ? searchParams.get('labeling') : null,
                minYear: searchParams.get('minYear') !== null ? new Date(parseInt(searchParams.get('minYear')), 0, 1) : 0,
                maxYear: searchParams.get('maxYear') !== null ? new Date(parseInt(searchParams.get('maxYear')), 0, 1) : 3000,
                minPrice: searchParams.get('minPrice') !== null ? parseFloat(searchParams.get('minPrice')) : "",
                maxPrice: searchParams.get('maxPrice') !== null ? parseFloat(searchParams.get('maxPrice')) : "",
                orderByField: searchParams.get('orderByField') !== null ? searchParams.get('orderByField') : "normalizedPrice",
                orderByValue: searchParams.get('orderByValue') !== null ? parseInt(searchParams.get('orderByValue')) : 1,
                textSearch: searchParams.get('textSearch') !== null ? searchParams.get('textSearch') : "",
                filterSearch: searchParams.get('filterSearch') !== null ? searchParams.get('filterSearch').split(" ").map(item => item.replace("_", " ")) : [],
                version: searchParams.get('version') !== null ? searchParams.get('version') : null,
                model: searchParams.get('model') !== null ? searchParams.get('model') : null,
            }
        ));
        //dispatch(getProductsFilters(indexID));
        document.title = "Scope | Lista de productos"

        setTimeout(() => {
            setActualNotFound(true)
        }, 500);
    }, [dispatch]);

    useEffect(() => {
        setTags(searchParams.get('filterSearch') !== "" && searchParams.get('filterSearch') !== null ? searchParams.get('filterSearch').split(" ").map(item => item.replace("_", " ")) : []);
        setSearch(searchParams.get('textSearch'))
        setPage(!searchParams.get('page') ? 1 : parseInt(searchParams.get('page')))
    }, [searchParams]);

    useEffect(() => {
        if (priceLists[0]) setSelectedPricelist(priceLists[0]);
    }, [priceLists]);

    const handleSearchClick = () => {
        if (location.pathname !== "/allProducts" || search !== productsReducer.textSearch) {
            let minYear, maxYear, maxPrice, minPrice, hasPrice, isUpdated, filterSearch;

            minYear = searchParams.get('minYear') !== null ? parseFloat(searchParams.get('minYear')) : 0;
            maxYear = searchParams.get('maxYear') !== null ? parseFloat(searchParams.get('maxYear')) : 3000;

            if (searchParams.get('maxPrice') !== null) {
                if (productsReducer.maxTotalPrice === null || parseFloat(searchParams.get('maxPrice')) <= productsReducer.maxTotalPrice) {
                    maxPrice = parseFloat(searchParams.get('maxPrice'));
                } else {
                    maxPrice = productsReducer.maxTotalPrice !== null ? productsReducer.maxTotalPrice : "";
                }
            } else {
                maxPrice = "";
            }

            if (searchParams.get('minPrice') !== null) {
                if (productsReducer.minTotalPrice === null || parseFloat(searchParams.get('minPrice')) >= productsReducer.minTotalPrice) {
                    minPrice = parseFloat(searchParams.get('minPrice'));
                } else {
                    minPrice = productsReducer.minTotalPrice !== null ? productsReducer.minTotalPrice : "";
                }
            } else {
                minPrice = "";
            }

            hasPrice = searchParams.get('hasPrice') !== null ? searchParams.get('hasPrice') === "true" : true;
            isUpdated = searchParams.get('isUpdated') !== null ? searchParams.get('isUpdated') === "true" : true;
            filterSearch = searchParams.get('filterSearch') !== null ? searchParams.get('filterSearch').split(" ").map(item => item.replace("_", " ")) : [];

            dispatch(getCarProducts(indexID, {
                ...productsReducer,
                textSearch: search,
                minYear: minYear,
                maxYear: maxYear,
                minPrice: minPrice,
                maxPrice: maxPrice,
                filterSearch: filterSearch,
                hasPrice: hasPrice,
                isUpdated: isUpdated,
                currency: productsReducer.currency,
                page: 1
            }));
            searchParams.set("textSearch", search);
            setSearchParams(searchParams);
        }
    }

    const handleTagInputChange = (value) => {
        if (value.length > tags.length) {
            setSearch(value[value.length - 1]);
            searchParams.set("textSearch", value[value.length - 1]);
            setSearchParams(searchParams);
        }
    };

    const handleRemove = (item) => {
        setTags(oldTags => {
            return oldTags.filter(tag => tag !== item)
        })

        let filterSearch = productsReducer.filterSearch ? productsReducer.filterSearch.filter(filter => filter !== item) : []

        dispatch(getCarProducts(indexID, { ...productsReducer, filterSearch: filterSearch, page: 1 }));

        filterSearch = filterSearch.map(item => item.replace(" ", "_"));
        searchParams.set("filterSearch", filterSearch.join(" "));
        setSearchParams(searchParams);
    }

    const handlePathPrice = (event) => {
        setPage(event)
        dispatch(getCarProducts(indexID, { ...productsReducer, page: event }))
        searchParams.set("page", event);
        setSearchParams(searchParams);
    }

    const handleCloseRecommendationModal = (event) => {
        if (event.target.className === 'producto-recomendaciones-seccion-recomendaciones') {
            setOpenModal(false)
        }
    }

    return (
        <div className="products">
            <MenuItems />
            <div className="productos-sin-accesos_rapidos">
                <div className="products-left-container">
                    <div>
                        <CarFilters productIndex={indexID} setSelectedPricelist={setSelectedPricelist}/>
                    </div>
                </div>
                <div className="productos-contenedor-central">
                    <div className="productos-contenedor-barra_busqueda">
                        <TagsInput
                            value={tags}
                            searchValue={search || ""}
                            onSearchChange={(event) => setSearch(event)}
                            onChange={handleTagInputChange}
                            placeholder="Escribe aquí"
                            splitChars={[]}
                            onRemove={handleRemove}
                            onKeyDown={(event) => { if (event.key === 'Enter') handleSearchClick() }}
                            label={`Buscando en: ${indexPath === "allProducts" ? "Todos los productos" : indexPath.replaceAll("-", " > ").replaceAll("_", " ")}`}
                            styles={{
                                input: {
                                    backgroundColor: '#fff'
                                },
                                label: {
                                    color: '#555555',
                                    fontSize: "12px"
                                }
                            }}
                        />
                        <button onClick={handleSearchClick}>Buscar</button>
                    </div>
                    {productsReducer.products.length > 0 && !productsReducer.loading ?
                        <span className="products-totalQuantity">Total de productos: <b>{productsReducer.count}</b></span> : <span style={{ height: "15px" }}></span>
                    }
                    <div className={`products-cards-container ${productsReducer.products.length === 0 ? "products-noProducts-display" : ""}`}>
                        {productsReducer.loading ?
                            Array.from({ length: 12 }, () => { return (<LoadingProductCard key={uuidv4()} />); })
                            : productsReducer.products?.map((product) => {
                                return <CarProductCard key={product._id} item={product} selectedPricelist={selectedPricelist}/>;
                            })
                        }
                        {actualNotFound && productsReducer.products.length === 0 && (
                            <div style={{ margin: "auto" }}>
                                <h3 style={{ fontSize: "20px" }}>No se encuentran artículos</h3>
                                <img src={iconoItemNoEncontrado} alt="item_not_found" />
                            </div>
                        )}
                    </div>
                    {Math.ceil(productsReducer.count / 12) > 1 &&
                        <div className="pagination-container">
                            <Pagination
                                color="var(--color-gray-blue-dark)"
                                onChange={(event) => handlePathPrice(event)}
                                total={Math.ceil(productsReducer.count / 12)}
                                value={activePage}
                            />
                        </div>
                    }
                </div>
                <div className="producto-recomendaciones-seccion">
                    <div>
                        {currency && <CurrencyCard />}
                        <CarsMarketValuesRecommendation/>
                    </div>
                </div>
                <div onClick={() => setOpenModal(!openModal)} className="producto-icono-recomendaciones">$</div>
                <Modal open={openModal} key={uuidv4()}>
                    <Box>
                        <div className="selectedProduct-modal-container">
                            <div className="producto-recomendaciones-seccion-iconos">
                                <img src={iconWhiteCross} onClick={() => setOpenModal(!openModal)} alt="iconos-cruz" />
                            </div>
                            <div className="producto-recomendaciones-seccion-recomendaciones" onClick={(event) => handleCloseRecommendationModal(event)}>
                                {currency && <CurrencyCard />}
                                <CarsMarketValuesRecommendation/>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    );
}