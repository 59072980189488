import { Navigate, Outlet } from "react-router";
import { useSelector } from "react-redux";
import { hasViewPermission } from "../../configuration/permissions/utilities/hasPermissions";

const ProtectedRoutes = ({ resources}) => {
	const user = useSelector((state) => state.auth.user);

	return resources.some(resource => hasViewPermission(resource, user)) ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
