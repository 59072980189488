import {
	ACTION_LOGIN_ERROR,
	ACTION_LOGIN_START,
	ACTION_AUTH_SUCCESS,
	AUTHENTICATION,
	LOG_IN_USER_EXITO,
	LOGOUT_SUCCESS,
	RECUPERAR_CONTRASEÑIA_USER_ERROR,
	MODIFICAR_CREAR_CLAVE_CUENTA_EXITO,
	CONTRASENIA_PROVISORIA,
	RESETEAR_CLAVE_PROVISORIA,
} from "../../types";

const initialState = {
	claveProvisoria: false,
	company: {},
	error: false,
	token: null,
	loading: false,
	message: null,
	user: {},
};

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case ACTION_LOGIN_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case AUTHENTICATION:
			return {
				...state,
				loading: false,
				error: false,
				user: action.payload.collaborator,
				company: action.payload.company,
				defaultProductType: action.payload.defaultProductType
			}
		case LOGOUT_SUCCESS:
			return {
				...state,
				claveProvisoria: false,
				company: {},
				error: false,
				token: null,
				loading: false,
				user: {},
			}
		case RECUPERAR_CONTRASEÑIA_USER_ERROR:
			return {
				...state,
				loading: false,
				error: true,
				message: action.payload.message,
			};
		case CONTRASENIA_PROVISORIA:
			return {
				...state,
				claveProvisoria: true,
				loading: false,
				error: false,
			};
		case ACTION_LOGIN_ERROR:
			return {
				...state,
				loading: false,
				token: null,
				error: true,
				message: action.payload
			};
		case LOG_IN_USER_EXITO:
			return {
				...state,
				company: action.payload.company,
				defaultProductType: action.payload.defaultProductType,
				user: action.payload.user,
				token: action.payload.token,
				message: action.payload.message,
				loading: false,
				error: false,
			};
		case ACTION_AUTH_SUCCESS:
			return {
				...state,
				message: action.payload.message,
				loading: false,
				error: false,
			};
		case MODIFICAR_CREAR_CLAVE_CUENTA_EXITO:
			return {
				...state,
				token: action.payload.token,
				message: action.payload.msj,
				loading: false,
				error: false,
			};
		case RESETEAR_CLAVE_PROVISORIA:
			return {
				...state,
				claveProvisoria: false,
			};
		default:
			return state;
	}
}
