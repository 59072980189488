import {
    GET_PRODUCT_TYPES_SUCCESS,
    ACTION_PRODUCT_TYPES_START,
    ACTION_PRODUCT_TYPES_ERROR,
} from "../../types/index";

const initialState = {
    productTypes: [],
    loading: false,
    error: false,
    message: "",
};

export default function productTypesReducer (state = initialState, action) {
    switch (action.type) {
        case ACTION_PRODUCT_TYPES_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_PRODUCT_TYPES_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                message: "",
                productTypes: action.payload,
            };
        case ACTION_PRODUCT_TYPES_START:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
}
