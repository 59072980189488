import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getProducts, getProductsBrands, getProductsCompanies } from "../../../../redux/actions/productos/productsAction"

import { Accordion, NativeSelect, Switch } from '@mantine/core';

import { hasPermissionByPermissionName } from "../../../configuration/permissions/utilities/hasPermissions";

import "./filtrosItems.css";

export default function Filters({ productIndex, setSelectedPricelist }) {
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const currency = useSelector((state) => state.currency.data);
	const productsReducer = useSelector((state) => state.products);
	const user = useSelector((state) => state.auth.user);
	const priceLists = useSelector((state) => state.priceList.priceList);

	const [supplierCheck, setSupplierCheck] = useState(searchParams.get('isSupplier') !== null ? searchParams.get('isSupplier') === "true" : true);

	const [stockCheck, setStockCheck] = useState(searchParams.get('inStock') !== null ? searchParams.get('inStock') === "true" : true)

	const toggleInStock = () => {
		dispatch(getProducts(productIndex, { ...productsReducer, inStock: !productsReducer.inStock, page: 1 }));
		searchParams.set("inStock", !productsReducer.inStock);
		searchParams.set("page", 1);
		setStockCheck(!stockCheck)
		setSearchParams(searchParams);
	}

	const toggleIsUpdated = () => {
		dispatch(getProducts(productIndex, { ...productsReducer, isUpdated: !productsReducer.isUpdated, page: 1 }));
		searchParams.set("isUpdated", !productsReducer.isUpdated);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const toggleHasPrice = () => {
		dispatch(getProducts(productIndex, { ...productsReducer, hasPrice: !productsReducer.hasPrice, page: 1 }));
		searchParams.set("hasPrice", !productsReducer.hasPrice);
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handlBrandChange = (value) => {
		if (value !== "Todas las marcas") {
			dispatch(getProducts(productIndex, { ...productsReducer, brand: value, page: 1 }));
			dispatch(getProductsCompanies(productIndex, value))
			searchParams.set("brand", value);
		}
		else {
			dispatch(getProducts(productIndex, { ...productsReducer, brand: null, page: 1 }))
			dispatch(getProductsCompanies(productIndex, undefined))
			searchParams.set("brand", null);
		};
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handleCompanyChange = (value) => {
		if (value !== "Todos los actores de mercado") {
			dispatch(getProducts(productIndex, { ...productsReducer, company: value, page: 1 }));
			dispatch(getProductsBrands(productIndex, value))
			searchParams.set("company", value);
		}
		else {
			dispatch(getProducts(productIndex, { ...productsReducer, company: null, page: 1 }));
			dispatch(getProductsBrands(productIndex, undefined))
			searchParams.set("company", null);
		};
		searchParams.set("page", 1);
		setSearchParams(searchParams);
	}

	const handleCurrencyChange = (value) => {
		dispatch(getProducts(productIndex, { ...productsReducer, currency: value }));
	}

	const handleIsSupplierChange = (newIsSupplier) => {
		dispatch(getProducts(productIndex, { ...productsReducer, isSupplier: newIsSupplier, page: 1 }));
		setSupplierCheck(newIsSupplier);
		searchParams.set("page", 1);
		searchParams.set("isSupplier", newIsSupplier);
		setSearchParams(searchParams);
	}

	return (
		<Accordion variant="contained" className="filters-container" defaultValue="filters">
			<Accordion.Item key={1} value="filters">
				<Accordion.Control>FILTROS</Accordion.Control>
				<Accordion.Panel>
					{hasPermissionByPermissionName('products', 'viewPriceDetail', user) &&
						<NativeSelect
							label="Lista de precio seleccionada"
							onChange={(e) => {
								setSelectedPricelist(priceLists.find((priceList) => priceList._id === e.target.value));
							}}
							size="xs"
						>
							{priceLists.map((priceList) => {
								return (
									<option value={priceList._id} key={priceList._id}>
										{priceList.name}
									</option>
								);
							})}
						</NativeSelect>
					}

					<section>
						{currency && <NativeSelect
							size="xs"
							onChange={(e) => { handleCurrencyChange(e.target.value) }}
							value={productsReducer.currency}
							style={{ width: "30%" }}
						>
							<option value={currency.name} key={currency.name}>{currency.name}</option>
							{Object.keys(currency.convertTo).map((item) => {
								return (<option value={item} key={item}>{item}</option>);
							})}
						</NativeSelect>}
						<Switch
							size="lg"
							id="filter-switch-isSupplier"
							color="var(--color-gray-blue)"
							checked={supplierCheck}
							onLabel="Proveedores"
							offLabel="Competencias"
							onChange={(event) => handleIsSupplierChange(event.currentTarget.checked)} />
						<Switch
							size="lg"
							checked={stockCheck}
							onLabel="Con stock"
							offLabel="Sin stock"
							color="var(--color-gray-blue)"
							onClick={toggleInStock}
						/>
					</section>

					<NativeSelect
						label="Marca"
						size="xs"
						onChange={(e) => { handlBrandChange(e.target.value) }}
					>
						<option value={undefined} key={"allBrands"}>Todas las marcas</option>
						{productsReducer.brands.map((brand) => {
							return (<option value={brand._id} key={brand._id} selected={productsReducer.brand === brand._id}>{brand._id} ({brand.count})</option>)
						})}
					</NativeSelect>
					
					{hasPermissionByPermissionName('products', 'viewSupplier', user) &&
						<NativeSelect
							label="Actor de mercado"
							size="xs"
							onChange={(e) => { handleCompanyChange(e.target.value) }}
						>
							<option value={null} key={"allCompanies"}>Todos los actores de mercado</option>
							{productsReducer.companies.map((item) => {
								return (<option value={item._id} key={item._id} selected={productsReducer.company === item._id}>{item._id} ({item.count})</option>);
							})}
						</NativeSelect>
					}

					<Switch
						checked={productsReducer.isUpdated}
						color="var(--color-gray-blue)"
						labelPosition="left"
						label="Solo productos actualizados"
						size="md"
						onClick={toggleIsUpdated}
					/>

					<Switch
						checked={productsReducer.hasPrice}
						color="var(--color-gray-blue)"
						labelPosition="left"
						label="Solo productos con precio"
						size="md"
						onClick={toggleHasPrice}
					/>
				</Accordion.Panel>
			</Accordion.Item>
		</Accordion>
	);
}
