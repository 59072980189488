import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import cx from 'clsx';
import { Image, rem, Text } from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { IconGripVertical } from '@tabler/icons-react';

import { moveNavbarIndex, setNavbarCategoryMessage, updateNavbarCategoryPositions } from "../../../../redux/actions/utilities/navbarAction";

import addIcon from "../../../../assets/svg/functionalities-icons/plus_white.svg";
import iconArrow_gray from "../../../../assets/svg/functionalities-icons/arrow_gray.svg";

import CategoryModal from "./modales/CategoryModal";
import StatusModal from "../../../utilities/Modal/StatusModal";
import SelectIndex from "../../../utilities/SelectIndex";
import { SelectSubcategory } from "../../../utilities/SelectSubcategory";

import "./categories.css";
import { hasCreatePermission, hasEditPermission } from "../../permissions/utilities/hasPermissions";
import { getProductTypes } from "../../../../redux/actions/productos/productTypes";
import SectionAccordion from "../../../utilities/components/SectionAccordion";

const Categories = () => {
	const dispatch = new useDispatch();

	const navbarCategories = useSelector((state) => state.navbar);
	const user = useSelector((state) => state.auth.user);
	const productTypes = useSelector((state) => state.productTypes.productTypes);

	const [openSelectIndex, setOpenSelectIndex] = useState(false)
	const [selectedIndex, setSelectedIndex] = useState({ name: "Indice" });

	const [openSelectSubcategory, setOpenSelectSubcategory] = useState(false)
	const [selectedSubcategory, setSelectedSubcategory] = useState({ name: "Subcategoría" });

	const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
	const [showStatusModal, setShowStatusModal] = useState(false);

	const [existingCategory, setExistingCategory] = useState(null)

	useEffect(() => {
		dispatch(setNavbarCategoryMessage(""))
		dispatch(getProductTypes())
		setShowStatusModal(false)
	}, [dispatch])

	useEffect(() => {
		if (navbarCategories.message !== "") setShowStatusModal(true)
		else setShowStatusModal(false)
	}, [navbarCategories])

	const handleAddNewCategory = () => {
		setExistingCategory(null)
		setShowAddCategoryModal(true);
	};

	const handleCategorySelect = (elementProduct) => {
		setSelectedIndex(elementProduct);
		setOpenSelectIndex(false);
	};

	const handleEditCategory = (category) => {
		if (hasEditPermission('categorization', user)) {
			setExistingCategory(category)
			setShowAddCategoryModal(true);
		}
	}

	const handleMoveIndex = () => {
		setOpenSelectIndex(false);
		setOpenSelectSubcategory(false);

		setSelectedIndex({ name: "Índice" });
		setSelectedSubcategory({ name: "Subcategoría" });

		dispatch(moveNavbarIndex(selectedSubcategory._id, selectedIndex.indexID))
	}

	const handleSubcategorySelect = (elementProduct) => {
		setSelectedSubcategory(elementProduct);
		setOpenSelectSubcategory(false);
	};
	
	const [state, handlers] = useListState(navbarCategories.categories);
	
	useEffect(() => {
		handlers.setState(navbarCategories.categories)
	}, [navbarCategories.categories])

	const items = state.map((item, index) => (
		<Draggable key={item._id} index={index} draggableId={item._id}>
			{(provided, snapshot) => (
				<div
					className={cx("categories-item", { ["categories-item-dragging"]: snapshot.isDragging })}
					ref={provided.innerRef}
					{...provided.draggableProps}
				>
					<div {...provided.dragHandleProps} className="categories-item-dragHandle">
						<IconGripVertical style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
					</div>
					<section onClick={() => handleEditCategory(item)} style={hasEditPermission('categorization', user) ? { cursor: "pointer" } : {}}>
						<div>
							<Image h={25} w={"fit-content"} src={item.images.desktop} className="categories-item-image" alt="cat.img" />
						</div>
						<Text>{item.name}</Text>
					</section>
				</div>
			)}
		</Draggable>
	));

	const handleNavbarPositionsChange = () => {
		dispatch(updateNavbarCategoryPositions(state.map(({ _id, position }) => ({ _id, position }))))
	}

	return (
		<div>
			<SectionAccordion title="Estructura de navbar" openClass="categories-container">
				<div>
					{hasCreatePermission('categorization', user) &&
						<div className="categories-item-add" type="button" onClick={handleAddNewCategory}>
							<img alt="new" src={addIcon} />
						</div>
					}
					{hasEditPermission('categorization', user) &&
						navbarCategories.categories !== state && <button onClick={handleNavbarPositionsChange}>Aplicar</button>
					}
				</div>

				<DragDropContext onDragEnd={({ destination, source }) => handlers.reorder({ from: source.index, to: destination?.index || 0 })} >
					<Droppable droppableId="dnd-list" direction="vertical">
						{(provided) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{items}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</SectionAccordion>

			{hasEditPermission('categorization', user) &&
				<>
					<SectionAccordion title="Reestructuración de categorías" openClass="category-moveIndex-container">
						<div>
							<span
								className="category-moveIndex-selectIndex"
								onClick={() => {
									setOpenSelectIndex(!openSelectIndex);
									setOpenSelectSubcategory(false);
									setSelectedSubcategory({ name: "Subcategoría" })
								}}
							>
								{selectedIndex.name}
								<img src={iconArrow_gray} style={!openSelectIndex ? { rotate: "180deg" } : {}} alt="open" />
							</span>
							<SelectIndex openSelectIndex={openSelectIndex} handleCategorySelect={handleCategorySelect} othersCategory={false} />
						</div>
						<div>
							<span
								className="category-moveIndex-selectIndex"
								onClick={() => {
									setOpenSelectSubcategory(!openSelectSubcategory);
									setOpenSelectIndex(false);
								}}
							>
								{selectedSubcategory.name}
								<img src={iconArrow_gray} style={!openSelectSubcategory ? { rotate: "180deg" } : {}} alt="open" />
							</span>
							<SelectSubcategory openSelectSubcategory={openSelectSubcategory} handleSubcategorySelect={handleSubcategorySelect} subcategoryExcept={selectedIndex.indexID} />
						</div>
						<button disabled={!selectedIndex.tipoProducto || !selectedSubcategory._id} onClick={handleMoveIndex}>Mover índice</button>
					</SectionAccordion>
				</>
			}
			{showAddCategoryModal && <CategoryModal setShowAddCategoryModal={setShowAddCategoryModal} existingCategory={existingCategory} productTypes={productTypes} />}
			{showStatusModal && (
				<StatusModal loading={navbarCategories.loading} error={navbarCategories.error} setModalStatusCloseAction={setShowStatusModal}>
					{navbarCategories.message}
				</StatusModal>
			)}

		</div>
	);
};

export default Categories;
