import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { SelectCreatable } from '../../utilities/components/SelectCreatable'

import { deleteEntityLabel, getEntityLabels, getLabelings } from '../../../redux/actions/utilities/labelingAction';

import crossIcon from './../../../assets/svg/functionalities-icons/cross_white.svg'

import './entityLabels.css'
import ConfirmationModal from '../../utilities/Modal/ConfirmationModal';
import StatusModal from '../../utilities/Modal/StatusModal';
import { hasCreatePermission, hasEditPermission } from '../../configuration/permissions/utilities/hasPermissions';

const EntityLabels = ({entityID, type}) => {
  const dispatch = useDispatch();

  const labeling = useSelector((state) => state.labeling);
	const user = useSelector((state) => state.auth.user)

  const [showStatusModal, setShowStatusModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  useEffect(() => {
    dispatch(getLabelings())
    dispatch(getEntityLabels(entityID, type))
  }, [])

  useEffect(() => {
    if(!showStatusModal){
      dispatch(getLabelings())
      dispatch(getEntityLabels(entityID, type))
    }
  }, [showStatusModal])

  const handleRemoveLabel = () => {
    setShowStatusModal(true)
    setShowConfirmationModal(false)
    dispatch(deleteEntityLabel(entityID, showConfirmationModal._id, type))
  }
  
  return (
    <div className='entityLabels-container'>
        <SelectCreatable collection={labeling.labelingList || []} entityID={entityID} type={type} setShowStatusModal={setShowStatusModal}/>
        
        {labeling.entityLabels.map((element) => {
          return (
            <div className='entityLabels-label'>
              <h4>{element.name}</h4>
              {hasEditPermission(type, user) && <img src={crossIcon} alt='delete-icon' onClick={() => setShowConfirmationModal(element)}/>}
            </div>
          )
        })}

        {showConfirmationModal && <ConfirmationModal
            setShowConfirmationModal={setShowConfirmationModal}
            handlerShowConfirmationModal={handleRemoveLabel}
            titleText="Eliminar etiqueta"
            descriptionText={`¿Está seguro que desea eliminar la etiqueta "${showConfirmationModal.name}"?`}
        />}

        {showStatusModal && (
            <StatusModal loading={labeling.loading} error={labeling.error} setModalStatusCloseAction={setShowStatusModal}>
                {labeling.loading
                    ? "Procesando..."
                    : labeling.message}
            </StatusModal>
        )}
    </div>
  )
}

export default EntityLabels