import {
	CLEAR_ENTITY_LABELS_SUCCESS,
	GET_ENTITY_LABELS_SUCCESS,
	GET_LABELING_ERROR,
	GET_LABELING_SUCCESS,
	SET_LABELING_MESSAGE,
	START_GET_LABELING,
} from "../../types/index";

const initialState = {
	labelingList: [],
	loading: false,
	entityLabels: [],
	error: false,
	message: "",
};

export default function LabelingReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ENTITY_LABELS_SUCCESS:
			return {
				...state,
				entityLabels: [],
				error: false,
				loading: false,
			};
		case GET_ENTITY_LABELS_SUCCESS:
			return {
				...state,
				error: null,
				loading: false,
				message: "",
				entityLabels: action.payload,
			};
		case GET_LABELING_ERROR:
			return {
				...state,
				error: true,
				loading: false,
				message: action.payload,
			};
		case GET_LABELING_SUCCESS:
			return {
				...state,
				error: null,
				loading: false,
				message: "",
				labelingList: action.payload,
			};
		case SET_LABELING_MESSAGE:
			return {
				...state,
				message: action.payload,
			}
		case START_GET_LABELING:
			return {
				...state,
				loading: true,
			};
		default:
			return state;
	}
}
