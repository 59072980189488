import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { getCurrency } from '../../../redux/actions/utilities/currencyAction';

import SectionAccordion from '../../utilities/components/SectionAccordion'
import { CurrencyCard } from '../../recommendations/currencyCard/CurrencyCard';

import { NativeSelect, ScrollArea, Table } from "@mantine/core";
import { YearPickerInput } from '@mantine/dates';
import { Modal, Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import 'dayjs/locale/es';

import iconWhiteCross from "../../../assets/svg/functionalities-icons/cross_white.svg";
import arrowCloseBlue from "../../../assets/svg/functionalities-icons/arrow-close_blue.svg";

import carsInfoACARA from './../../utilities/carsPrices/cars_info_acara.json'
import carsInfoCCA from './../../utilities/carsPrices/cars_info_cca.json'
import carsInfoInfoAutos from './../../utilities/carsPrices/cars_info_infoAutos.json'

import './carsMarketValue.css'

const CarsMarketValue = () => {
    const dispatch = useDispatch();

    document.title = "Scope | Valores de mercado"

    const currency = useSelector((state) => state.currency.data);

    const [brand, setBrand] = useState(null);
    const [model, setModel] = useState(null);
    const [modelList, setModelList] = useState([]);

    const [carACARA, setCarsACARA] = useState(null)
    const [carCCA, setCarsCCA] = useState(null)
    const [carInfoAutos, setCarsInfoAutos] = useState(null)

    const [minYear, setMinYear] = useState(null);
    const [maxYear, setMaxYear] = useState(null);

    const [openModal, setOpenModal] = useState(false)

    const [openACARA, setOpenACARA] = useState(true)
    const [openCCA, setOpenCCA] = useState(true)
    const [openInfoAutos, setOpenInfoAutos] = useState(true)

    useEffect(() => {
        if (brand && model) {

            let rewindCarsACARA = carsInfoACARA[brand]?.modelos[model]?.versiones ? Object.values(carsInfoACARA[brand]?.modelos[model]?.versiones) : null
            let rewindCarsCCA = carsInfoCCA[brand]?.modelos[model]?.versiones ? Object.values(carsInfoCCA[brand]?.modelos[model]?.versiones) : null
            let rewindCarsInfoAutos = carsInfoInfoAutos[brand]?.modelos[model]?.versiones ? Object.values(carsInfoInfoAutos[brand]?.modelos[model]?.versiones) : null

            let filteredCarsACRA = rewindCarsACARA?.filter(version =>
                Object.keys(version.precios).some(year =>
                    (!minYear || year >= minYear.getFullYear()) &&
                    (!maxYear || year <= maxYear.getFullYear()) &&
                    version.precios[year]?.valor
                )
            );

            let filteredCarsCCA = rewindCarsCCA?.filter(version =>
                Object.keys(version.precios).some(year =>
                    (!minYear || year >= minYear.getFullYear()) &&
                    (!maxYear || year <= maxYear.getFullYear()) &&
                    version.precios[year]?.valor
                )
            );

            let filteredCarsInfoAutos = rewindCarsInfoAutos?.filter(version =>
                Object.keys(version.precios).some(year =>
                    (!minYear || year >= minYear.getFullYear()) &&
                    (!maxYear || year <= maxYear.getFullYear()) &&
                    version.precios[year]?.valor
                )
            );

            setCarsACARA(filteredCarsACRA)
            setCarsCCA(filteredCarsCCA)
            setCarsInfoAutos(filteredCarsInfoAutos)
        }
    }, [minYear, maxYear])

    useEffect(() => {
        dispatch(getCurrency());
    }, [])

    useEffect(() => {
        if (brand) {
            setModel(null)
            setModelList(Object.keys(carsInfoACARA[brand]?.modelos))
        }
    }, [brand])

    useEffect(() => {
        if (model) {
            setMinYear(null)
            setMaxYear(null)
            setCarsACARA(carsInfoACARA[brand]?.modelos[model]?.versiones ? Object.values(carsInfoACARA[brand]?.modelos[model]?.versiones) : null)
            setCarsCCA(carsInfoCCA[brand]?.modelos[model]?.versiones ? Object.values(carsInfoCCA[brand]?.modelos[model]?.versiones) : null)
            setCarsInfoAutos(carsInfoInfoAutos[brand]?.modelos[model]?.versiones ? Object.values(carsInfoInfoAutos[brand]?.modelos[model]?.versiones) : null)
        }
    }, [model])

    const brandList = Object.keys(carsInfoACARA);

    const formatNumber = (value) => {
        if (value) return new Intl.NumberFormat("de-DE").format((value * 1));
        else return null
    };

    const handleCloseRecommendationModal = (event) => {
        if (event.target.className === 'producto-recomendaciones-seccion-recomendaciones') {
            setOpenModal(false)
        }
    }

    return (
        <div className="cuentas-contenedor carsMarketValue-container">
            <SectionAccordion title="Valores de mercado del automotor">
                <div className='carsMarketValue-filters-container'>
                    <div>
                        <NativeSelect size="xs" id="brand" label="Marca" onChange={(e) => { setBrand(e.target.value) }}>
                            <option hidden>Seleccionar</option>
                            {brandList.length && brandList.map((brand) => {
                                return (
                                    <option value={brand} key={brand} style={{ textTransform: 'capitalize' }} >
                                        {brand}
                                    </option>
                                );
                            })}
                            <option value="otro">Otro</option>
                        </NativeSelect>
                        <NativeSelect size="xs" id="brand" label="Modelo" disabled={!brand} onChange={(e) => { setModel(e.target.value) }}>
                            <option hidden>Seleccionar</option>
                            {modelList.length && modelList.map((model) => {
                                return (
                                    <option value={model} key={model} style={{ textTransform: 'capitalize' }} >
                                        {model}
                                    </option>
                                );
                            })}
                        </NativeSelect>
                    </div>
                    {brand && model && <div>
                        <YearPickerInput
                            label="Año minimo"
                            clearable
                            value={minYear}
                            onChange={(value) => setMinYear(value)}
                            placeholder="Seleccionar"
                            size="xs"
                            minDate={new Date(2010, 0, 1)}
                            maxDate={maxYear ? maxYear : new Date()}
                        />
                        <YearPickerInput
                            label="Año maximo"
                            clearable
                            value={maxYear}
                            placeholder="Seleccionar"
                            onChange={(value) => setMaxYear(value)}
                            size="xs"
                            minDate={minYear}
                            maxDate={new Date()}
                        />
                    </div>}
                </div>

                {brand && model && <div className='carsMarketValue-company-container'>
                    <span onClick={() => setOpenACARA(!openACARA)}>
                        ACARA
                        <img src={arrowCloseBlue} alt='arrowCloseBlue' style={openACARA ? { rotate: "180deg" } : {}} />
                    </span>
                    {openACARA && <h5>Información correspondiente a Marzo 2024</h5>}
                </div>}
                {brand && model && openACARA && <ScrollArea scrollbars="x">
                    <Table
                        striped
                        highlightOnHover
                        withTableBorder
                        withColumnBorders
                        maw="100%"
                        borderColor="var(--color-gray-dark)"
                        highlightOnHoverColor="var(--color-gray-blue-ligther)"
                    >
                        <Table.Thead
                            bg="#3c4d66"
                            c="#ffffff"
                        >
                            <Table.Tr>
                                <Table.Th>{brand.toUpperCase()} - {model.toUpperCase()}</Table.Th>
                                <Table.Th>0km</Table.Th>
                                <Table.Th>2024</Table.Th>
                                <Table.Th>2023</Table.Th>
                                <Table.Th>2022</Table.Th>
                                <Table.Th>2021</Table.Th>
                                <Table.Th>2020</Table.Th>
                                <Table.Th>2019</Table.Th>
                                <Table.Th>2018</Table.Th>
                                <Table.Th>2017</Table.Th>
                                <Table.Th>2016</Table.Th>
                                <Table.Th>2015</Table.Th>
                                <Table.Th>2014</Table.Th>
                                <Table.Th>2013</Table.Th>
                                <Table.Th>2012</Table.Th>
                                <Table.Th>2011</Table.Th>
                                <Table.Th>2010</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {carACARA && carACARA.map((version, index) => {
                                return (
                                    <Table.Tr key={index}>
                                        <Table.Td>{version.nombre}</Table.Td>
                                        <Table.Td>{version.precios["0km"]?.moneda} {formatNumber(version.precios["0km"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2024"]?.moneda} {formatNumber(version.precios["2024"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2023"]?.moneda} {formatNumber(version.precios["2023"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2022"]?.moneda} {formatNumber(version.precios["2022"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2021"]?.moneda} {formatNumber(version.precios["2021"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2020"]?.moneda} {formatNumber(version.precios["2020"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2019"]?.moneda} {formatNumber(version.precios["2019"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2018"]?.moneda} {formatNumber(version.precios["2018"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2017"]?.moneda} {formatNumber(version.precios["2017"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2016"]?.moneda} {formatNumber(version.precios["2016"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2015"]?.moneda} {formatNumber(version.precios["2015"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2014"]?.moneda} {formatNumber(version.precios["2014"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2013"]?.moneda} {formatNumber(version.precios["2013"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2012"]?.moneda} {formatNumber(version.precios["2012"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2011"]?.moneda} {formatNumber(version.precios["2011"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2010"]?.moneda} {formatNumber(version.precios["2010"]?.valor)}</Table.Td>
                                    </Table.Tr>
                                )
                            })}
                            {carACARA && carACARA.length === 0 && (
                                <div className='carsMarketValue-noInfo-container'>
                                    <h6>¡No existe Información correspondiente a esta busqueda!</h6>
                                </div>
                            )}
                        </Table.Tbody>
                    </Table>
                </ScrollArea>}

                {brand && model && carCCA && <div className='carsMarketValue-company-container'>
                    <span onClick={() => setOpenCCA(!openCCA)}>
                        CCA
                        <img src={arrowCloseBlue} alt='arrowCloseBlue' style={openCCA ? { rotate: "180deg" } : {}} />
                    </span>
                    {openCCA && <h5>Información correspondiente a Marzo 2024</h5>}
                </div>}
                {brand && model && openCCA && carCCA && <ScrollArea scrollbars="x">
                    <Table
                        striped
                        highlightOnHover
                        withTableBorder
                        withColumnBorders
                        maw="100%"
                        borderColor="var(--color-gray-dark)"
                        highlightOnHoverColor="var(--color-gray-blue-ligther)"
                    >
                        <Table.Thead
                            bg="#3c4d66"
                            c="#ffffff"
                        >
                            <Table.Tr>
                                <Table.Th>{brand.toUpperCase()} - {model.toUpperCase()}</Table.Th>
                                <Table.Th>0km</Table.Th>
                                <Table.Th>2024</Table.Th>
                                <Table.Th>2023</Table.Th>
                                <Table.Th>2022</Table.Th>
                                <Table.Th>2021</Table.Th>
                                <Table.Th>2020</Table.Th>
                                <Table.Th>2019</Table.Th>
                                <Table.Th>2018</Table.Th>
                                <Table.Th>2017</Table.Th>
                                <Table.Th>2016</Table.Th>
                                <Table.Th>2015</Table.Th>
                                <Table.Th>2014</Table.Th>
                                <Table.Th>2013</Table.Th>
                                <Table.Th>2012</Table.Th>
                                <Table.Th>2011</Table.Th>
                                <Table.Th>2010</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {carCCA && carCCA.map((version, index) => {
                                return (
                                    <Table.Tr key={index}>
                                        <Table.Td>{version.nombre}</Table.Td>
                                        <Table.Td>{version.precios["0km"]?.moneda} {formatNumber(version.precios["0km"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2024"]?.moneda} {formatNumber(version.precios["2024"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2023"]?.moneda} {formatNumber(version.precios["2023"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2022"]?.moneda} {formatNumber(version.precios["2022"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2021"]?.moneda} {formatNumber(version.precios["2021"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2020"]?.moneda} {formatNumber(version.precios["2020"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2019"]?.moneda} {formatNumber(version.precios["2019"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2018"]?.moneda} {formatNumber(version.precios["2018"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2017"]?.moneda} {formatNumber(version.precios["2017"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2016"]?.moneda} {formatNumber(version.precios["2016"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2015"]?.moneda} {formatNumber(version.precios["2015"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2014"]?.moneda} {formatNumber(version.precios["2014"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2013"]?.moneda} {formatNumber(version.precios["2013"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2012"]?.moneda} {formatNumber(version.precios["2012"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2011"]?.moneda} {formatNumber(version.precios["2011"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2010"]?.moneda} {formatNumber(version.precios["2010"]?.valor)}</Table.Td>
                                    </Table.Tr>
                                )
                            })}
                            {carCCA && carCCA.length === 0 && (
                                <div className='carsMarketValue-noInfo-container'>
                                    <h6>¡No existe Información correspondiente a esta busqueda!</h6>
                                </div>
                            )}
                        </Table.Tbody>
                    </Table>
                </ScrollArea>}

                {brand && model && carInfoAutos  &&  <div className='carsMarketValue-company-container'>
                    <span onClick={() => setOpenInfoAutos(!openInfoAutos)}>
                        InfoAutos
                        <img src={arrowCloseBlue} alt='arrowCloseBlue' style={openInfoAutos ? { rotate: "180deg" } : {}} />
                    </span>
                    {openInfoAutos && <h5>Información correspondiente a Marzo 2024</h5>}
                </div>}
                {brand && model && openInfoAutos && carInfoAutos && <ScrollArea scrollbars="x">
                    <Table
                        striped
                        highlightOnHover
                        withTableBorder
                        withColumnBorders
                        maw="100%"
                        borderColor="var(--color-gray-dark)"
                        highlightOnHoverColor="var(--color-gray-blue-ligther)"
                    >
                        <Table.Thead
                            bg="#3c4d66"
                            c="#ffffff"
                        >
                            <Table.Tr>
                                <Table.Th>{brand.toUpperCase()} - {model.toUpperCase()}</Table.Th>
                                <Table.Th>0km</Table.Th>
                                <Table.Th>2024</Table.Th>
                                <Table.Th>2023</Table.Th>
                                <Table.Th>2022</Table.Th>
                                <Table.Th>2021</Table.Th>
                                <Table.Th>2020</Table.Th>
                                <Table.Th>2019</Table.Th>
                                <Table.Th>2018</Table.Th>
                                <Table.Th>2017</Table.Th>
                                <Table.Th>2016</Table.Th>
                                <Table.Th>2015</Table.Th>
                                <Table.Th>2014</Table.Th>
                                <Table.Th>2013</Table.Th>
                                <Table.Th>2012</Table.Th>
                                <Table.Th>2011</Table.Th>
                                <Table.Th>2010</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {carInfoAutos && carInfoAutos.map(version => {
                                return (
                                    <Table.Tr>
                                        <Table.Td>{version.nombre}</Table.Td>
                                        <Table.Td>{version.precios["0km"]?.moneda} {formatNumber(version.precios["0km"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2024"]?.moneda} {formatNumber(version.precios["2024"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2023"]?.moneda} {formatNumber(version.precios["2023"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2022"]?.moneda} {formatNumber(version.precios["2022"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2021"]?.moneda} {formatNumber(version.precios["2021"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2020"]?.moneda} {formatNumber(version.precios["2020"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2019"]?.moneda} {formatNumber(version.precios["2019"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2018"]?.moneda} {formatNumber(version.precios["2018"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2017"]?.moneda} {formatNumber(version.precios["2017"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2016"]?.moneda} {formatNumber(version.precios["2016"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2015"]?.moneda} {formatNumber(version.precios["2015"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2014"]?.moneda} {formatNumber(version.precios["2014"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2013"]?.moneda} {formatNumber(version.precios["2013"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2012"]?.moneda} {formatNumber(version.precios["2012"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2011"]?.moneda} {formatNumber(version.precios["2011"]?.valor)}</Table.Td>
                                        <Table.Td>{version.precios["2010"]?.moneda} {formatNumber(version.precios["2010"]?.valor)}</Table.Td>
                                    </Table.Tr>
                                )
                            })}
                            {carInfoAutos && carInfoAutos.length === 0 && (
                                <div className='carsMarketValue-noInfo-container'>
                                    <h6>¡No existe Información correspondiente a esta busqueda!</h6>
                                </div>
                            )}
                        </Table.Tbody>
                    </Table>
                </ScrollArea>}
            </SectionAccordion>

            <div onClick={() => setOpenModal(!openModal)} className="producto-icono-recomendaciones" style={{ display: "block", marginRight: "50px" }}>$</div>
            <Modal open={openModal} key={uuidv4()}>
                <Box>
                    <div className="selectedProduct-modal-container">
                        <div className="producto-recomendaciones-seccion-iconos">
                            <img src={iconWhiteCross} onClick={() => setOpenModal(!openModal)} alt="iconos-cruz" />
                        </div>
                        <div className="producto-recomendaciones-seccion-recomendaciones" onClick={(event) => handleCloseRecommendationModal(event)}>
                            {currency && <CurrencyCard />}
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default CarsMarketValue