import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import addIcon from "../../../assets/svg/functionalities-icons/plus_white.svg";

import ConfirmationModal from "../../utilities/Modal/ConfirmationModal";
import StatusModal from "../../utilities/Modal/StatusModal";
import { hasCreatePermission } from "./utilities/hasPermissions";
import FormPermissions from "./FormPermissions";

import { getResources } from "../../../redux/actions/utilities/resourceAction";
import { deleteRole, getRoles, postRole, updateRole } from "../../../redux/actions/utilities/roleAction";
import { authUser } from "../../../redux/actions/utilities/authAction";

import "./permissions.css"
import SectionAccordion from "../../utilities/components/SectionAccordion";

const Permissions = () => {
    const dispatch = new useDispatch();

    useEffect(() => {
        dispatch(getResources());
        dispatch(getRoles());
    }, [dispatch])

    const resources = useSelector((state) => state.resource.resources);
    const roles = useSelector((state) => state.role);
    const user = useSelector((state) => state.auth.user);

    const [closeSection, setCloseSection] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [showConfirmationDeleteModal, setShowConfirmationDeleteModal] = useState(null);
    const [showConfirmationEditModal, setShowConfirmationEditModal] = useState(null);
    const [showStatusModal, setShowStatusModal] = useState(null);

    document.title = "Scope | Roles y permisos"

    function parseResources(resources) {
        //Transformo los recursos a la esctructura que toman en los permisos para usarlos en el form
        const resourceDict = {};

        resources.forEach(resource => {
            resourceDict[resource.name] = {
                label: resource.label,
                actions: resource.actions.reduce((actions, action) => {
                    actions[action.name] = {
                        label: action.label,
                        description: action.description,
                        value: false,
                    };
                    return actions;
                }, {})
            }
        });

        return resourceDict;
    }

    const handleAddRole = () => {
        setSelectedRole({
            _id: null,
            name: '',
            label: '',
            permissions: parseResources(resources)
        })
    }

    const handleSubmit = async (values) => {
        if (selectedRole._id) {
            // Si hay id es porque estoy editando
            setShowStatusModal(true);
            await dispatch(updateRole(values.name, values.permissions, values._id));
            dispatch(authUser());
            setShowConfirmationEditModal(false);
            setSelectedRole(values);
        } else {
            // Si NO hay id es porque estoy creando
            setShowStatusModal(true);
            await dispatch(postRole(values.name, values.permissions));
            setSelectedRole(null);
        }
    }

    const handleSelectRole = (role) => {
        setSelectedRole(() => {
            // Construyo el rol con sus datos y la estructura de todos los permisos
            const newSelectedRole = {
                ...role,
                permissions: {
                    ...parseResources(resources),
                }
            }

            // Cargo los valores actuales de cada acción en cada recurso del permiso
            resources.map(res => {
                if (role.permissions[res.name]) {
                    return Object.keys(role.permissions[res.name].actions).forEach(action => {
                        newSelectedRole.permissions[res.name].actions[action] = role.permissions[res.name].actions[action];
                    })
                }
            })

            return newSelectedRole;
        })
    }

    const handleDeleteRole = async () => {
        setShowStatusModal(true);
        await dispatch(deleteRole(selectedRole._id));

        setShowConfirmationDeleteModal(false);
        setSelectedRole(null);
    }

    const handleCloseModal = (status) => {
        setShowStatusModal(false);
        dispatch(getRoles());
    }

    return (
        <div className="cuentas-contenedor">
            <SectionAccordion title="Roles y permisos" openClass="permissions-container">
                <section>
                    <div className={`roles-container${closeSection ? " d-none" : ""}`}>
                        {hasCreatePermission('permissions', user) &&
                            <div className="first" type="button" onClick={handleAddRole}>
                                <img alt="new" src={addIcon} />
                            </div>
                        }
                        {roles.roles.map(role => {
                            return (
                                <div type="button" onClick={() => handleSelectRole(role)} key={role._id}>
                                    <h4>{role.name}</h4>
                                </div>
                            )
                        })}
                    </div>
                </section>
                <section className="permissions-form-container">
                    {selectedRole ?
                        <FormPermissions
                            values={selectedRole}
                            handleSubmit={handleSubmit}
                            handleDelete={handleDeleteRole}
                            setShowConfirmationDeleteModal={setShowConfirmationDeleteModal}
                            existingRolesNames={roles.roles.map(rol => rol.name.toLowerCase())}
                            setSelectedRole={setSelectedRole}
                        /> :
                        <h4>Seleccione un rol o cree uno nuevo...</h4>
                    }
                </section>
            </SectionAccordion>

            {showConfirmationDeleteModal &&
                <ConfirmationModal
                    descriptionText={`¿Está seguro que quiere eliminar el rol "${selectedRole?.name}"?`}
                    handlerShowConfirmationModal={handleDeleteRole}
                    setShowConfirmationModal={setShowConfirmationDeleteModal}
                    titleText={`Eliminar rol`}
                />
            }
            {showConfirmationEditModal &&
                <ConfirmationModal
                    descriptionText={`¿Está seguro que quiere editar el rol "${selectedRole?.name}"?`}
                    handlerShowConfirmationModal={handleSubmit}
                    setShowConfirmationModal={showConfirmationEditModal}
                    titleText={`Editar rol`}
                />
            }
            {showStatusModal && (
                <StatusModal loading={roles.loading} error={roles.error} setModalStatusCloseAction={handleCloseModal}>
                    {roles.message}
                </StatusModal>
            )}
        </div>
    );
};

export default Permissions;
