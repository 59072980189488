import React, { useState } from 'react'

import iconArrow_gray from "../../../assets/svg/functionalities-icons/arrow_gray.svg";

import './utilitiesComponents.css'

const SectionAccordion = ({ children, title, openClass }) => {

    const [closeSection, setCloseSection] = useState(true)

    return (
        <div>
            <div className="section-accordion-row" onClick={() => { setCloseSection(!closeSection) }}>
                <h2>{title}</h2>
                <img alt="arrow" src={iconArrow_gray} style={!closeSection ? { rotate: "180deg" } : {}} />
            </div>
            <hr />
            <div className={closeSection ? openClass ? openClass : "" : "d-none"} >
                {children}
            </div>
        </div>
    )
}

export default SectionAccordion