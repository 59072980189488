import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

import App from "./routes/App";
import store from "./redux/store";

import "./index.css";
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';

const root = createRoot(document.getElementById("root"));

root.render(
	<Provider store={store}>
		<MantineProvider 
			theme={{
				colors: {
					'my-custom-color': ['#f8f9fa', '#f1f3f5', '#e9ecef', '#dee2e6', '#ced4da', '#adb5bd', '#3c4d66', '#343a40', '#212529', '#0b0b0b'],
				},
				primaryColor: 'my-custom-color',
				primaryShade: 6,
			}}
			withGlobalStyles
			withNormalizeCSS
		>
			<Notifications />
			<App />
		</MantineProvider>
	</Provider>,
);