import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Grid, Group, Pagination, ScrollArea, Select, Table, Text } from "@mantine/core";
import { DateTimePicker } from '@mantine/dates';

import dayjs from "dayjs";
import 'dayjs/locale/es';

import { getActivityLogs, getFilteredActivityLogs } from '../../redux/actions/utilities/activityLogAction';
import { getRoles } from "../../redux/actions/utilities/roleAction";
import { getResources } from "../../redux/actions/utilities/resourceAction";
import { getCollaborators } from "../../redux/actions/entities/collaboratorAction";
import { hasPermissionByPermissionName } from "../configuration/permissions/utilities/hasPermissions";
import SectionAccordion from "../utilities/components/SectionAccordion";

const ActivityLogs = () => {
    const dispatch = useDispatch();

    document.title = "Scope | Actividades"

    const activityLogs = useSelector(state => state.activityLog.logs);
    const activityLogsTotalCount = useSelector(state => state.activityLog.totalCount);
    const roles = useSelector(state => state.role.roles);
    const sections = useSelector(state => state.resource.resources);
    const collaborators = useSelector(state => state.collaborators.collaborators);
    const user = useSelector((state) => state.auth.user)

    const [roleSelected, setRoleSelected] = useState(null);
    const [sectionSelected, setSectionSelected] = useState(null);
    const [collaboratorSelected, setCollaboratorSelected] = useState(null);

    const [initialDate, setInitialDate] = useState(null);
    const [finalDate, setFinalDate] = useState(null);

    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
        dispatch(getRoles());
        dispatch(getResources());
        dispatch(getCollaborators())
        dispatch(getActivityLogs());
    }, [dispatch])

    const handleFilter = () => {
        dispatch(getFilteredActivityLogs(roleSelected, sectionSelected, collaboratorSelected, initialDate, finalDate, 1));
        setActivePage(1);
    }

    const handlePageChange = (page) => {
        dispatch(getFilteredActivityLogs(roleSelected, sectionSelected, collaboratorSelected, initialDate, finalDate, page));
        setActivePage(page);
    }

    const handleSetInitialDateTime = (value) => {
        setInitialDate(value);
        setFinalDate(null)
    }

    const roleOptions = roles?.map(role => role.name);
    const sectionOptions = sections ? ["Autenticación", ...sections.map(section => section.label)] : [];
    const collaboratorsOptions = collaborators.length > 0 && collaborators?.map(collaborator => `${collaborator.info.name} ${collaborator.info.lastName}`);

    return (
        <div className="cuentas-contenedor">
            <SectionAccordion title="Registro de actividad">
                {roles?.length > 0 && sections?.length > 0 &&
                    <Grid my="md">
                        <Grid.Col span={{ base: 12, sm: 6, md: 6, lg: 2 }}>
                            <DateTimePicker
                                size="xs"
                                locale="es"
                                placeholder="Fecha y hora inicial"
                                value={initialDate}
                                onChange={(val) => handleSetInitialDateTime(val)}
                                clearable
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, sm: 6, md: 6, lg: 2 }}>
                            <DateTimePicker
                                size="xs"
                                locale="es"
                                placeholder="Fecha y hora final"
                                disabled={!initialDate}
                                value={finalDate}
                                onChange={setFinalDate}
                                excludeDate={date => dayjs(date).isBefore(dayjs(initialDate).add(1, 'minute').subtract(1, 'day'))}
                                clearable
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, sm: 6, md: 6, lg: 2 }}>
                            <Select size="xs" placeholder="Filtro por rol" data={roles ? roleOptions : []} value={roleSelected} onChange={setRoleSelected} />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, sm: 6, md: 6, lg: 3 }}>
                            <Select size="xs" placeholder="Filtro por sección" data={sections ? sectionOptions : []} value={sectionSelected} onChange={setSectionSelected} />
                        </Grid.Col>
                        <Grid.Col span={{ base: 8, sm: 9, md: 9, lg: 2 }}>
                            <Select size="xs" placeholder="Filtro por colaborador" data={collaborators ? collaboratorsOptions : []} value={collaboratorSelected} onChange={setCollaboratorSelected} />
                        </Grid.Col>
                        <Grid.Col span={{ base: 4, sm: 3, md: 3, lg: 1 }}>
                            <Button size="xs" w="100%" onClick={handleFilter}>Filtrar</Button>
                        </Grid.Col>
                    </Grid>
                }
                <Group gap={2}>
                    <Text ml="auto" size="xs">Total de registros:</Text>
                    <Text mr={4} size="xs" fw={600}>{activityLogsTotalCount || 0}</Text>
                </Group>
                <ScrollArea scrollbars="x">
                    <Table
                        striped
                        highlightOnHover
                        withTableBorder
                        withColumnBorders
                        maw="100%"
                        borderColor="var(--color-gray-dark)"
                        highlightOnHoverColor="var(--color-gray-blue-ligther)"
                    >
                        <Table.Thead
                            bg="#3c4d66"
                            c="#ffffff"
                        >
                            <Table.Th>Colaborador</Table.Th>
                            <Table.Th>Rol</Table.Th>
                            <Table.Th>Fecha y hora</Table.Th>
                            <Table.Th>Sección</Table.Th>
                            <Table.Th>Descripción</Table.Th>
                        </Table.Thead>
                        <Table.Tbody>
                            {activityLogs.length > 0 && activityLogs.map(log => {
                                return (
                                    <Table.Tr>
                                        <Table.Td>{log.collaborator.info.name} {log.collaborator.info.lastName}</Table.Td>
                                        <Table.Td>{log.role.name}</Table.Td>
                                        <Table.Td>{new Date(log.timestamp).toLocaleDateString()} {new Date(log.timestamp).toLocaleTimeString('es-ES')}</Table.Td>
                                        <Table.Td>{log.section.label}</Table.Td>
                                        <Table.Td maw={200}>{hasPermissionByPermissionName('activityLogs', 'view_detail', user) ? log.detailedDescription : log.description}</Table.Td>
                                    </Table.Tr>
                                )
                            })
                            }
                        </Table.Tbody>
                    </Table>
                </ScrollArea>
                {activityLogs.length === 0 &&
                    <Text mt="md" c="dimmed" align="center">No existen registros de actividad con esas condiciones.</Text>
                }
                {activityLogsTotalCount > 0 &&
                    <div className="pagination-container">
                        <Pagination
                            color="var(--color-gray-blue-dark)"
                            onChange={(value) => handlePageChange(value)}
                            total={Math.ceil(activityLogsTotalCount / 10)}
                            value={activePage}
                        />
                    </div>
                }
            </SectionAccordion>
        </div>
    )
}

export default ActivityLogs;