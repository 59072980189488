import {
	GET_ACTIVITY_LOGS_ERROR,
	GET_ACTIVITY_LOGS_SUCCESS,
	SET_ACTIVITY_LOGS_MESSAGE,
	START_GET_ACTIVITY_LOGS,
} from "../../types/index";

const initialState = {
	logs: [],
	totalCount: null,
	loading: false,
	error: false,
	message: "",
};

export default function activityLogReducer (state = initialState, action) {
	switch (action.type) {
		case GET_ACTIVITY_LOGS_ERROR:
			return {
				...state,
				error: true,
				loading: false,
			};
		case GET_ACTIVITY_LOGS_SUCCESS:
			return {
				...state,
				error: null,
				loading: false,
				message: "",
				logs: action.payload.data,
				totalCount: action.payload.metadata[0]?.totalCount
			};
		case SET_ACTIVITY_LOGS_MESSAGE:
			return {
				...state,
				message: action.payload,
			}
		case START_GET_ACTIVITY_LOGS:
			return {
				...state,
				loading: true,
			};
		default:
			return state;
	}
}
