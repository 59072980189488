import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Accordion, Text, Group, Box, Stack, Checkbox, Button, TextInput, ActionIcon, Divider, ScrollArea } from "@mantine/core";

import iconTrash from "../../../assets/svg/functionalities-icons/trash-can.svg";

import { useForm } from '@mantine/form';

import "./permissions.css"
import { hasDeletePermission, hasEditPermission } from "./utilities/hasPermissions";

const FormPermissions = ({ values, handleSubmit, setShowConfirmationDeleteModal, setSelectedRole, existingRolesNames }) => {

    const resources = useSelector((state) => state.resource.resources);
    const user = useSelector((state) => state.auth.user);

    const [completeResourcesChecked, setCompleteResourcesChecked] = useState([])

    const form = useForm({
        mode: 'controlled',
        initialValues: {},

        transformValues: (values) => ({
            ...values,
            name: values.name.trim()
        }),

        validate: {
            name: (value) => {
                if (value.length <= 0) {
                    return "Debe ingresar un nombre para el rol"
                } else if (!values._id && existingRolesNames.includes(value.toLowerCase().trim())) {
                    return "Ya existe un rol con este nombre"
                }
                return null
            },
            permissions: (value) => (
                Object.values(value).some((resource) =>
                    Object.values(resource.actions).some((action) => action.value)
                ) ? null : "Debe seleccionar al menos una acción"
            ),
        }
    });

    useEffect(() => {
        // Actualizo el form cuando se selecciona un rol desde la lista o se elige crear uno nuevo
        // y checkeo si algún recurso tenía sleccionadas todas las acciones
        form.setValues(values)

        setCompleteResourcesChecked(
            Object.keys(values.permissions).map(resource => {
                if (Object.values(values.permissions[resource].actions).every(action => action.value === true)) {
                    return resource;
                }
            }) || []
        );
    }, [values])

    const handleSelectAll = (resourceName, checked) => {
        //Marco o desmarco todas las acciones de un recurso
        const resourceActions = form.values.permissions[resourceName].actions;
        Object.keys(resourceActions).forEach((action) => {
            form.setFieldValue(`permissions.${resourceName}.actions.${action}.value`, checked);
        })
        checked ? setCompleteResourcesChecked(prev => [...prev, resourceName]) :
            setCompleteResourcesChecked(prev => [...prev.filter(resource => resource !== resourceName)])
    }

    const validateAllSelected = (resourceActions, resourceName) => {
        //Checkeo si con la opción que seleccioné estoy completando el recurso
        if (Object.values(resourceActions).filter(action => action.value === true).length === Object.values(resourceActions).length - 1) {
            setCompleteResourcesChecked(prev => [...prev, resourceName])
        }
    }

    const handleCheckboxCheck = (resourceName, action) => {
        validateAllSelected(form.values.permissions[resourceName].actions, resourceName);

        if (action.name !== 'view') {
            form.setFieldValue(`permissions.${resourceName}.actions.view.value`, true);
        }
    }

    const handleCheckboxUncheck = (resource, action) => {
        setCompleteResourcesChecked(prev => [...prev.filter(res => res !== resource.name)]);

        if (action.name === 'view') {
            resource.actions.forEach(act => {
                if (act.name !== 'view') {
                    form.setFieldValue(`permissions.${resource.name}.actions.${act.name}.value`, false);
                }
            })
        }
    }

    return (
        <Stack>
            <TextInput
                placeholder="Nombre de rol"
                maw="40%"
                disabled={!hasEditPermission('permissions', user)}
                key={form.key(`name`)}
                {...form.getInputProps(`name`)}
            />
            <ScrollArea h={"53vh"} type="always">
                <Accordion
                    multiple
                    defaultValue={resources.map(res => res.name)}
                >
                    {resources.map(resource => {
                        return (
                            <Accordion.Item key={resource.name} value={resource.name}>
                                <Accordion.Control>
                                    <Group>
                                        {resource.label}
                                        <Checkbox
                                            ml="auto"
                                            mr={5}
                                            name={resource.name}
                                            disabled={!hasEditPermission('permissions', user)}
                                            checked={completeResourcesChecked.includes(resource.name)}
                                            styles={form.errors.permissions ? { input: { "border": "1px solid red" } } : {}}
                                            onClick={e => {
                                                // Evito que se cierre o abra el acordion
                                                e.stopPropagation()
                                                handleSelectAll(e.target.name, e.target.checked)
                                                form.clearFieldError('permissions')
                                            }}
                                        />
                                    </Group>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    {resource.actions.map(action => {
                                        return (
                                            <Box>
                                                <Divider mx="md" />
                                                <Group
                                                    my={6}
                                                    onClick={() => {
                                                        form.setFieldValue(`permissions.${resource.name}.actions.${action.name}.value`, !form.getValues().permissions[resource.name].actions[action.name].value)
                                                        form.clearFieldError('permissions')
                                                        // Valido si estoy completando el recurso o dejando de completarlo para marcar o desmarcar el general y el ver todos
                                                        form.getValues().permissions[resource.name].actions[action.name].value ?
                                                            handleCheckboxCheck(resource.name, action) :
                                                            handleCheckboxUncheck(resource, action)
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <Stack
                                                        ml="md"
                                                        gap={0}
                                                    >
                                                        <Text>{action.label}</Text>
                                                        <Text ml={2} size="sm" c="dimmed">{action.description}</Text>
                                                    </Stack>
                                                    <Checkbox
                                                        ml="auto"
                                                        mr="lg"
                                                        key={form.key(`permissions.${resource.name}.actions.${action.name}`)}
                                                        styles={form.errors.permissions ? { input: { "border": "1px solid red" } } : {}}
                                                        disabled={!hasEditPermission('permissions', user)}
                                                        {...form.getInputProps(`permissions.${resource.name}.actions.${action.name}.value`, { type: 'checkbox' })}
                                                        onChange={e => {
                                                            form.setFieldValue(`permissions.${resource.name}.actions.${action.name}.value`, e.target.checked);
                                                            form.clearFieldError('permissions')
                                                            // Valido si estoy completando el recurso o dejando de completarlo para marcar o desmarcar el general y el ver todos
                                                            e.target.checked ?
                                                                handleCheckboxCheck(resource.name, action) :
                                                                handleCheckboxUncheck(resource, action)
                                                        }
                                                        }
                                                    />
                                                </Group>
                                            </Box>
                                        )
                                    })
                                    }
                                </Accordion.Panel>
                            </Accordion.Item>
                        )
                    })}
                </Accordion>
            </ScrollArea>
            <Text size="xs" c="red">{form.errors.permissions}</Text>
            <Group ml="auto" gap={6}>
                {values._id &&
                    <Group gap={6}>
                        {hasDeletePermission('permissions', user) &&
                            <ActionIcon
                                variant="outline"
                                ml="auto"
                                onClick={setShowConfirmationDeleteModal}
                            >
                                <img
                                    src={iconTrash}
                                    type="button"
                                    alt="delete"
                                />
                            </ActionIcon>
                        }
                        <Button size="xs" variant="outline" onClick={() => setSelectedRole(null)}>Cancelar</Button>
                    </Group>
                }
                {hasEditPermission('permissions', user) && <Button size="xs" type="submit" onClick={form.onSubmit((values) => handleSubmit(values))}>{values._id ? "Editar" : "Guardar"}</Button>}
            </Group>
        </Stack>
    )
}

export default FormPermissions;