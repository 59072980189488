import {
    ACTION_COLLABORATOR_ERROR,
    ACTION_COLLABORATOR_START,
    ACTION_COLLABORATOR_SUCCESS,
    ADD_COLLABORATORS_SUCCESS,
    GET_COLLABORATOR_SUCCESS,
    GET_COLLABORATORS_SUCCESS,
    RESET_QUOTATION_RECORD
} from "../../types/index";

import clienteAxios from "../../../Axios";
import universlaCookie from "universal-cookie";
const cookie = new universlaCookie();

export function addCollaborator(name, lastName, docType, docNum, CUIL, birthday, email, phone, role) {
    return async (dispatch) => {
        dispatch(actionCollaboratorStart());
        try {
            const response = await clienteAxios({
                method: "POST",
                url: "collaborator",
                headers: {
                    "x-acces-token": cookie.get("token"),
                },
                data: {
                    name, lastName, docType, docNum, CUIL, birthday, email, phone, role
                },
            });
            dispatch(addCollaboratorSuccess({ collaborators: response.data.collaborators, message: response.data.message }))
        } catch (error) {
            dispatch(actionCollaboratorError(error.response.data.message));
        }
    };
}

export function addCollaboratorDirection(country, province, locality, neighborhood, street, number, floor, postalCode, collaboratorID) {
    return async (dispatch) => {
        dispatch(actionCollaboratorStart());
        try {
            const response = await clienteAxios({
                method: "POST",
                url: "collaboratorDirection",
                headers: { "x-acces-token": cookie.get("token") },
                data: { country, province, locality, neighborhood, street, number, floor, postalCode, collaboratorID },
            });
            dispatch(actionCollaboratorSuccessMessage(response.data.message));
        } catch (error) {
            dispatch(actionCollaboratorError(error.response.data.message));
        }
    };
}

export function deleteCollaborator(collaboratorID) {
    return async (dispatch) => {
        dispatch(actionCollaboratorStart());
        try {
            const response = await clienteAxios({
                method: "DELETE",
                url: `collaborator/?collaboratorID=${collaboratorID}`,
                headers: { "x-acces-token": cookie.get("token") }
            });
            dispatch(actionCollaboratorSuccessMessage(response.data.message));
        } catch (error) {
            dispatch(actionCollaboratorError(error.response.data.message));
        }
    };
}

export function deleteCollaboratorDirection(collaboratorID) {
    return async (dispatch) => {
        dispatch(actionCollaboratorStart());
        try {
            const response = await clienteAxios({
                method: "DELETE",
                url: `collaboratorDirection/?collaboratorID=${collaboratorID}`,
                headers: { "x-acces-token": cookie.get("token") }
            });
            dispatch(actionCollaboratorSuccessMessage(response.data.message));
        } catch (error) {
            dispatch(actionCollaboratorError(error.response.data.message));
        }
    };
}

export function getCollaboratorByID(collaboratorID) {
    return async (dispatch) => {
        dispatch(actionCollaboratorStart());
        try {
            const response = await clienteAxios({
                method: "GET",
                url: `collaborator/?collaboratorID=${collaboratorID}`,
                headers: { "x-acces-token": cookie.get("token") }
            });
            dispatch(getCollaboratorSuccess(response.data.response));
        } catch (error) {
            dispatch(actionCollaboratorError(error.response.data.message));
        }
    };
}

export function getCollaborators(page, searcher) {
    return async (dispatch) => {
        dispatch(actionCollaboratorStart());
        try {
            const response = await clienteAxios({
                method: "GET",
                url: `collaborators?page=${page}&searcher=${searcher}`,
                headers: { "x-acces-token": cookie.get("token") }
            });
            dispatch(getCollaboratorsSuccess(response.data.response));
            dispatch(resetQuotationRecord())
        } catch (error) {
            dispatch(actionCollaboratorError(error.response.data.message));
        }
    };
}

export function updateCollaborator(collaboratorInfo, collaboratorID) {
    return async (dispatch) => {
        dispatch(actionCollaboratorStart());
        try {
            const response = await clienteAxios({
                method: "PUT",
                url: "collaborator",
                headers: { "x-acces-token": cookie.get("token") },
                data: { collaboratorInfo, collaboratorID },
            });
            dispatch(actionCollaboratorSuccessMessage(response.data.message));
        } catch (error) {
            dispatch(actionCollaboratorError(error.response.data.message));
        }
    };
}

export function updateCollaboratorDirection(country, province, locality, neighborhood, street, number, floor, postalCode, index, collaboratorID) {
    return async (dispatch) => {
        dispatch(actionCollaboratorStart());
        try {
            const response = await clienteAxios({
                method: "PUT",
                url: "collaboratorDirection",
                headers: { "x-acces-token": cookie.get("token") },
                data: { country, province, locality, neighborhood, street, number, floor, postalCode, index, collaboratorID },
            });
            dispatch(actionCollaboratorSuccessMessage(response.data.message));
        } catch (error) {
            dispatch(actionCollaboratorError(error.response.data.message));
        }
    };
}

const actionCollaboratorError = (response) => ({
    type: ACTION_COLLABORATOR_ERROR,
    payload: response,
});

const actionCollaboratorStart = () => ({
    type: ACTION_COLLABORATOR_START,
});

const actionCollaboratorSuccessMessage = (response) => ({
    type: ACTION_COLLABORATOR_SUCCESS,
    payload: response,
})

const addCollaboratorSuccess = (response) => ({
    type: ADD_COLLABORATORS_SUCCESS,
    payload: response,
});

const getCollaboratorSuccess = (response) => ({
    type: GET_COLLABORATOR_SUCCESS,
    payload: response,
});

const getCollaboratorsSuccess = (response) => ({
    type: GET_COLLABORATORS_SUCCESS,
    payload: response,
});

const resetQuotationRecord = () => ({
    type: RESET_QUOTATION_RECORD
})