import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actionMarketPlayerRestart, getMarketPlayers } from "../../../redux/actions/entities/marketPlayerActions";

import ModalAddMarketPlayer from "./modales/ModalAddMarketPlayer";
import CardMarketPlayer from "./CardMarketPlayer";
import { TextSearcher } from "../../utilities/components/TextSearcher";
import StatusModal from "../../utilities/Modal/StatusModal";

import iconoMenu from "../../../assets/svg/functionalities-icons/menu.svg";
import iconAddNew from "../../../assets/svg/functionalities-icons/plus_white.svg";

import "./marketPlayers.css";
import { hasAdministrationPermission } from "../../configuration/permissions/utilities/hasPermissions";
import SectionAccordion from "../../utilities/components/SectionAccordion";

const MarketPlayers = () => {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user)
	const marketPlayers = useSelector((state) => state.marketPlayer.marketPlayers);
	const stateMarketPlayer = useSelector((state) => state.marketPlayer)

	const [accessToAdministration, setAccessToAdministration] = useState(false)
	const [cuentaBusqueda, setCuentaBusqueda] = useState("");
	const [addMarketPlayerModal, setAddMarketPlayerModal] = useState(false);
	const [addMPisSupplier, setAddMPisSupplier] = useState(false);

	const [showStatusModal, setShowStatusModal] = useState(false)

	useEffect(() => {
		dispatch(actionMarketPlayerRestart())
		dispatch(getMarketPlayers());
		setShowStatusModal(false)
		document.title = "Scope | Actores de mercado"
	}, [dispatch]);

	useEffect(() => {
		if (stateMarketPlayer.message !== "") {
			setShowStatusModal(true)
			setAddMarketPlayerModal(false)
		}
	}, [stateMarketPlayer.loading, stateMarketPlayer.error])

	useEffect(() => {
		if (!showStatusModal &&
			stateMarketPlayer.error &&
			(stateMarketPlayer.message === "No se pudo cargar el actor de mercado"
				|| stateMarketPlayer.message === "Ya existe un actor de mercado con este nombre")) {
			setAddMarketPlayerModal(true)
		}
	}, [showStatusModal, stateMarketPlayer.error])

	const handleAddMarketPlayerModal = (isSupplier) => {
		setAddMarketPlayerModal(true);
		setAddMPisSupplier(isSupplier);
	};

	return (
		<div className="cuentas-contenedor">
			<div className="marketPlayers-header" >
				<h2>Actores de mercado</h2>
				{hasAdministrationPermission("marketPlayers", user) && !accessToAdministration &&
					<img
						src={iconoMenu}
						alt="menu"
						onClick={() => setAccessToAdministration(true)}
					/>}
			</div>
			<div className="marketPlayers-header-searcher">
				<TextSearcher placeholder="Nombre..." setSearchFilter={setCuentaBusqueda} />
				{hasAdministrationPermission("marketPlayers", user) && !accessToAdministration &&
					<button
						className="marketPlayers-admin-button"
						onClick={() => setAccessToAdministration(true)}
					>
						Administrar actores de mercado
					</button>}
			</div>

			<SectionAccordion title="Proveedores" openClass="marketPlayers-cards-container">
				{accessToAdministration && (
					<div className="cardMarketPlayer-container supplier-addNew-container" onClick={() => handleAddMarketPlayerModal(true)}>
						<img alt="add" src={iconAddNew} />
					</div>
				)}
				{marketPlayers &&
					marketPlayers.length > 0 &&
					marketPlayers.map((marketPlayer) => {
						if (
							(marketPlayer.company.name.toLowerCase().includes(cuentaBusqueda.toLowerCase()) ||
								(marketPlayer.credentials.username &&
									marketPlayer.credentials.username.toLowerCase().includes(cuentaBusqueda.toLowerCase()))) &&
							marketPlayer.company.isSupplier
						) {
							return (
								<CardMarketPlayer
									permisoAdministrador={accessToAdministration}
									key={marketPlayer._id}
									marketPlayer={marketPlayer}
									index={marketPlayer._id}
								/>
							);
						}
						return null
					})}
			</SectionAccordion>

			<SectionAccordion title="Competencias" openClass="marketPlayers-cards-container">
				{accessToAdministration && (
					<div className="cardMarketPlayer-container supplier-addNew-container" onClick={() => handleAddMarketPlayerModal(false)}>
						<img alt="add" src={iconAddNew} />
					</div>
				)}
				{marketPlayers &&
					marketPlayers.length > 0 &&
					marketPlayers.map((marketPlayer) => {
						if (
							(marketPlayer.company.name.toLowerCase().includes(cuentaBusqueda.toLowerCase()) ||
								(marketPlayer.credentials.username &&
									marketPlayer.credentials.username.toLowerCase().includes(cuentaBusqueda.toLowerCase()))) &&
							!marketPlayer.company.isSupplier
						) {
							return (
								<CardMarketPlayer
									permisoAdministrador={accessToAdministration}
									key={marketPlayer._id}
									marketPlayer={marketPlayer}
									index={marketPlayer._id}
								/>
							);
						}
						return null
					})}
			</SectionAccordion>

			{addMarketPlayerModal && <ModalAddMarketPlayer isSupplier={addMPisSupplier} setAddMarketPlayerModal={setAddMarketPlayerModal} />}
			{showStatusModal && (
				<StatusModal loading={stateMarketPlayer.loading} error={stateMarketPlayer.error} setModalStatusCloseAction={setShowStatusModal}>
					{stateMarketPlayer.loading
						? "Verificando informacion..."
						: stateMarketPlayer.message}
				</StatusModal>
			)}
		</div>
	);
};

export default MarketPlayers;
