import React from 'react'

import Loader from '../../../utilities/Loader'

import './loadingProductCard.css'

export const LoadingProductCard = () => {
  return (
    <div className="loadingProductCard-container" >
      <div className="loadingProductCard-info">
        <Loader />
      </div>
    </div>
  )
}
