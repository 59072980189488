import { useState } from 'react';
import { Combobox, InputBase, useCombobox } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { postEntityLabel, postInsertEntityLabel } from '../../../redux/actions/utilities/labelingAction';
import { hasCreatePermission } from '../../configuration/permissions/utilities/hasPermissions';

export function SelectCreatable({ collection, entityID, type, setShowStatusModal }) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user)

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [data, setData] = useState(collection);
  const [value, setValue] = useState(null);
  const [search, setSearch] = useState('');

  const exactOptionMatch = data.some((item) => item.name === search);

  const safeSearch = search ? search.toLowerCase().trim() : '';
  const filteredOptions = exactOptionMatch
    ? data ?? [] : (data ?? []).filter((item) => (item.name ?? '').toLowerCase().includes(safeSearch));


  const options = filteredOptions.map((item) => (
    <Combobox.Option value={item.name} key={item._id} onClick={() => handleLabelSelection(item._id)}> {item.name} </Combobox.Option>
  ));

  const handleLabelCreation = () => {
    setShowStatusModal(true)
    dispatch(postInsertEntityLabel(search.toLowerCase(), entityID, type))
  }

  const handleLabelSelection = (labelID) => {
    setShowStatusModal(true)
    dispatch(postEntityLabel(entityID, labelID, type))
  }

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        if (val === '$create') {
          setData((current) => [...current, search]);
          setValue(search);
        } else {
          setValue(val);
          setSearch(val || '');
        }
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          rightSection={<Combobox.Chevron />}
          value={search}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(value || '');
          }}
          placeholder="Agrege etiqueta"
          rightSectionPointerEvents="none"
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {options}
          {hasCreatePermission('labeling', user) &&
            !exactOptionMatch && search.trim().length > 0 &&
            <Combobox.Option value="$create" onClick={handleLabelCreation}>+ Crear etiqueta "{search}"</Combobox.Option>
          }
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}