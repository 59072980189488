import {
	GET_ROLES_ERROR,
	GET_ROLES_SUCCESS,
	SET_ROLES_MESSAGE,
	START_GET_ROLES,
} from "../../types/index";

const initialState = {
	roles: [],
	loading: false,
	error: false,
	message: "",
};

export default function roleReducer (state = initialState, action) {
	switch (action.type) {
		case GET_ROLES_ERROR:
			return {
				...state,
				error: true,
				loading: false,
				message: "Ocurrió un error."
			};
		case GET_ROLES_SUCCESS:
			return {
				...state,
				error: null,
				loading: false,
				roles: action.payload,
			};
		case SET_ROLES_MESSAGE:
			return {
				...state,
				error: action.payload.error,
				message: action.payload.message,
			}
		case START_GET_ROLES:
			return {
				...state,
				loading: true,
			};
		default:
			return state;
	}
}
