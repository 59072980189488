import React from 'react'

import infoCircle from "../../../../assets/svg/information-icons/info-circle.svg";
import editOption from "../../../../assets/svg/functionalities-icons/lapiz-editar.svg";
import deleteOption from "../../../../assets/svg/functionalities-icons/trash-can.svg";

import { hasCreatePermission, hasDeletePermission, hasEditPermission } from '../../../configuration/permissions/utilities/hasPermissions';
import SectionAccordion from '../SectionAccordion';

import './labelingManager.css'

const LabelingManager = ({ collection, information, permissions, setShowConfirmationModal, setShowCreationModal, title, type, user }) => {

    const handleUpdateOption = (value) => {
        setShowCreationModal({ action: 'Editar', type, values: value })
    };

    return (
        <div className="labelingManager-container">
            <SectionAccordion title={title}>
                <section>
                    <img src={infoCircle} alt="info-icon" />
                    <p>{information}</p>
                </section>
                {hasCreatePermission(permissions, user) &&
                    <button className="labelingManager-button" onClick={() => setShowCreationModal({ action: 'Crear', type })}>
                        Crear
                    </button>
                }
                <div className="labelingManager-list-container">
                  {collection &&
                      collection.map((element) => {
                          return (
                              <div className="labelingManager-list-item" key={element._id}>
                                  <div>
                                      <section>
                                          <h5>{element.name}</h5>
                                          {element.percentage !== undefined && <h5>[ {element.percentage} % ]</h5>}
                                      </section>
                                      <section >
                                        {hasEditPermission(permissions, user) &&
                                          <img
                                            src={editOption}
                                            alt="options-icon"
                                            className="global-small-img-button"
                                            onClick={() => handleUpdateOption(element)}
                                          />
                                        }
                                        {hasDeletePermission(permissions, user) &&
                                          <img
                                            src={deleteOption}
                                            alt="options-icon"
                                            className="global-small-img-button"
                                            onClick={() => setShowConfirmationModal({ type, name: element.name, id: element._id })}
                                          />
                                        }
                                    </section>
                                  </div>
                              </div>
                          );
                      })}
                  </div>
              </SectionAccordion>
          </div >
    )
}

export default LabelingManager