import React from "react";

import { Accordion } from '@mantine/core';

import carsMarketValuesIcon from './../../../assets/svg/menu-icons/carsMarketValues.svg'

import "./carsMarketValuesRecommendation.css";
import "../recommendations.css"
import { useNavigate } from "react-router";

export const CarsMarketValuesRecommendation = () => {
    const navegation = useNavigate();

	return (
        <Accordion variant="contained" className="recommendation-container" defaultValue="carsMarketValues-container">
            <Accordion.Item key={1} value="carsMarketValues-container" className="carsMarketValues-container">
                <Accordion.Control>
                    <div className="carsMarketValues-circle"></div>
                    <span>Valores de mercado</span>
                </Accordion.Control>
                <Accordion.Panel>
                    <section onClick={() => navegation('/ValoresDeMercado')}>
                        <h4>Ir a sección</h4>
                        <span>
                            <img src={carsMarketValuesIcon} alt="carsMarketValuesIcon"/>
                        </span>
                    </section>
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
	);
};